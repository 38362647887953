<template>
  <div id="upload" class="t-center">
    <input
      v-if="!isCan"
      :disabled="isShowAble" ref="cleInput" :id="fileData.uploadFileId" type="file" class="fileLoad"
      style="z-index:11;" :accept="filetypeL" @change="upLoad">
  </div>
</template>

<script>
import {request} from '../../utils/request.js'

// let COS = require('cos-js-sdk-v5')
import {defineComponent, nextTick, ref, reactive, toRefs, onBeforeMount, watch} from 'vue'
import {message} from 'ant-design-vue'
import {uploadObject} from '../../utils/upload'
import {addPropToItem} from '../../utils/ArrayUtils'

export default defineComponent({
  props: {
    fileNameNotHaveSpaces: {
      type: Number,
      default: () => 0
    },
    filetype: {
      type: String,
      default() {
        return ''
      }
    },
    isShowAble: {
      type: Boolean,
      default() {
        return false
      }
    },
    uploadFileData: {
      type: String,
      default: () => ''
    },
    isUpdate: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['fileChange'],
  setup(props, {emit}) {
    console.debug(props)
    const params = reactive({
      cosType: 'cos'
    })
    let filetypeL = ''
    //此处添加上传类型
    if (props.filetype == 'img') {
      filetypeL = 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
    } else if (props.filetype == 'excel') {
      filetypeL = '.xls,.xlsx'
    } else {
      filetypeL = '.docx,.doc,.xlsx,.xls,.ppt,.pdf,.png,.jpg'
    }
    const getUUID = () => {
      let s = []
      let hexDigits = '0123456789abcdef'
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '_'
      let uuid = s.join('')
      return uuid
    }
    const isCan = ref(false)
    //此页面内部使用数据
    const fileData = {
      bucket: '',
      region: '',
      folder: '',
      TmpSecretId: '',
      TmpSecretKey: '',
      XCosSecurityToken: '',
      StartTime: '',
      ExpiredTime: '',
      uploadFileId: 'uploadId' + getUUID(),
      fileArr: [],
      ScopeLimit: false,
      showProgress: false,
      uploadFileData: ''
    }
    watch(() => props.uploadFileData, () => {
      if (props.isUpdate) {
        let inputValue = document.getElementsByClassName('fileLoad')
        inputValue.type = 'text'
        inputValue.value = props.uploadFileData
        console.debug(props.uploadFileData, inputValue[0])
      }

    }, {immediate: true})


    const cleInput = ref()
    const getCOSType = () => {
      let uri = '/product-service/product/cos/getTempToken'
      let param = {
        'data': {
          'type': ''
        },
      }
      return request(uri, param).then(res => {
        if (res.code === 1) {
          return res.result.osType
        }
        return ''
      })
    }
    onBeforeMount(async () => {
      params.cosType = await getCOSType()
    })
    const upLoad = () => {
      let fileObj = document.getElementById(fileData.uploadFileId).files[0]
      let blobFile = dataURLtoBlob(fileObj)
      blobFile = Object.assign(blobFile, {
        name: fileObj.name
      })
      uploadFile(blobFile)
    }
    const uploadFile = (e) => {
      if (e.name && props.fileNameNotHaveSpaces !== 0) {
        var judgeFn = new RegExp(/\s+/g)
        if (judgeFn.test(e.name)) {
          clearFile()
          message['error']('文件名不能包含空格')
          return
        }
      }
      fileData.showProgress = true
      uploadToCos(e)
    }
    const uploadToCos = (uploadFile) => {
      const uid = getUUID()
      uploadObject(uploadFile, params.cosType, (type, fileUrl, origin_file_name, key, p) => {
        if (p && fileUrl === '') {
          // 设置进度及状态
          if (p !== 100) {
            fileData.percentage = p
            addPropToItem(fileData.fileArr, 'percent', p, 'uid', uid)
          } else {
            fileData.showProgress = false
            addPropToItem(fileData.fileArr, 'percent', p, 'uid', uid)
            addPropToItem(fileData.fileArr, 'status', 'done', 'uid', uid)
          }
        } else {
          fileData.showProgress = false
          fileData.fileArr = {
            'enclosureName': origin_file_name,
            'url': fileUrl,
            'id': fileData.uploadFileId
          }
          emit('fileChange', fileData.fileArr)
        }
      })
    }
    // // 上传腾讯云
    // const uploadFile = (file, filename) => {
    //   // let fileNameInit = filename.split('.')[1]
    //   fileData.showProgress = true
    //   const uuid = getUUID()
    //   // console.info('上传位置:' + bucket)
    //   let uri = '/product-service/product/cos/getTempToken'
    //   let param = {
    //     'data': {
    //       'type': ''
    //     },
    //   }
    //   if (uri) {
    //     return request(uri, param).then(result => {
    //       console.debug('请求结果:' + JSON.stringify(result))
    //       if (result.code === 1) {
    //         fileData.bucket = result.result.app_cosBucket
    //         fileData.region = result.result.app_cosRegion
    //         fileData.folder = result.result.app_cosFolder + getSimpleDate() + '/'
    //         fileData.TmpSecretId = result.result.credentials.tmpSecretId
    //         fileData.TmpSecretKey = result.result.credentials.tmpSecretKey
    //         fileData.XCosSecurityToken = result.result.credentials.sessionToken
    //         fileData.StartTime = result.result.startTime
    //         fileData.ExpiredTime = result.result.expiredTime
    //         fileData.ScopeLimit = true
    //         uploadCosFile(file, filename, uuid)
    //       }
    //     })
    //   }
    // }
    // const uploadCosFile = (file, filename, value1) => {
    //   const cos = new COS({
    //     getAuthorization: function (options, abc) {
    //       abc({
    //         TmpSecretId: fileData.TmpSecretId,
    //         TmpSecretKey: fileData.TmpSecretKey,
    //         XCosSecurityToken: fileData.XCosSecurityToken,
    //         StartTime: fileData.StartTime, // 单位是秒
    //         ExpiredTime: fileData.ExpiredTime,
    //         ScopeLimit: fileData.ScopeLimit // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
    //       })
    //     }
    //   })
    //   cos.uploadFile(
    //     {
    //       Bucket: fileData.bucket /* 必须 */,
    //       Region: fileData.region /* 存储桶所在地域，必须字段 */,
    //       // Key: folder + uuid + filename, /* 必须 */
    //       Key: fileData.folder + value1 + '.' + filename /* 必须 */,
    //       StorageClass: 'STANDARD',
    //       Body: file, // 上传文件对象
    //       SliceSize: 1024 * 1024 * 10, /* 触发分块上传的阈值，超过10MB使用分块上传，非必须 */
    //       onProgress: function (progressData) {
    //         if (progressData) {
    //           fileData.percentage = progressData.percent
    //         }
    //       }
    //     },
    //     function (err, data) {
    //       fileData.showProgress = false
    //       console.info(err)
    //       if (data && data.statusCode === 200) {
    //         fileData.fileArr = {
    //           'enclosureName': filename,
    //           'url': 'https://' + data.Location,
    //           'id': fileData.uploadFileId
    //         }
    //         // console.debug(fileData)
    //         emit('fileChange', fileData.fileArr)
    //       } else {
    //         // console.debug('请求失败')
    //         message['error']('上传文件失败！')
    //         clearFile()
    //       }
    //     }
    //   )
    // }
    // DataURL转Blob
    const dataURLtoBlob = (fileObj) => {
      // console.debug(fileObj.size)
      return new Blob([fileObj], {type: fileObj.type})
    }
    // const getSimpleDate = (_label = '/') => {
    //   let date = new Date()
    //   let year = date.getFullYear()
    //   let month = date.getMonth() + 1
    //   let strDate = date.getDate()
    //   if (month >= 1 && month <= 9) {
    //     month = '0' + month
    //   }
    //   if (strDate >= 0 && strDate <= 9) {
    //     strDate = '0' + strDate
    //   }
    //   return year + _label + month + _label + strDate
    // }

    const clearFile = () => {
      isCan.value = true
      //nextTick 函数是在下一次dom更新后回调，
      //这就可以实现先执行的上面的代码，让input[file]节点消失，
      //然后再dom更新后回调该函数，又执行了让input[file]节点显示，这样就实现了刷新
      nextTick(() => {
        isCan.value = false
      })
    }
    return {
      ...toRefs(params),
      // uploadCosFile,
      upLoad,
      cleInput,
      fileData,
      filetypeL,
      props,
      isCan,
      clearFile
    }
  },
})


</script>

<style scoped>
.fileBox {
  display: inline-block;
  width: 70px;
  position: absolute;
  overflow: hidden;
  right: 0;
}

.file-ip {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
</style>
