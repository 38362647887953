<template>
  <a-checkbox
    v-if="dataInsert !== 1"
    v-model:checked="checked"
    @change="onCheckAllChange(checked)"
  >
    选择全部
  </a-checkbox>
  <a-tabs @change="tabsChange" v-model:value="activeKey"  type="card" style="margin-top: 20px">
    <div class="a-tabs">
      <poster-tab-son ref="posterRef" @getList="getList" @getIds="getIds" :dataInsert="dataInsert" v-if="showTab === false && data.length > 0" :data="data" @statusLosk="statusLosk" :dataType="dataType"></poster-tab-son>
    </div>
    <a-tab-pane key="1" tab="图片" id="tab-1" v-if="showTab" class="a-tabs">
      <div class="actives" v-if="dataType === 'img'">
        <a-empty v-if="data.length <= 0"/>
        <poster-tab-son @getIds="getIds" v-if="data.length > 0" :activeKey="activeKey" ref="posterRef" :data="data" @statusLosk="statusLosk" :dataType="dataType"></poster-tab-son>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="音频" id="tab-2" v-if="showTab" class="a-tabs">
      <div class="actives" v-if="dataType === 'yinpin'">
        <a-empty v-if="data.length <= 0"/>
        <poster-tab-son @getIds="getIds" v-if="data.length > 0" :activeKey="activeKey" ref="posterRef" @statusLosk="statusLosk" :data="data" :dataType="dataType"></poster-tab-son>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="视频" id="tab-3" v-if="showTab" class="a-tabs">
      <div class="actives" v-if="dataType === 'shipin'">
        <a-empty v-if="data.length <= 0"/>
        <poster-tab-son @getIds="getIds" v-if="data.length > 0" :activeKey="activeKey" ref="posterRef" @statusLosk="statusLosk" :data="data" :dataType="dataType"></poster-tab-son>
      </div>
    </a-tab-pane>
    <a-tab-pane key="4" tab="文件" id="tab-4" v-if="showTab" class="a-tabs">
      <div class="actives" v-if="dataType === 'wenjian'">
        <a-empty v-if="data.length <= 0"/>
        <poster-tab-son @getIds="getIds" v-if="data.length > 0" :activeKey="activeKey" ref="posterRef" @statusLosk="statusLosk" :data="data" :dataType="dataType"></poster-tab-son>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import {defineComponent, reactive, toRefs, ref} from 'vue'
import PosterTabSon from './posterTabSonn'
export default defineComponent({
  components: {PosterTabSon},
  props: {
    dataInsert: {
      type: Number,
      required: true,
      dafault() {
        return 0
      }
    },
    data: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    dataType: {
      type: String,
      required: true,
      default() {
        return 'img'
      }
    },
    isShow: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    }
  },
  emits:['tab-change', 'getIds'],
  setup(props,context) {
    const posterRef = ref()
    const state = reactive({
      checked: false,
      status: false,
      activeKey: '1',
      showTab: props.isShow === '1' ? true : false
    })
    const statusLosk = function(value) { // 全选
      state.checked = value
    }
    const onCheckAllChange = function(checked) {
      state.status = checked
      posterRef.value.selectAll(checked)
    }
    const deletel = function() {
      state.checked = false
      posterRef.value.dell()
    }
    const tabsChange = (activeKey) => {
      state.activeKey = activeKey
      context.emit('tab-change', activeKey)
    }
    const getList = (list) => {
      context.emit('getList', list)
    }
    const getIds = (ids) =>{
      context.emit('getIds', ids)
    }

    return {
      tabsChange,
      ...toRefs(state),
      getIds,
      getList,
      onCheckAllChange,
      statusLosk,
      posterRef,
      deletel,
    }
  }

})
</script>

<style scoped>
.a-tabs {
  height: 400px;
  overflow-y: auto;
}

</style>
