<template>

  <div class="clearfix">
    <div>
    <a-upload
      v-model:file-list="fileList"
      list-type="picture-card"
      :multiple="false"
      @change="handleChange"
      @preview="handlePreview"
      :customRequest="uploadFile"
      :accept="filetypeL"
      style="margin-bottom:15px"
      :beforeUpload="beforeUpload"
      :capture="null"
      :max-count="1"
      :show-upload-list="{showPreviewIcon:true}"
    >
      <div v-if="fileList.length < 1">
        <div style="position: relative;width: 13rem;height: 8rem;" v-if="fileList.length  < 1">
<!--          <img style="width: 100%; height: 100%" :src="'//images.weserv.nl/?url='+fileState.specialImg" alt="example"/>-->
          <img style="width: 100%; height: 100%" :src="fileState.specialImg.indexOf('data:image/png;base64,')!==-1?fileState.specialImg:'//images.weserv.nl/?url='+fileState.specialImg" alt="example"/>
          <div class="plus_lined">
            <img alt="peizhao" style="width: 2.5rem; height: 2.5rem;"  src="../../assets/img/icon-photo.png"/>
            <div style="margin-top: 8px">{{ fileState.up_placeholder }}</div>
          </div>
        </div>
      </div>
      <!-- <van-image radius="15" v-if="file.status==='done'" width="208" fill :src="file.playUrl"/> -->
      <template #itemRender="{file}">
      <!-- {{ file }} -->
        <div class="demo-upload-list">
          <!-- <div> -->
            <img
              v-if="file.status==='done'"
              class="upload-img"
              style="width: 208px; object-fit: cover;"
              :src="file.playUrl"
              alt="正在飞速加载......"
            >
            <!-- <van-image v-if="file.status==='done'" width="100" fill :src="file.thumbUrl"/> -->
            <!-- <van-image radius="15" v-if="file.status==='done'" width="208" fill :src="file.playUrl"/> -->
            <!-- <a-image
            style="overflow: hidden;"
            :preview="false"
            v-if="file.status==='done'"
            :width="200"
            :height="100"
            :src="file.thumbUrl"
  /> -->
            <a-progress v-else
                        :width="80" type="circle" :percent="file.percent"/>
            <div class="demo-upload-list-cover">
<!--              <close-circle-outlined  style="width:100px; position: absolute;right:-40px;top: 0px;}"  @click="handleRemove(file)"/>-->
<!--              <delete-outlined  style="width:100px; position: absolute;right:-40px;top: 0px;}"  @click="handleRemove(file)"/>-->
              <DeleteTwoTone style="width:100px; position: absolute;right:-40px;top: 0px;}"  @click="handleRemove(file)"/>
<!--              <div style="width:100px; position: absolute;right:-40px;top: 0px;}" @click="handleRemove(file)">删除</div>-->
            </div>
          <!-- </div> -->
        </div>
      </template>
    </a-upload>
    <a-modal :visible="previewVisible" :title="previewTitle"  @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
    </div>
  </div>
</template>

<script>

import {reactive, toRefs, onMounted, onBeforeMount} from 'vue'
// import {EyeOutlined, DeleteOutlined} from '@ant-design/icons-vue'

import {uploadObject, getDownloadInfo} from '../../utils/upload'
import {addPropToItem, removeByValue, selectItemByProp} from '../../utils/ArrayUtils'
import {docIcon, zipIcon, defaultIcon, xlsIcon, pptIcon, pdfIcon, rtfIcon, txtIcon, wpsIcon,} from '../../utils/icon64list'
import { message } from 'ant-design-vue'
import {request} from '../../utils/request'
import HeaderImg from '../../assets/img/header.png'
import EmblemImg from '../../assets/img/emblem.png'
import Card from '../../assets/img/card.png'
// import {Image} from 'vant'
import { DeleteTwoTone } from '@ant-design/icons-vue'
export default {
  name: 'uploadFileToCos',
  components: {
    // CloseCircleOutlined: CloseCircleOutlined
    // EyeOutlined: EyeOutlined,
    DeleteTwoTone: DeleteTwoTone,
    // [Image.name]:Image
  },
  props: {
    titleType: {
      type: String,
      default: () => '身份证'
    },
    styleType: {
      type: Number,
      default: () => 0
    },
    maxNum: {
      type: [Number, String],
      default: () => 1
    },
    formatList: {
      type: Array,
      default: () => []
    },
    selectList:{ // 限制类型
        type: Array,
        default:()=> []
      },
    echoList:{
      type: Array,
        default:()=> []
    },
    enclosureName: {
      type: String,
      default: () => ''
    },
    isSpecial:{
      type: Number,
      default: () => 1
    },
    uploadNameType:{
      type: [Number,String],
      default: () => 1
    }
  },
  emits:['fileChange', 'getFileList'],
  setup(props, context) {
    const params = reactive({
      cosType: 'cos',
      fileObj : {},
      cos: {
        bucket: '',
        region: '',
        folder: '',
        TmpSecretId: '',
        TmpSecretKey: '',
        XCosSecurityToken: '',
        StartTime: '',
        ExpiredTime: '',
        ScopeLimit: true
      },
      styleType: props.styleType,
      fileList: props.echoList,
      filetypeL: '',
      previewVisible: false,
      previewTitle: '',
      previewImage: '',
      type: '',
      previewUrl: '',
      previewVideoImgUrl: '',
      showProgress: false,
      idTypeData:{
        '01': ['请上传人像面','请上传国徽面'],
        '03': ['请上传证件照'],
        '05': ['请上传首页', '请上传本人页'],
        '07': ['请上传证件照'],
        '15': ['外国人永久居留身份证人像面', '外国人永久居留身份证国徽面'],
        '1': ['请上传银行卡照片'],
        '2': ['请上传存在照片']
      }

    })
    const getCOSType = () => {
      let uri = '/product-service/product/cos/getTempToken'
      let param = {
        'data': {
          'type': ''
        },
      }
      return request(uri, param).then(res => {
        if (res.code === 1) {
          return res.result.osType
        }
        return ''
      })
    }
    onBeforeMount(async ()=> {
      params.cosType = await getCOSType()
    })
    const uploadToCos = (uploadFile) => {
      let uid = uploadFile.uid
      uploadObject(uploadFile, params.cosType,(type, fileUrl, origin_file_name, key, p, respFlag) => {
        if (!respFlag){
          console.debug('上传失败')
          handleRemove2(uploadFile)
        }
        if (p && p!==-1 && fileUrl === '') {
          // 设置进度及状态
          if (p !== 100) {
            addPropToItem(params.fileList,'percent',p,'uid',uid)
          } else {
            addPropToItem(params.fileList,'percent',p,'uid',uid)
            addPropToItem(params.fileList,'status','done','uid',uid)
          }
        } else {
          // console.debug(`类型:${type}\ncos-url:${fileUrl}\n文件名:${origin_file_name}\nKEY:${key}`)
          // console.debug(`上传的对象：${JSON.stringify(uploadFile)}`)
          // console.debug(`已上传文件列表：${JSON.stringify(params.fileList)}`)

          let uid = uploadFile.uid
          params.fileList.forEach(file => {
            if (file.uid === uid) {
              file['playUrl'] = fileUrl
              file['cosKey'] = key
              if (file.thumbUrl === '') {
                //  TODO 只做了图文处理，--- 还差 其他文件类型默认图，及展示对应文件的弹窗
                let comType = compareType('mp4',type)
                console.debug(66666666666,comType)
                if (compareType('doc',type)||compareType('docx',type)){
                  file.thumbUrl = docIcon
                } else if (compareType('zip',type)||compareType('rar',type)||compareType('7z',type)){
                  file.thumbUrl = zipIcon
                } else if (compareType('xlsx',type)||compareType('xls',type)){
                  file.thumbUrl = xlsIcon
                } else if (compareType('ppt',type)||compareType('pptx',type)){
                  file.thumbUrl = pptIcon
                } else if (compareType('pdf',type)){
                  file.thumbUrl = pdfIcon
                }else if (compareType('rtf',type)){
                  file.thumbUrl = rtfIcon
                }else if (compareType('txt',type)){
                  file.thumbUrl = txtIcon
                }else if (compareType('wps',type)){
                  file.thumbUrl = wpsIcon
                }else {
                  file.thumbUrl = defaultIcon
                }
              }
            }
          })
          console.debug('更新属性后的已上传文件列表',params.fileList)
          // 将更新的值 返回
          context.emit('fileChange', params.fileList)
        }
      })
    }

    /**
     * 根据uid获取已上传列表的file对象
     * @param e
     * @returns {*}
     */
    const getFileFormListByEuid = (e) => {
      return selectItemByProp(params.fileList, 'uid', e.uid)[0]
    }

    /**
     * 执行移除操作
     * @param e
     */
    const handleRemove = (e) => {
      let file = getFileFormListByEuid(e)
      if (file.cosKey) {
          removeByValue(params.fileList, 'uid', file.uid)
          message.success('删除成功')
          // 删除之后的值返回出去
          context.emit('getFileList',  file)
      }
    }
    const handleRemove2 = (e) => {
      let file = getFileFormListByEuid(e)
      removeByValue(params.fileList, 'uid', file.uid)
      // 删除之后的值返回出去
      context.emit('getFileList',  file)
    }
    const handlePreview = async (e) => {
      params.fileObj = e
      let file = getFileFormListByEuid(e)
      console.debug('执行步骤: handlePreview',file)
      params.previewTitle = file.name
      params.type = file.name.split('.').pop() // 得到上传的后缀名
      // TODO 在这里处理弹窗预览的东西
      if (params.type === 'mp4') {
        // 在此处获取
        params.previewUrl = file.playUrl
      } else {
        params.previewImage = file.thumbUrl
      }
      params.previewVisible = true
    }
    const handleChange = () => {
      context.emit('fileChange', params.fileList, props.isSpecial)
    }
    // 上传腾讯云
    const uploadFile = (e) => {
      console.log(e, 'eeeeeee')
      if(e.file.size > 2000000){
        blobToBase64(e.file,e.file.name).then(res =>{
          uploadToCos(res)
        })
      }else{
        uploadToCos(e.file)
      }
    }
    const beforeUpload = (file) => {
      if (!props.selectList.length) {
        return
      }
      // 限制类型
      let fileType = file.name.split('.').pop() // 得到上传的后缀名
      // 检查 MIME 类型是否以 "image/" 开头
      let format = props.selectList.map(item => item.toLocaleUpperCase())
      if (format && format[0] === 'IMAGE/*') {
        const fileType2 = file.type
        if (!fileType2.toLocaleUpperCase().startsWith('IMAGE/')) {
          message.warning(`请上传 ${format} 格式的文件`)
          return false
        } else {
          return true
        }
      } else {
        if (!format.includes('.' + fileType.toLocaleUpperCase())) {
          message.warning(`请上传 ${format} 格式的文件`)
          return false
        } else {
          return true
        }
      }
    }
    // 压缩大小
    const compressImg = (base64, scale, fileName) => {
      // 处理缩放，转换格式
      // 用canvas来压缩
      const img = new Image()
      img.src = base64
      return new Promise((resolve) => {
        img.onload = async () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.setAttribute('width', img.width * scale)
          canvas.setAttribute('height', img.height * scale)
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          // 转成base64 文件
          base64 = canvas.toDataURL('image/jpeg', 0.5) //0到1之间的取值，主要用来选定图片的质量，默认值是0.92，超出范围也会选择默认值
          const arr = base64.split(',')
          const mime = arr[0].match(/:(.*?);/)[1]
          const bytes = atob(arr[1])
          const bytesLength = bytes.length
          const u8arr = new Uint8Array(bytesLength)
          for (let i = 0; i < bytes.length; i++) {
            u8arr[i] = bytes.charCodeAt(i)
          }
          const file = await new File([u8arr], fileName, { type: mime })
          resolve(file)
        }
      })
    }

    const blobToBase64 = async (blob, fileName) => {
      let uid = blob.uid
      const reader = await new FileReader() // 实例化一个reader文件
      await reader.readAsDataURL(blob) // 添加二进制文件
      return new Promise((resolve) => {
        reader.onload = async (event) => {
          const base64 = await event.target.result // 获取到它的base64文件
          const scale = 0.1 // 设置缩放比例 （0-1）
          let file = await compressImg(base64, scale, fileName)
          file.uid = uid
          resolve(file)
        }
      })
    }








    const handleCancel = () => {
      params.previewVisible = false
      params.previewImage = ''
      params.previewUrl = ''
      //  TODO  注意播放视频关闭弹窗，也要处理
    }
    // const getList = () => {
    //   getObjectList((cb) => {
    //     console.debug(`桶数据：${JSON.stringify(cb)}`)
    //   })s
    // }
    const compareType = (key,type) => {
      console.debug('得到想要的值', key,type)
      if (key.toUpperCase() === type.toUpperCase()) {
        return true
      }
      return false
    }

    // 预览下载
    const downloadFile = () =>{
      let file = getFileFormListByEuid(params.fileObj)
      if (file.cosKey) {
        getDownloadInfo(file, params.cosType)
      }
    }
    const fileState = reactive({
      specialImg: Card,
      up_placeholder:'请上传证件照'
    })
    const  handelSpecialImg = (type='') =>{
      let typeName = ''
      if(type){
        typeName = type
      }else{
        typeName = props.uploadNameType
      }
      switch (typeName){
        case '01':
          if(props.isSpecial == 1){
            fileState.specialImg = HeaderImg
            fileState.up_placeholder = '请上传身份证人像面'
          }else if(props.isSpecial == 2){
            fileState.specialImg = EmblemImg
            fileState.up_placeholder = '请上传身份证国徽面'
          }
          break
        case '1':
          fileState.specialImg = Card
          fileState.up_placeholder = '请上传银行卡正面'
          break
        case '05':
          if(props.isSpecial == 1){
            fileState.specialImg = HeaderImg
            fileState.up_placeholder = '请上传首页'
          }else if(props.isSpecial == 2){
            fileState.specialImg = EmblemImg
            fileState.up_placeholder = '请上传本人页'
          }
          break
        case '15':
          if(props.isSpecial == 1) {
            fileState.specialImg = HeaderImg
            fileState.up_placeholder = '请上传外国人永久居留身份证人像面'
          } else if (props.isSpecial == 2) {
            fileState.specialImg = EmblemImg
            fileState.up_placeholder = '请上传外国人永久居留身份证国徽面'
          }
          break
        default:
          fileState.specialImg = Card
          fileState.up_placeholder = props.titleType === '银行卡'?'请上传银行卡正面照':'请上传图片'
          break
      }
    }

    onMounted(() => {
      handelSpecialImg()
      //限制的类型进行遍历
      params.filetypeL = props.selectList.join()
    })
    return {
      fileState,
      ...toRefs(params),
      handlePreview,
      handleChange,
      uploadFile,
      beforeUpload,
      handleCancel,
      handleRemove,
      compareType,
      downloadFile,
      handelSpecialImg
    }
  }
}
</script>

<style scoped>
:deep(.ant-upload.ant-upload-select-picture-card){
  width: 13rem;
  height: 8rem;
  border: 0;
}
.plus_lined{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);

}
:deep(.ant-upload-list-picture-card-container){
  width: 13rem;
  height: 8rem;
  /*margin-bottom: 4.6rem;*/
}
.demo-upload-list-cover {
  /*position: absolute;*/
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover :deep(.anticon-eye) {
  color:#fff;
  font-size:20px;
  cursor:pointer;
  margin-left:15px;
}

.demo-upload-list-cover :deep(.anticon-delete) {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 15px;
}

.demo-upload-list {
  display: flex;
  text-align: center;
  overflow: hidden;
  justify-content: center;
  border-radius: 4px;
  background: #fff;
  position: relative;
  margin-right: 4px;
  /*position: relative;*/
  width: 13rem;
  height: 8rem;
}
/* .demo-upload-list img {
  width: 100%;
} */
</style>
