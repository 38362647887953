<template>
  <a-config-provider :locale="zhCN">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive"/>
    </router-view>
  </a-config-provider>

</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
// import {isAndroid, isQyWeiXin, isWeiXin} from './utils/utlis.js'

export default {
  name: 'App',
  setup() {
    dayjs.locale('zh-cn')
    return {
      zhCN
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  /*position: absolute;*/
  /*left: 0;*/
  /*top: 0;*/
}

#app ::-webkit-scrollbar {
  display: none
}

/*::-webkit-scrollbar {display:none}*/
</style>

