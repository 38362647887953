<template>
  <div>
    <!--根据json，预览元素-->
    <template v-for="(dragModule,num) in previewDatas.productElementList" :key="'PE_' + previewDatas.moduleType + num">
      <div class="inTitle" :class="{blodClass: isBlod}" v-if="isModel&& !previewData.moduleName.includes('健康') && previewDatas.moduleType != 'addBeneficiarys'">
        <span>{{
            previewDatas.productElementList.length > 1 ? previewDatas.moduleName + (num + 1) : previewDatas.moduleName
          }}:</span>
      </div>
      <div v-if="previewDatas.moduleType !== 'insureds' || !isInsureds">
        <template v-for="(element_detail, idx) in dragModule"
                  :key="'PE_' + previewDatas.moduleType + num + '_' + idx + '_' + element_detail.id">
          <div >
            <div class="input-section2" v-if="!element_detail.isShow && isShowRFlag" >
              <!-- 如果字段是必填的，显示一个红色的小红点 -->
              <div class="required-marker" v-if="element_detail.required==='1'"></div>
              <!-- 如果字段是非必填的，显示一个透明的小红点 -->
              <div class=" transparent" v-else></div>
            </div>
            <div :draggable="true"
                 @dragstart.stop="handleDragStart($event, element_detail)"
                 @dragover.stop="handleDragOver($event, element_detail)"
                 @dragenter.stop="Debounce(handleDragEnter($event, element_detail))"
                 @dragend.stop="handleDragEnd($event, element_detail)"
                 :class="element_detail.elementOperatingMode && (element_detail.elementOperatingMode.mode_id === '99' || (previewData.moduleName && previewData.moduleName.includes('健康')))? (!previewData.isChild ? 'inSectionHealth' : '') : 'inSection'"
                 v-if="!element_detail.isShow">
                 <!-- {{ previewData.isChild }} -----previewData.isChild -->
              <!--输入框-->
              <div class="inRow"
                   v-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '0'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}"
                  :title="element_detail.elementOperatingMode.elementTitle">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}
                  <span
                    v-if="element_detail.elementConfig.calculate">({{ element_detail.elementConfig.calculate }})</span>
                </label>
                <InputView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :disabledType="element_detail.disabledType"
                  :input-title="element_detail.elementOperatingMode.elementTitle"
                  :element-vaule="element_detail.elementVaule"
                  :checkType="checkType"
                  :element-name="element_detail.elementName"
                  :input-hint="element_detail.elementConfig.promptLanguage"
                  :max-length="element_detail.elementMaxValueLength"
                  :tips="element_detail.elementTips"
                  :input-config="element_detail.elementConfig"
                  :elementCode="element_detail.elementCode"
                  :isRequired="element_detail.required"
                  @callback="getData"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :insuredObj="insuredObj"
                  :isDisabled="element_detail.isDisabled"
                />
                <div v-show="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    下拉框-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '1'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                  <SelectView
                    v-model:firstValue="element_detail.firstValue"
                    v-model:endValue="element_detail.endValue"
                    :isDisabled="element_detail.isDisabled"
                    :isRequired="element_detail.required"
                    :detailType="previewDatas.type?previewDatas.type : ''"
                    :disabledType="element_detail.disabledType"
                    @callback="getData"
                    :isShow="element_detail.isShow"
                    :defaultRequired="element_detail.defaultRequired"
                    :title="element_detail.elementName"
                    :elementTitle="element_detail.elementOperatingMode.elementTitle"
                    :elementCode="element_detail.elementCode"
                    :options="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_select"
                    :dictionary="element_detail.dictionaryName"
                    :moduleId="previewDatas.moduleType"
                    :elementId="element_detail.id"
                    :moduleIndex="num"
                    :moduleName="previewDatas.moduleName"
                    :insuredObj="insuredObj"
                    />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    单选-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '2'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <RadioBtnView

                  v-model:firstValue="element_detail.firstValue"
                  :isRequired="element_detail.required"
                  :defaultRequired="element_detail.defaultRequired"
                  :isDisabled="element_detail.isDisabled"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :element-name="element_detail.elementName"
                  :disabledType="element_detail.disabledType"
                  @callback="getData"
                  :moduleIndex="num"
                  :elementCode="element_detail.elementCode"
                  :title="element_detail.elementOperatingMode.elementTitle"
                  :data-list="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"/>
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    多选-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '3'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <CheckBoxView
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :isDisabled="element_detail.isDisabled"
                  :disabledType="element_detail.disabledType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :titlePos="element_detail.titlePos"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :options="element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!-- 开关  -->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '103'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <SwitchView
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :disabledType="element_detail.disabledType"
                  :defaultRequired="element_detail.defaultRequired"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :isDisabled="element_detail.isDisabled"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :switchData="element_detail.elementOperatingMode.mode_switch"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--   城市 / 职业选择器 -- tree -->
              <div class="inRow"
                   v-else-if=" !element_detail.isShow &&  element_detail.elementOperatingMode && (element_detail.elementOperatingMode.mode_id === '4' || element_detail.elementOperatingMode.mode_id === '5')">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <OccupationCityView
                  :isDisabled="element_detail.isDisabled"
                  v-model:firstValue="element_detail.firstValue"
                  :disabledType="element_detail.disabledType"
                  :occupationCity="element_detail"
                  :occupationCityObj="previewDatas"
                  @callback="getData"
                  :title="element_detail.elementName"
                  :moduleId="previewDatas.moduleType"
                  :moduleIndex="num"
                  :moduleShow="moduleShow"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--   时间选择器  -->
              <div class="inRow"
                   v-else-if=" !element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '6'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <DataTimePickerView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :isDisabled="element_detail.isDisabled"
                  :titlePos="element_detail.titlePos"
                  :data-type="element_detail.elementVaule.typeId"
                  :range-data="element_detail.elementVaule"
                  :title="element_detail.elementName"
                  :disabledType="element_detail.disabledType"
                  :min-time="element_detail.elementOperatingMode.mode_time.timeMinValue"
                  :max-time="element_detail.elementOperatingMode.mode_time.timeMaxValue"
                  @callback="getData"
                  :moduleIndex="num"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :isRequired="element_detail.required"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    证件类型-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '7'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <CertificateTypeView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :isDisabled="element_detail.isDisabled"
                  :isRequired="element_detail.required"
                  :detailType="previewDatas.type?previewDatas.type : ''"
                  :disabledType="element_detail.disabledType"
                  @callback="getData"
                  :isShow="element_detail.isShow"
                  :defaultRequired="element_detail.defaultRequired"
                  :title="element_detail.elementName"
                  :elementTitle="element_detail.elementOperatingMode.elementTitle"
                  :elementCode="element_detail.elementCode"
                  :options="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_documentType.certificateOptions"
                  :dictionary="element_detail.dictionaryName"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :moduleName="previewDatas.moduleName"
                  :certifiOptions="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_documentType.certificateValue"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    健康告知-->
              <div :class="'inRow'"
                    v-else-if="element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '99'">
                  <label v-if="!(element_detail.elementOperatingMode.elementTitle.includes('身高(cm)') || element_detail.elementOperatingMode.elementTitle.includes('体重(kg)'))" style="border: 0 !important; font-size: 0.94rem;line-height: 1.5rem" :class="{blodClass: isBlod,itemLabel: true, setN: true, topList: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}" v-html="element_detail.elementOperatingMode.elementTitle"></label>
                  <HealthTold
                    v-model:firstValue="element_detail.firstValue"
                    :elementTitle="element_detail.elementOperatingMode.elementTitle"
                    :disabledType="element_detail.disabledType"
                    :childId="element_detail.id"
                    :moduleId="previewDatas.moduleType ? previewDatas.moduleType :dragModule.moduleId"
                    :elementId="element_detail.id"
                    :required="element_detail.required ? element_detail.required :dragModule.required"
                    v-bind="$attrs"
                    @callback="getData"
                    :moduleIndex="num"
                    :superiorId="previewDatas.superiorId ? previewDatas.superiorId : ''"
                    :healthType="element_detail.healthType"
                    :elementCode="element_detail.elementCode"
                    :BIM="BIM"
                    :model="element_detail.elementOperatingMode.mode_health.way"
                    :health-obj="element_detail.elementOperatingMode.mode_health"
                  />
                  <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
                </div>
              <!-- 保险模块 -->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '104'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <NotifyAssociationView
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :disabledType="element_detail.disabledType"
                  :isDisabled="element_detail.isDisabled"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :options="element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!-- 影像附件 -->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '105'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <ImgAttachments
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :isDisabled="element_detail.isDisabled"
                  :disabledType="element_detail.disabledType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :title="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :options="element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  @getImageParameters="getImageParameters"
                  :elementCode="element_detail.elementCode"
                  :insuredObj="insuredObj"
                  :insuranceReminder="insuranceReminder"
                  :relationship="relationship"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>

              <!-- 上传 -->
              <div class="inRow"
                   v-else-if="element_detail.elementOperatingMode && (element_detail.elementOperatingMode.mode_id === '100' || element_detail.elementOperatingMode.mode_id === '101')">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }} :</label>
                <UploadDetailView
                  v-model:firstValue="element_detail.firstValue"
                  :elementData="dragModule"
                  :isDisabled="element_detail.isDisabled"
                  :isRequired="element_detail.required"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                  :elementId="element_detail.id"
                  :disabledType="element_detail.disabledType"
                  :moduleId="previewDatas.moduleType"
                  :moduleIndex="num"
                  :mode_id="element_detail.elementMode"
                  :specialUpload="element_detail.elementOperatingMode.mode_upload.specialUpload"
                  :maximumRestrict="element_detail.elementOperatingMode.mode_upload.uploadData.MaximumRestrict ? element_detail.elementOperatingMode.mode_upload.uploadData.MaximumRestrict : 1000"
                  :uploadType="element_detail.elementOperatingMode.mode_upload.uploadData.selectData && element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectName ? element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectName : ''"
                  :selectType="element_detail.elementOperatingMode.mode_upload.uploadData.selectData && element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectType ? element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectType : 0"
                  :moduleBig="element_detail.elementOperatingMode.mode_upload.uploadData.isLargeFilesTwo"
                  :isRestrict="element_detail.elementOperatingMode.mode_upload.uploadData.isRestrict"
                  :selectList="element_detail.elementOperatingMode.mode_upload.uploadData.selectData && element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectList ? element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectList : []"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>

              <!--    附件-->
              <div class="inRow" v-else-if="element_detail.elementOperatingMode &&
                    element_detail.elementOperatingMode.mode_id === '15'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>

                <AttachView
                  v-model:firstValue="element_detail.firstValue"
                  :fileUrl="element_detail.elementVaule.fileUrl"
                  :showPassBack="element_detail.elementConfig.isPassBack"
                  @callback="getData"
                  :isDisabled="element_detail.isDisabled"
                  :moduleIndex="num"
                  :elementCode="element_detail.elementCode"
                  :moduleId="previewDatas.moduleType"
                  :element-name="element_detail.elementName"
                >
                </AttachView>
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>

              </div>
            </div>
          </div>

        </template>
      </div>
      <van-collapse v-model="activeNames" v-if="previewDatas.moduleType === 'insureds' && isInsureds">
        <van-collapse-item title="被保险人" :name="previewDatas.modifyId">
          <template v-for="(element_detail, idx) in dragModule"
                    :key="'PE_' + previewDatas.moduleType + num + '_' + idx + '_' + element_detail.id">
            <div class="insuredsInSection oooo" v-if="!element_detail.isShow">
              <div v-if="isShowRFlag" class="input-section">
                <!-- 如果字段是必填的，显示一个红色的小红点 -->
                <div class="required-marker" v-if="element_detail.required==='1'"></div>
                <!-- 如果字段是非必填的，显示一个透明的小红点 -->
                <div class="transparent" v-else></div>
              </div>
              <!--输入框-->
              <div class="inRow"
                   v-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '0'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}"
                  :title="element_detail.elementOperatingMode.elementTitle">
                  {{ element_detail.elementOperatingMode.elementTitle }}<span
                  v-if="element_detail.elementConfig.calculate">({{ element_detail.elementConfig.calculate }})</span>
                </label>
                <InputView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :detailType="element_detail.type?element_detail.type : ''"
                  :disabledType="element_detail.disabledType"
                  :isDisabled="element_detail.isDisabled"
                  :checkType="checkType"
                  :input-title="element_detail.elementOperatingMode.elementTitle"
                  :element-vaule="element_detail.elementVaule"
                  :element-name="element_detail.elementName"
                  :input-hint="element_detail.elementConfig.promptLanguage"
                  :max-length="element_detail.elementMaxValueLength"
                  :tips="element_detail.elementTips"
                  :input-config="element_detail.elementConfig"
                  :elementCode="element_detail.elementCode"
                  :isRequired="element_detail.required"
                  @callback="getData"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :insuredObj="insuredObj"
                />
                <div v-show="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    下拉框-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '1'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <SelectView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :isRequired="element_detail.required"
                  :isDisabled="element_detail.isDisabled"
                  :detailType="previewDatas.type?previewDatas.type : ''"
                  :disabledType="element_detail.disabledType"
                  @callback="getData"
                  :isShow="element_detail.isShow"
                  :defaultRequired="element_detail.defaultRequired"
                  :title="element_detail.elementName"
                  :elementCode="element_detail.elementCode"
                  :options="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_select"
                  :dictionary="element_detail.dictionaryName"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :insuredObj="insuredObj"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>

              </div>
              <!--    单选-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '2'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <RadioBtnView
                  v-model:firstValue="element_detail.firstValue"
                  :isRequired="element_detail.required"
                  :isDisabled="element_detail.isDisabled"
                  :defaultRequired="element_detail.defaultRequired"
                  :detailType="previewDatas.type?previewDatas.type : ''"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :element-name="element_detail.elementName"
                  :disabledType="element_detail.disabledType"
                  @callback="getData"
                  :moduleIndex="num"
                  :elementCode="element_detail.elementCode"
                  :title="element_detail.elementOperatingMode.elementTitle"
                  :data-list="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"/>
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    多选-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '3'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <CheckBoxView
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :isDisabled="element_detail.isDisabled"
                  :titlePos="element_detail.titlePos"
                  :disabledType="element_detail.disabledType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :options="element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!-- 开关  -->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '103'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <SwitchView
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :isDisabled="element_detail.isDisabled"
                  :disabledType="element_detail.disabledType"
                  :defaultRequired="element_detail.defaultRequired"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :switchData="element_detail.elementOperatingMode.mode_switch"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--   城市 / 职业选择器 -- tree -->
              <div class="inRow"
                   v-else-if=" !element_detail.isShow &&  element_detail.elementOperatingMode && (element_detail.elementOperatingMode.mode_id === '4' || element_detail.elementOperatingMode.mode_id === '5')">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <OccupationCityView
                  v-model:firstValue="element_detail.firstValue"
                  :disabledType="element_detail.disabledType"
                  :occupationCity="element_detail"
                  :occupationCityObj="previewDatas"
                  :isDisabled="element_detail.isDisabled"
                  @callback="getData"
                  :title="element_detail.elementName"
                  :moduleId="previewDatas.moduleType"
                  :moduleIndex="num"
                  :moduleShow="moduleShow"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--   时间选择器  -->
              <div class="inRow"
                   v-else-if=" !element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '6'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <DataTimePickerView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :data-type="element_detail.elementVaule.typeId"
                  :range-data="element_detail.elementVaule"
                  :isDisabled="element_detail.isDisabled"
                  :title="element_detail.elementName"
                  :disabledType="element_detail.disabledType"
                  :min-time="element_detail.elementOperatingMode.mode_time.timeMinValue"
                  :max-time="element_detail.elementOperatingMode.mode_time.timeMaxValue"
                  @callback="getData"
                  :titlePos="element_detail.titlePos"
                  :moduleIndex="num"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :isRequired="element_detail.required"
                  :elementCode="element_detail.elementCode"
                  :insuredObj="insuredObj"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    证件类型-->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '7'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <CertificateTypeView
                  v-model:firstValue="element_detail.firstValue"
                  v-model:endValue="element_detail.endValue"
                  :isRequired="element_detail.required"
                  :isDisabled="element_detail.isDisabled"
                  :detailType="previewDatas.type?previewDatas.type : ''"
                  :disabledType="element_detail.disabledType"
                  @callback="getData"
                  :isShow="element_detail.isShow"
                  :defaultRequired="element_detail.defaultRequired"
                  :title="element_detail.elementName"
                  :elementTitle="element_detail.elementOperatingMode.elementTitle"
                  :elementCode="element_detail.elementCode"
                  :options="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_documentType.certificateOptions"
                  :dictionary="element_detail.dictionaryName"
                  :moduleId="previewDatas.moduleType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :moduleName="previewDatas.moduleName"
                  :certifiOptions="element_detail.isDictionary === 'true' ? [] : element_detail.elementOperatingMode.mode_documentType.certificateValue"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    健康告知-->
              <div class="inRow"
                   v-else-if="element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '99'">
                <label style="border: 0 !important; font-size: 0.7rem;line-height: 1.5rem"
                       :class="{blodClass: isBlod,itemLabel: true, setN: true, topList: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <HealthTold
                  v-model:firstValue="element_detail.firstValue"
                  :childId="element_detail.id"
                  :disabledType="element_detail.disabledType"
                  :moduleId="previewDatas.moduleType ? previewDatas.moduleType :dragModule.moduleId"
                  :elementId="element_detail.id"
                  :required="element_detail.required ? element_detail.required :dragModule.required"
                  v-bind="$attrs"
                  @callback="getData"
                  :moduleIndex="num"
                  :healthType="element_detail.healthType"
                  :elementCode="element_detail.elementCode"
                  :model="element_detail.elementOperatingMode.mode_health.way"
                  :health-obj="element_detail.elementOperatingMode.mode_health"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!-- 保险模块 -->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '104'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <NotifyAssociationView
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :isDisabled="element_detail.isDisabled"
                  :disabledType="element_detail.disabledType"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :options="element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!-- 影像附件 -->
              <div class="inRow"
                   v-else-if="!element_detail.isShow && element_detail.elementOperatingMode && element_detail.elementOperatingMode.mode_id === '105'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>
                <ImgAttachments
                  v-model:firstValue="element_detail.firstValue"
                  :moduleId="previewDatas.moduleType"
                  :disabledType="element_detail.disabledType"
                  :isDisabled="element_detail.isDisabled"
                  :elementId="element_detail.id"
                  :moduleIndex="num"
                  :element-name="element_detail.elementName"
                  :isRequired="element_detail.required"
                  :options="element_detail.elementOperatingMode.mode_config"
                  :dictionary="element_detail.dictionaryName"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>

              <!-- 上传 -->
              <div class="inRow"
                   v-else-if="element_detail.elementOperatingMode && (element_detail.elementOperatingMode.mode_id === '100' || element_detail.elementOperatingMode.mode_id === '101')">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }} :</label>
                <UploadDetailView
                  v-model:firstValue="element_detail.firstValue"
                  :elementData="dragModule"
                  :isRequired="element_detail.required"
                  @callback="getData"
                  :elementCode="element_detail.elementCode"
                  :elementId="element_detail.id"
                  :disabledType="element_detail.disabledType"
                  :isDisabled="element_detail.isDisabled"
                  :moduleId="previewDatas.moduleType"
                  :moduleIndex="num"
                  :moduleBig="element_detail.elementOperatingMode.mode_upload.uploadData.isLargeFilesTwo"
                  :mode_id="element_detail.elementMode"
                  :specialUpload="element_detail.elementOperatingMode.mode_upload.specialUpload"
                  :maximumRestrict="element_detail.elementOperatingMode.mode_upload.uploadData.MaximumRestrict ? element_detail.elementOperatingMode.mode_upload.uploadData.MaximumRestrict : 1000"
                  :uploadType="element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectName"
                  :selectType="element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectType"
                  :isRestrict="element_detail.elementOperatingMode.mode_upload.uploadData.isRestrict"
                  :selectList="element_detail.elementOperatingMode.mode_upload.uploadData.selectData.selectList"
                />
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
              <!--    附件-->
              <div class="inRow" v-else-if="element_detail.elementOperatingMode &&
                    element_detail.elementOperatingMode.mode_id === '15'">
                <label
                  :class="{itemLabel: true, top: element_detail.titlePos === '0', left: element_detail.titlePos !== '0'}">{{
                    element_detail.elementOperatingMode.elementTitle
                  }}</label>

                <AttachView
                  v-model:firstValue="element_detail.firstValue"
                  :fileUrl="element_detail.elementVaule.fileUrl"
                  :showPassBack="element_detail.elementConfig.isPassBack"
                  @callback="getData"
                  :moduleIndex="num"
                  :elementCode="element_detail.elementCode"
                  :moduleId="previewDatas.moduleType"
                  :element-name="element_detail.elementName"
                >
                </AttachView>
                <div v-if="isModuleDel" @click="handelDelElement(element_detail)" class="delElement">x</div>
              </div>
            </div>
          </template>
        </van-collapse-item>
      </van-collapse>
      <!--      删除按钮第一个不展示-->
      <div class="del_class" v-if="previewDatas.productElementList.length > 1&&isButton"
           @click="deleteElementFun(previewDatas.id,num,previewDatas.moduleType)">
        <span>
          <minus-circle-outlined style="color: #ff2d2d"/>  删除被保险人
        </span>
      </div>
      <!-- 受益人之后放开 ||previewDatas.moduleType==='beneficiarys')  -->
      <div class="add"
           v-if="(previewDatas.productElementList.length-1===num)
            && previewDatas.moduleType==='insureds'
            && isModel
            && (previewDatas.productElementList.length < maximumLimit)
            && maximumLimit > 1 "
      >
        <!--      添加按钮只会出现在被保人和受益人模块且只出现在最后一个下面-->

        <span @click="addElementFun(previewDatas.id,num,previewDatas.moduleType)">
          <plus-circle-outlined style="color: cornflowerblue"/> 新增被保险人</span>
      </div>
    </template>
  </div>
</template>

<script>
import 'vant/lib/index.css'
import {Collapse, CollapseItem, Popup, Icon, Field} from 'vant'
import {reactive, toRefs, onBeforeUpdate, onBeforeMount, ref, onMounted, watch} from 'vue'
import InputView from '../../pages/InsuranceConfiguration/element/views/InputView'
import SelectView from '../../pages/InsuranceConfiguration/element/views/SelectView'
import AttachView from '../../pages/InsuranceConfiguration/element/views/AttachView'
import RadioBtnView from '../../pages/InsuranceConfiguration/element/views/RadioBtnView'
import CheckBoxView from '../../pages/InsuranceConfiguration/element/views/CheckBoxView'
import DataTimePickerView from '../../pages/InsuranceConfiguration/element/views/DataTimePickerView'
import CertificateTypeView from '../../pages/InsuranceConfiguration/element/views/CertificateTypeView'
import HealthTold from '../../pages/InsuranceConfiguration/element/views/healthtold/HealthToldMod'
import UploadDetailView from '../../pages/InsuranceConfiguration/element/views/uploadDetailView'
import OccupationCityView from '../../pages/InsuranceConfiguration/element/views/OccupationCityView'
import SwitchView from '../../pages/InsuranceConfiguration/element/views/SwitchView'
import ImgAttachments from '../../pages/InsuranceConfiguration/element/views/ImgAttachments'

import NotifyAssociationView from '../../pages/InsuranceConfiguration/element/views/NotifyAssociationView'
// import * as _ from 'lodash'
export default {
  components: {
    InputView,
    SelectView,
    AttachView,
    RadioBtnView,
    CheckBoxView,
    DataTimePickerView,
    CertificateTypeView,
    HealthTold,
    UploadDetailView,
    OccupationCityView,
    NotifyAssociationView,
    SwitchView,
    ImgAttachments,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Field.name]: Field
  },
  props: {
    isBlod: {
      type: Boolean,
      default: () => false
    },
    checkType: {
      type: Boolean,
      default: () => true
    },
    isModel: {
      type: Boolean,
      default: () => true
    },
    isButton: {
      type: Boolean,
      default: () => false
    },
    required: {
      type: [Number, String],
      default: () => '0'
    },
    moduleId: {
      type: [String, Number],
      default: () => ''
    },
    previewData: {
      type: Object,
      required: true
    },
    isModuleDel: {
      type: Boolean,
      default: () => false
    },
    moduleShow: {
      type: Boolean,
      default: () => false
    },
    isShowRFlag: {
      type: Boolean,
      default: () => false
    },
    isInsureds: {
      type: Boolean,
      default: () => false
    },

    maximumLimit: {
      type: [String, Number],
      default: () => ''
    },
    insuredObj: {
      type: Object,
      default: () => {
      }
    },
    insuranceReminder: {
      type: String,
      default: () => ''
    },
    relationship: {
      type: String,
      default: () => ''
    },
    BIM: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }

  },
  name: 'PreviewPop',
  emits: ['getImageParameters', 'PreviewPopData', 'deleteElementFun', 'addElementFun', 'setUserDetail', 'handelDelElement'],
  setup(props, {emit}) {
    const State = reactive({
      required: props.required ? props.required : props.previewData.required,
      moduleId: props.moduleId,
      previewDatas: {},
      dragging: '',
      addBenefitVisible: false,
      addBenefitFormData: {}
    })
    const handleDragStart = (e, items) => {
      State.dragging = items//开始拖动时，暂时保存当前拖动的数据。
    }
    const handleDragEnd = (e, items) => {
      console.debug(e, items)
      State.dragging = null//拖动结束后，清除数据
    }
    const handleDragOver = (e) => {
      e.preventDefault() // 阻止默认行为
      e.stopPropagation()
      e.dataTransfer.dropEffect = 'move'//在dragenter中针对放置目标来设置!
    }
    const handleDragEnter = (e, items) => {
      e.preventDefault() // 阻止默认行为
      e.stopPropagation()
      e.dataTransfer.effectAllowed = 'move'//为需要移动的元素设置dragstart事件
      if (items == State.dragging) return
      let newItems = JSON.parse(JSON.stringify(State.previewDatas.productElementList))//拷贝一份数据进行交换操作。
      let src//获取数组下标
      let dst
      newItems.forEach((item, index) => {
        if (item[0].id === State.dragging.id) {
          src = index
        }
        if (item[0].id === items.id) {
          dst = index
        }

      })
      // console.log('src,det',src,dst)
      newItems.splice(dst, 0, ...newItems.splice(src, 1))//交换位置
      State.previewDatas.productElementList = newItems
    }

    const Debounce = (fn, t) => {
      const delay = t || 500
      let timer
      return function () {
        const args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          timer = null
          fn.apply(this, args)
        }, delay)
      }
    }
    const activeNames = ref(null)
    const deleteElementFun = (id, index, modularId) => {
      emit('deleteElementFun', id, index, modularId)
    }
    const addElementFun = function (id, index, modularId) {
      emit('addElementFun', id, index, modularId)
    }
    //得到数据
    const getData = (data, flag='') => {
      console.log(data, 'data11111')
      let keys = Object.keys(data)[0]
      if (State.previewDatas.moduleType == 'insureds' || State.previewDatas.moduleType == 'beneficiarys') {
        let index = data[keys].index
        let item = State.previewDatas.productElementList[index]
        let lenthNum = State.previewDatas.productElementList.length
        let isSelf = item.filter(n => (n.elementCode === 'relationToAppnt' || n.elementCode === 'relationToInsured')
          && (Number(n.firstValue) === 1 || (n.firstValue == '新增' && n.elementMode !== '103'))).length > 0
        // 得到点击的那个数组
        for (let i = 0; i < item.length; i++) {
          if (item[i].elementCode == 'bnfType' || item[i].elementCode == 'staffRelativesRelation') continue
          item[i].isShow = isSelf && item[i].isPublic ? true : false
          if (State.previewDatas.moduleType == 'insureds' && item[i].elementCode == 'relationToAppnt') {
            console.log('data==>', data)
            console.log('item[i]==>', item[i])
            if ((index !== 0 || item[i].firstValue !== '01') && item[i].elementMode === '103' && lenthNum > 1) {
              item[i].isShow = true
            } else {
              item[i].isShow = false
            }
            continue
          }
          // 保费
          if (State.previewDatas.moduleType == 'insureds' && lenthNum === 1 && item[i].elementCode == 'premium') {
            handelShowPremium(State.previewDatas.productElementList, 'premium', lenthNum)
            continue
          } else if (State.previewDatas.moduleType == 'insureds' && lenthNum > 1 && item[i].elementCode == 'premium') {
            handelShowPremium(State.previewDatas.productElementList, 'premium', lenthNum)
            continue
          }
        }
      }
      if ((data[keys][keys] == 'null' || data[keys][keys] == undefined) && keys !== 'idDateType' && keys !== 'longterm') return
      console.debug('data==> typeIsNotOcr', flag)
      emit('PreviewPopData', data, flag)
    }
    let value = ''
    // 展示保费元素
    const handelShowPremium = (list, item, lenthNum) => {
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].length; j++) {
          if (list[i][j].elementCode === 'relationToAppnt') {
            value = list[i][j].firstValue
          }
          if (list[i][j].elementCode === item && value === '01' && lenthNum === 1) {
            list[i][j].isShow = true
          } else if (list[i][j].elementCode === item) {
            list[i][j].isShow = false

          }
        }
      }
    }

    const getImageParameters = (data) => {
      emit('getImageParameters', data)
    }

    const handelDelElement = (data) => {
      emit('handelDelElement', data)
    }

    watch(props, () => {
      State.previewDatas = props.previewData
      activeNames.value = [State.previewDatas.modifyId]
    }, {immediate: true, deep: true})
    onBeforeUpdate(() => {
      // State.previewDatas = props.previewData
    })
    onMounted(() => {
    })


    onBeforeMount(() => {

    })
    return {
      handelDelElement,
      getData,
      deleteElementFun,
      addElementFun,
      ...toRefs(State),
      activeNames,
      handleDragStart,
      handleDragEnd,
      handleDragOver,
      handleDragEnter,
      Debounce,
      handelShowPremium,
      getImageParameters
    }
  }
}
</script>

<style scoped>
.input-section2 {
  position: absolute;
  padding-top: 13px;
  left: 10px;
}
.input-section {
  position: absolute;
  padding-top: 2px;
  left: -5px;
}

.required-marker::before  {
  content: "*"; /* 使用星号来代替小红点 */
  width: 4px;
  height: 4px;
  color: red;
  border-radius: 50%;
  margin-right: 5px; /* 添加右边距以分隔小红点和PreviewPop组件 */
}

.transparent {
  background-color: transparent;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 5px; /* 保持与红色小红点相同的右边距 */
}

.blodClass {
  font-size: 1.1rem !important;
  color: #333;
  font-weight: 600;
  font-family: '微软雅黑';
}

.delElement {
  position: absolute;
  left: -20px;
  top: 0;
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: #ccc;
}

.inRow {
  position: relative;
}

.inTitle {
  height: auto;
  padding: 0.4rem 0.7rem 0;
  font-size: .98rem;
  margin: 10px;
}

.inSection {
  font-size: .94rem;
  line-height: 2rem;
  padding: 6px 15px 6px 20px;
  color: #666666;
  /* border-radius: 6px; */
  background-color: #ffffff;
  border-bottom: 1px solid #f3f5f5;
}

.inSectionHealth {
  font-size: .94rem;
  line-height: 2rem;
  padding: 6px 15px 6px 20px;
  color: #666666;
  /* border-radius: 6px; */
  /* border-bottom: 1px solid #f3f5f5;*/
}

.oooo {
  position: relative;
}

.insuredsInSection {

  font-size: .94rem;
  line-height: 2rem;
  padding: 6px;
  color: #666666;
  border-radius: 6px;
  background-color: #ffffff;
  border-bottom: 1px solid #f3f5f5;
}

/*.inSection:last-child {*/
/*  border: 0;*/
/*}*/

.insuredsInSection:last-child {
  border: 0;
}

.add {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.add {
  display: inline-block;
  /* width: 200px; */
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  color: cornflowerblue;
  border-radius: 5px;
  margin-top: 10px;
}

.del_class {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  color: #ff2d2d;
  border-radius: 5px;
}

.deleteIcon {
  float: right;
  margin-right: 20px;
  margin-top: .15rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: darkred;
}

.itemLabel {
}

.itemLabel.left {
  position: absolute;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;

}

.itemLabel.top {
  display: block;
  line-height: 2rem;
  font-size: 0.87rem;
  /* border-bottom: thin solid #cecece ; */
  /*color: #666;
  padding: 1rem 1rem;
  line-height: 1.8;
  font-size: 0.6rem;
  background: #f6f8fb;*/
}

.itemLabel.topList {
  display: block;
}

.setN {
  white-space: pre-line;
  width: 100% !important;
}

.ant-input {
  border: 0 !important;
  padding: 0 !important;
}

.ant-input:focus, .ant-input-focused {
  box-shadow: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 0 !important;
}

.ant-picker {
  border: 0 !important;
  width: 100% !important;
}


.ant-col-5 {
  flex: 0 0 30.83333333% !important;
  max-width: 30.833333% !important;
}

.ant-col-19 {
  flex: 0 0 69.16666667% !important;
  max-width: 69.16666667% !important;
}

.ant-space-vertical {
  width: 100% !important;
}

.ant-space-item {
  width: 100% !important;
}

.ant-picker-input > input {
  text-align: right !important;
}

:deep(.van-cell) {
  padding: 0.5rem 1.28rem !important;
  font-size: .98rem;
}

:deep(.van-collapse-item__content) {
  padding: 0.1rem 0.9rem !important;
}
</style>
<style>
.ant-picker {
  width: 100% !important;
}

.ant-space-vertical {
  width: 100% !important;
}

.ant-space-item {
  width: 100% !important;
}

.ant-picker-input > input {
  text-align: right !important;
}
.add-benefit {
  background: #fff;
}
.popup-cell {
  /* padding: 0 10px; */
  font-size: .94rem;
  line-height: 2rem;
  padding: 6px;
  color: #666666;
  border-radius: 6px;
  background-color: #ffffff;
  border-bottom: 1px solid #f3f5f5;
}
.opt-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  .van-button {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 30px;
    border-radius: 8px;
    font-size: 12px;
    border: 1px solid cornflowerblue;
  }
}
</style>
