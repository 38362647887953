<template>
  <div class="debug">
    <i class="info">{{ isDebug === 'ON' ? '开启' : '关闭' }}</i>
  </div>
</template>

<script>
import {onBeforeMount, reactive, toRefs} from 'vue'

export default {
  name: 'debug',
  setup: function () {
    const state = reactive({
      isDebug: undefined
    })
    onBeforeMount(() => {
      state.isDebug = localStorage.getItem('developer--debug')
      if (state.isDebug === 'ON') {
        state.isDebug = 'OFF'
      } else {
        state.isDebug = 'ON'
      }
      localStorage.setItem('developer--debug', state.isDebug)
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.debug {
  color: black;
  background: white;
  width: 100%;
  height: 100%;
}

.info {
  padding-top: 30px;
  display: block;
}
</style>
