<template>
  <div>
    <div class="editorBoxStyle"
         :id="sonIdT"></div>
    <a-row  type="flex" class="btnLineStyle" >
      <a-col :flex="2" style="text-align: left;margin-left: 50px">
        <span>{{ status_bar_text }}</span>
      </a-col>
      <a-col :flex="2" style="text-align: left;margin-left: 50px">
        <a-button @click="gsh" >格式化</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as monaco from 'monaco-editor'
import {toRefs, onMounted, reactive, onUnmounted, ref, watch} from 'vue'
// todo 这个编辑器针对xml语言只能高亮，不能验证，所以放个美化代码库能好看点，json是可以验证的
import vkbeautify from 'vkbeautify'

export default {
  name: 'JsonXmlEditor',
  emits: ['scriptChange','clearRunStr'],
  props: {
    content: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    sonId: {
      type: String,
    },
    height: {
      type: String,
      default: '300px'
    },
    language: {
      type: String,
      default: 'xml'
    },
    theme: {
      type: String,
      default: 'vs-dark'
    }
  },
  setup(props, {emit, expose}) {
    const state = reactive({
       editorWidth : props.width,
       editorHeight : props.height,
      status_bar_text: 'Ln: 0, Col: 0'

    })
    let sonIdT=ref('')
    watch(()=>props.sonId,(newValue)=>{
      sonIdT.value=newValue
    },
      {
        immediate:true,
        deep:true
      })
    let editor
    const initEditor = () => {
      // todo 使用这个编辑器，需要先去vue.config.js加上对应的语言，我目前只加了xml,json
      let uri = monaco.Uri.parse(`file://code-jsonxml.${props.language==='xml'?'xml':'json'}`)
      let model = monaco.editor.getModel(uri) // 如果该文档已经创建/打开则直接取得已存在的model
      if (!model) {
        // 否则创建新的model
        model = monaco.editor.createModel(props.content, props.language, uri) // 如 code="console.log('hello')", language="javascript"
      }
      monaco.languages.registerCompletionItemProvider('xml',
          {
            triggerCharacters: ['>'],
            provideCompletionItems: (model, position) => {
              const codePre = model.getValueInRange({
                startLineNumber: position.lineNumber,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
              })

              const tag = codePre.match(/.*<(\w+)>$/)?.[1]

              if (!tag) {
                return
              }

              const word = model.getWordUntilPosition(position)

              return {
                suggestions: [
                  {
                    label: `</${tag}>`,
                    kind: monaco.languages.CompletionItemKind.EnumMember,
                    insertText: `$1</${tag}>`,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    range: {
                      startLineNumber: position.lineNumber,
                      endLineNumber: position.lineNumber,
                      startColumn: word.startColumn,
                      endColumn: word.endColumn,
                    }
                  }
                ]
              }
            }
          })
      editor = monaco.editor.create(document.getElementById(sonIdT.value), {
        model: model, // 采用model的形式
        value: props.content,
        language: props.language,
        theme: props.theme,
        tabCompletion: 'on',
        cursorSmoothCaretAnimation: true,
        formatOnPaste: true,
        mouseWheelZoom: true,
        folding: true, //代码折叠
        autoClosingBrackets: 'always',
        autoClosingOvertype: 'always',
        autoClosingQuotes: 'always',
        selectOnLineNumbers: true,//显示行号
        roundedSelection: false,
        readOnly: false, // 只读
        cursorStyle: 'line', //光标样式
        automaticLayout: true, //自动布局
        glyphMargin: true, //字形边缘
        useTabStops: false,
        fontSize: 14, //字体大小
        autoIndent: true, //自动布局
        quickSuggestionsDelay: 100, //代码提示延时
        minimap: {
          enabled: false // 不要小地图
        }
      })
      // 监控光标位置变化
      editor.onDidChangeCursorPosition((val) => {
        state.status_bar_text = `Ln: ${val.position.lineNumber}，Col: ${val.position.column}`
      })
    }

    const getRunScript = () => {
      const originalStr = editor.getModel().getValue()
      const originalStrArray = editor.getModel().getLinesContent()
      emit ('scriptChange', {
        originalStr,
        originalStrArray
      })
    }
    const restValue=()=>{
      editor.setValue('')
    }
    /**
     * 看得到我不想起名了吧
     * 这里是格式化代码的方法，使用的插件vkbeautify，先尝试了【js-beautify】插件，不太行
     */
  const gsh = () => {
      if (props.language === 'xml') {
        editor.getModel().setValue(vkbeautify.xml(editor.getModel().getValue()))
      }
      if (props.language === 'json') {
        editor.getModel().setValue(vkbeautify.json(editor.getModel().getValue()))
      }
  }
    expose({
      getRunScript,
      restValue
    })
    onMounted(()=>{
      initEditor()
    })
    onUnmounted(()=> {
      // 销毁
      editor.dispose()
    })
    return {
      ...toRefs(state),
      getRunScript,
    gsh,
      restValue,
      sonIdT
    }
  }
}
</script>

<style scoped>
.editorBoxStyle {
  height: 90%;
  width: 100%;
  text-align: left;
}
.btnLineStyle {
  background-color:#ebebeb;
  height: 10%;
  width: 100%;
  align-items: center;
}
</style>
