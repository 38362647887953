<template>

  <div class="clearfix">
    <div v-if="styleType === 1">
      <a-upload
        :max-count="1"
        v-model:file-list="fileList"
        @change="handleChange"
        @preview="handlePreview"
        :customRequest="uploadFile"
        :accept="null"
        :beforeUpload="beforeUpload"
      >
        <a-button>
          <upload-outlined></upload-outlined>
          上传脚本文件
        </a-button>
      </a-upload>
    </div>
    <div v-else>
    <a-upload
      v-model:file-list="fileList"
      list-type="picture-card"
      :multiple="true"
      @change="handleChange"
      @preview="handlePreview"
      :customRequest="uploadFile"
      :accept="filetypeL"
      style="margin-bottom:15px"
      :beforeUpload="beforeUpload"
      :max-count="maxNum"
      :show-upload-list="{showPreviewIcon:true}"
    >
      <div v-if="fileList.length < maxNum">
        <plus-outlined/>
        <div style="margin-top: 8px">上传</div>
      </div>
      <template #itemRender="{file}">
        <div class="demo-upload-list">
          <div>
            <img
              v-if="file.status==='done'"
              style="width:100%;height:100%"
              :src="file.thumbUrl"
              alt="正在飞速加载......"
            >
            <a-progress v-else
                        :width="80" type="circle" :percent="file.percent"/>
          </div>
          <div class="demo-upload-list-cover">
            <EyeOutlined @click="handlePreview(file)"/>
            <DeleteOutlined @click="handleRemove(file)"/>
          </div>
        </div>
<!--        // todo 如果名字很长需要处理-->
        <p style="text-align:center;">{{ file.name }}</p>
      </template>
    </a-upload>
    <a-modal :visible="previewVisible" :title="previewTitle"  @cancel="handleCancel">
      <!--      //  TODO  这里做一些类型判断，用来区别各文件展示方式，例如视频，图片-->
      <!--      //  TODO  图片类型--预览-->
      <!--      //  TODO  压缩包或其他类型--下载-->
      <!--      //  TODO  视频类型--播放-->
      <!--      //  TODO  办公软件类型--新开页面预览或下载？？？ iframe-->
      <!--      //  TODO  下载的方法我没写，需要补充到js文件里，建议使用返回blob格式-->
      <video v-if="compareType('mp4',type)" style="width: 100%" controls="controls">
        您的浏览器不支持播放该视频！
        <source :src="previewUrl" type="video/mp4">
      </video>
      <div v-else-if="compareType('zip',type)" style="width: 100%"  :src="previewUrl">
        压缩包下载地址<a href="previewUrl"></a>
      </div>
      <img v-else alt="example" style="width: 100%" :src="previewImage"/>
      <template #footer>
        <a-button type="primary" @click="downloadFile">下载</a-button>
      </template>
    </a-modal>
    </div>
  </div>
</template>

<script>

import {reactive, toRefs, onMounted, onBeforeMount} from 'vue'
import {EyeOutlined, DeleteOutlined} from '@ant-design/icons-vue'

import {removeObject, uploadObject, getDownloadInfo} from '../../utils/upload'
import {addPropToItem, removeByValue, selectItemByProp} from '../../utils/ArrayUtils'
import {docIcon, zipIcon, defaultIcon, xlsIcon, pptIcon, pdfIcon, rtfIcon, txtIcon, wpsIcon,} from '../../utils/icon64list'
import { message } from 'ant-design-vue'
import {request} from '../../utils/request'

export default {
  name: 'uploadFileToCos',
  components: {
    EyeOutlined: EyeOutlined,
    DeleteOutlined: DeleteOutlined
  },
  props: {
    styleType: {
      type: Number,
      default: () => 0
    },
    maxNum: {
      type: [Number, String],
      default: () => 1
    },
    maxBig:{
      type: [Number, String],
      default: () => 2
    },
    formatList: {
      type: Array,
      default: () => []
    },
    selectList:{ // 限制类型
        type: Array,
        default:()=> []
      },
    echoList:{
      type: Array,
        default:()=> []
    }
  },
  emits:['fileChange'],
  setup(props, context) {
    const params = reactive({
      cosType: 'cos',
      fileObj : {},
      cos: {
        bucket: '',
        region: '',
        folder: '',
        TmpSecretId: '',
        TmpSecretKey: '',
        XCosSecurityToken: '',
        StartTime: '',
        ExpiredTime: '',
        ScopeLimit: true
      },
      styleType: props.styleType,
      maxNum: props.maxNum *1,
      maxBig:props.maxBig,
      fileList: props.echoList,
      filetypeL: '',
      previewVisible: false,
      previewTitle: '',
      previewImage: '',
      type: '',
      previewUrl: '',
      previewVideoImgUrl: '',
      showProgress: false,


    })
    console.log('zx----------222', params.styleType, params.maxBig)
    const getCOSType = () => {
      let uri = '/product-service/product/cos/getTempToken'
      let param = {
        'data': {
          'type': ''
        },
      }
      return request(uri, param).then(res => {
        if (res.code === 1) {
          return res.result.osType
        }
        return ''
      })
    }
    onBeforeMount(async ()=> {
      params.cosType = await getCOSType()
    })
    const getVideoBase64 = (file, url) => {
      console.debug(url)
      let dataURL = ''
      let video = document.createElement('video')
      video.setAttribute('crossOrigin', 'anonymous')//处理跨域
      video.setAttribute('src', url)
      video.setAttribute('width', 400)
      video.setAttribute('height', 240)
      video.setAttribute('preload', 'auto')
      video.currentTime = 2 // 第一帧
      video.addEventListener('loadeddata', () => {
        let canvas = document.createElement('canvas')
        let width = video.width  //canvas的尺寸和图片一样
        let height = video.height
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(video, 0, 0, width, height) //绘制canvas
        dataURL = canvas.toDataURL('image/jpeg') //转换为base64
        file.thumbUrl = dataURL
        console.debug(dataURL)
      })
    }
    const uploadToCos = (uploadFile) => {
      let uid = uploadFile.uid
      uploadObject(uploadFile, params.cosType,(type, fileUrl, origin_file_name, key, p) => {
        if (p && fileUrl === '') {
          // 设置进度及状态
          if (p !== 100) {
            addPropToItem(params.fileList,'percent',p,'uid',uid)
          } else {
            addPropToItem(params.fileList,'percent',p,'uid',uid)
            addPropToItem(params.fileList,'status','done','uid',uid)
          }
        } else {
          // console.debug(`类型:${type}\ncos-url:${fileUrl}\n文件名:${origin_file_name}\nKEY:${key}`)
          // console.debug(`上传的对象：${JSON.stringify(uploadFile)}`)
          // console.debug(`已上传文件列表：${JSON.stringify(params.fileList)}`)

          let uid = uploadFile.uid
          params.fileList.forEach(file => {
            if (file.uid === uid) {
              file['playUrl'] = fileUrl
              file['cosKey'] = key
              if (file.thumbUrl === '') {
                console.debug(type)
                //  TODO 只做了图文处理，--- 还差 其他文件类型默认图，及展示对应文件的弹窗
                let comType = compareType('mp4',type)
                console.debug(comType)
                if (compareType('mp4',type)) {
                  file.thumbUrl = getVideoBase64(file, fileUrl)
                } else if (compareType('doc',type)||compareType('docx',type)){
                  file.thumbUrl = docIcon
                } else if (compareType('zip',type)||compareType('rar',type)||compareType('7z',type)){
                  file.thumbUrl = zipIcon
                } else if (compareType('xlsx',type)||compareType('xls',type)){
                  file.thumbUrl = xlsIcon
                } else if (compareType('ppt',type)||compareType('pptx',type)){
                  file.thumbUrl = pptIcon
                } else if (compareType('pdf',type)){
                  file.thumbUrl = pdfIcon
                }else if (compareType('rtf',type)){
                  file.thumbUrl = rtfIcon
                }else if (compareType('txt',type)){
                  file.thumbUrl = txtIcon
                }else if (compareType('wps',type)){
                  file.thumbUrl = wpsIcon
                }else {
                  file.thumbUrl = defaultIcon
                }
              }
            }
          })
          console.debug('更新属性后的已上传文件列表',params.fileList)
          // 将更新的值 返回
          context.emit('fileChange', params.fileList)
        }
      })
    }

    /**
     * 根据uid获取已上传列表的file对象
     * @param e
     * @returns {*}
     */
    const getFileFormListByEuid = (e) => {
      return selectItemByProp(params.fileList, 'uid', e.uid)[0]
    }

    /**
     * 执行移除操作
     * @param e
     */
    const handleRemove = (e) => {
      let file = getFileFormListByEuid(e)
      if (file.cosKey) {
        removeObject(file.cosKey, params.cosType, (cb) => {
          if (cb) {
            removeByValue(params.fileList, 'uid', file.uid)
            // 删除之后的值返回出去
            context.emit('fileChange', params.fileList)
          }
        })
      }
    }
    const handlePreview = async (e) => {
      params.fileObj = e
      let file = getFileFormListByEuid(e)
      console.debug('执行步骤: handlePreview',file)
      params.previewTitle = file.name
      params.type = file.name.split('.').pop() // 得到上传的后缀名
      // TODO 在这里处理弹窗预览的东西
      if (params.type === 'mp4') {
        // 在此处获取
        params.previewUrl = file.playUrl
      } else {
        params.previewImage = file.thumbUrl
      }
      params.previewVisible = true
    }
    const handleChange = (e) => {
      console.debug(`执行步骤：handleChange:${JSON.stringify(e)}`)
      console.debug(`执行步骤：handleChange:${JSON.stringify(params.fileList)}`)
      console.log('zx----------assad',e)
      // if (!e.file.status) {
      //   // loading.value = false
      //   message.error('upload error')
      //   return
      // }
      context.emit('fileChange', params.fileList)
    }
    // 上传腾讯云
    const uploadFile = (e) => {
      uploadToCos(e.file)
      console.debug('上传后列表：', params.fileList)
    }
    const beforeUpload = (file) => {
      console.log('zx----------ww',params.fileList,file)
      console.log('zx----------www', file.size, file.size / 1024 / 1024 ,params.maxBig)
      //对上传文件大小做判断
      const isLt2M = file.size / 1024 / 1024 < params.maxBig
      if (!isLt2M) {
        // params.fileList=[]
        message.warning(`请上传文件大小不超过 ${params.maxBig} M的文件`)
        // return false
        return new Promise(()=>{})
      }
      if(!props.selectList.length) {
        return
      }

      // console.debug('上传的文件',file)
      // TODO 做判断：1.文件格式，2. 数量
      // 限制类型
      let fileType = file.name.split('.').pop() // 得到上传的后缀名
      let format = props.selectList.map(item => item.toLocaleUpperCase())
      if(!format.includes('.' + fileType.toLocaleUpperCase())){
        message.warning(`请上传 ${format} 格式的文件`)
        return false
      }else {
        return true
      }
    }
    const handleCancel = () => {
      params.previewVisible = false
      params.previewImage = ''
      params.previewUrl = ''
      //  TODO  注意播放视频关闭弹窗，也要处理
    }
    // const getList = () => {
    //   getObjectList((cb) => {
    //     console.debug(`桶数据：${JSON.stringify(cb)}`)
    //   })s
    // }
    const compareType = (key,type) => {
      console.debug('得到想要的值', key,type)
      if (key.toUpperCase() === type.toUpperCase()) {
        return true
      }
      return false
    }

    // 预览下载
    const downloadFile = () =>{
      let file = getFileFormListByEuid(params.fileObj)
      if (file.cosKey) {
        getDownloadInfo(file, params.cosType)
      }
    }

    onMounted(() => {
      //限制的类型进行遍历
      params.filetypeL = props.selectList.join()
      // console.debug('限制的类型', params.filetypeL)
    })
    return {
      ...toRefs(params),
      handlePreview,
      handleChange,
      uploadFile,
      beforeUpload,
      handleCancel,
      handleRemove,
      compareType,
      downloadFile
    }
  }
}
</script>

<style scoped>
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover :deep(.anticon-eye) {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-left: 15px;
}

.demo-upload-list-cover :deep(.anticon-delete) {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 15px;
}

.demo-upload-list {
  display: inline-block;
  width: 104px;
  height: 104px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}
</style>
