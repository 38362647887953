<template>
  <Toolbar
    style="border-bottom: 1px solid #ccc"
    :editor="editorRef"
    :defaultConfig="toolbarConfig"
    :mode="mode"
  />
  <Editor
    style="height: 400px;width: 100%"
    v-model="valueHtml"
    :defaultConfig="editorConfig"
    :mode="mode"
    @onCreated="handleCreated"
    @onChange="onChange"
  />
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import {onBeforeUnmount, onMounted, reactive, shallowRef, toRefs} from 'vue'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {uploadObject} from '../../utils/upload.js'
import {message} from 'ant-design-vue'

export default {
  components: { Editor, Toolbar },
  props:{
    contentHtml: {
      type: String,
      default: '<p></p>'
    },
    text:{
      type: [String,Number],
      default:()=> ''
    } ,
    readOnly:{
      type: Boolean,
      default:()=> false
    } ,
  },
  emits: ['update:text', 'getWangEditorValue'],
  setup(props, {emit}) {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
    // 内容 HTML
  const state = reactive({
    valueHtml: props.contentHtml,
  })
    // 模拟 ajax 异步获取内容
    onMounted(() => {
    })

    const uploadFile = (file, insertFn) => {
      if (file.size > 2 * 1024 * 1024) {
        message.warn('图片大小不能超过2M')
        return
      }
      // 现阶段先用图片转base64方式去做，这块后续改为obs上传，且图片富文本的生成放到后端做
      uploadObject(file, 'obs', (type, fileUrl, origin_file_name, key, p) => {
        console.log('上传成功', type, fileUrl, origin_file_name, key, p)
        insertFn(fileUrl)
      })

      // // 富文本内容太大了，算了，还是上传吧
      // fileToBase64(file).then(res => {
      //   insertFn(res)
      // })
    }
    // const fileToBase64 = (file) => {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.onload = function (e) {
    //       // 读取完成后，e.target.result 将包含data url形式的Base64编码
    //       resolve(e.target.result)
    //     }
    //     reader.onerror = function (e) {
    //       reject(e.message)
    //     }
    //     // 使用readAsDataURL方法读取文件内容，这将返回一个Base64编码的data URL
    //     reader.readAsDataURL(file)
    //   })
    // }
    const toolbarConfig = {
      excludeKeys: ['insertImage', 'group-video', 'fullScreen', 'emotion'], // 删除上传图片的网络图片
    }
    const editorConfig = {
      placeholder: '请输入内容...',
      readOnly: props.readOnly,
      MENU_CONF: {
        uploadImage: {
          customUpload: uploadFile,
        }
      }
    }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }
    const onChange = () => {
      // console.log(1111222,editorRef.value, editorRef.value.getHtml())
      let html = editorRef.value.getHtml()
      // let html = editorRef.value.getHtml().replace(/&nbsp;/g, ' ')
      emit('update:text', html)
      emit('getWangEditorValue', html)
    }

    return {
      ...toRefs(state),
      editorRef,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      onChange,

    }
  }
}
</script>

<style scoped>
 /deep/ .w-e-text-container {
  /*// height: 50px!important;*/
 }
</style>
