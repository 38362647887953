<style scoped>
.plan_wrap {

}

.plan_title {
  font-size: 1.2rem;
  font-weight: 500;
}

.risk_factors {
  transition: height .3s ease;
}

.risk_divider {
  margin: 6px 0;
  border: 0 !important;
}

.risk_title {
  font-size: 1rem;
  font-weight: 500;
  padding: .5rem;
}

.risk_title label {
  width: calc(100% - 50px);
  display: inline-block;
}

.plan_duty {
  margin-top: .5rem;
}

.duty_class {
  position: relative;
  margin-bottom: .2rem;
  max-width: 98%;
}

.duty_tip {
  position: absolute;
  margin-left: .5rem;
}

.duty_content {
  line-height: 1.5rem;
}

:deep(.ant-checkbox-disabled + span) {
  color: inherit;
}

.element-radioA {
  flex: 9;
  text-align: right;
}

.label-leftA {
  flex: 3;
}

.element_wrapA {
  margin: 0.5rem;
  display: flex;
}

.ant-modal-wrap {
  background: rgba(0, 0, 0, .5);
}

.duty_detail_class {
  line-height: 1.6rem;
  -webkit-flex: 1;
  margin-top: .5rem;
  color: #333;
  font-size: 15px;
  /* text-indent: 2rem; */
  max-height: 480px;
  overflow: auto;

}

.duty_detail_class::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.dutyName_class {
  font-weight: bold;
  color: #333;
  padding: 0 0.4rem;
  margin: 0.7rem auto 1rem;
  text-align: center;
  font-size: 1.28rem;
}

.sub {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

.duty_button {
  display: flex;
  justify-content: center;
}

.plan_wrap :global(.ant-modal-content) {
  border-radius: 20px !important;
}

:global(.ant-modal-header) {
  border-radius: 20px !important;
}

/*附加险*/
:deep(.van-collapse-item__content) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.van-collapse-item__title) {
  padding-left: 0;
  padding-right: 0;
}

.van-checkbox-group .risk_title {
  padding-left: 0;
  padding-right: 0;
}

.buttonStyle {
  color: #ffba00;
  width: 100vw;
  background: #fff3d1;
  height: 2.7rem;
  margin-left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.van-collapse-item__wrapper) {
  /*overflow: visible;*/
}

:deep(.van-col--18) {
  display: flex;
  justify-content: end;
  line-height: 2rem;
  height: 2rem;
}

:deep(.van-col--18 span) {
  color: #FF4D4D;
  font-size: 1.2rem;
  font-weight: bold;
}

:deep(.van-collapse-item__title--expanded) {
  color: #0078fc;
}

:deep(.van-collapse-item__title) {
  color: #0078fc;
  line-height: 1.7rem;
}

.site-menu-sub {
  padding: 0px;
}

.sub-menu-enter-active,
.sub-menu-leave-active {
  transition: all .5s ease-in-out;
  max-height: 300px; /* 这里将最大高度设置为折叠元素不可能超越的高度 比如1000px */
}

.sub-menu-enter,
.sub-menu-leave-to {
  opacity: 0;
  max-height: 0px;
}

:root:root {
  /*--van-button-primary-background: red;*/
  --van-collapse-item-duration: 10s !important;
}

/*附加险计划样式*/
/*.plan_class {*/
/*  width: 100%;*/
/*  display: flex;*/
/*  border-bottom: 1px solid #f3f5f5;*/
/*  justify-content: space-evenly !important;*/
/*}*/
.plan_class {
  border-bottom: 1px solid #f3f5f5;
}

.header_plan_span {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: .5rem;
  height: 2rem;

}

.header_plan_span span {
  border-right: 1px solid #9ab0ce;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -1px;
}

/*附加险眼计划样式选中高亮*/
.addition {
  color: #0078fc !important;
}


.img-icon {
  height: 20px;
}

.select_heard{
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.select_heard h3{
  font-size: 1.2rem;
  font-weight: 500;
  color: #010721;
}
.select_heard :deep(.van-checkbox){
  padding: .5rem 0;
}
.select_heard :deep(.van-checkbox__label) {
  color: #666666;
}

.select_heard2 {
  padding-bottom: 0;
}
</style>
<template>
  <div class="plan_wrap">
    <!-- 第一主险 -->
    <template v-for="(factorItem, fIndex) in firstMainRiskInfo['calculateFactor']"
              :key="'calculateFactor_' + firstMainRiskInfo['riskId'] + '_' + factorItem['factorId']">
      <!--      <div v-if="factorItem['factorType'] === 'TextAge'">{{JSON.stringify(factorItem)}}</div>-->
      <div v-show="factorItem['isShow'] === 'Y'">
        <InsureElement
          :ref="(el) => setElementRef(el, factorItem['factorType'])"
          :elementKey="getSpecialItem(factorItem['factorType'],0)"
          :factorId="factorItem['factorId']||''"
          :label="factorItem['elementName'] || ''"
          labelPosition="left"
          :elementType="getSpecialItem(factorItem['operationType'],1,factorItem['factorType'])"
          :dataType="factorItem['dataType']"
          :dataList="factorItem['factorValueList']"
          :isPremiumCal="factorItem['isPremiumCal']"
          :isEdit="factorItem['isEdit']"
          :isRiskSign="'1'"
          v-model:modelValue="riskFormData.riskList[firstMainRiskInfo.index]['calculateFactor'][fIndex]['factorValue']"
          v-model:modelName="riskFormData.riskList[firstMainRiskInfo.index]['calculateFactor'][fIndex]['factorValueName']"
          :riskId="firstMainRiskInfo.riskId"
          :riskSingeId="productInfo['riskList'][0]['riskId']"
          :supCode="productInfo['supplierCode']"
          @factorChange="factorChange"
          :riskFormData='riskFormData'
          :isPremCalculating="calculating"
        ></InsureElement>
      </div>
    </template>

    <!--    可选责任  -->
    <div class="select_heard" v-if="riskFormData.riskList[firstMainRiskInfo.index]['hasOptionalDuty']">
      <h3>可选责任</h3>
      <template v-for="(dutyItem) in riskFormData.riskList[firstMainRiskInfo.index]['dutyList']"
                :key="'optionalDuty_' + dutyItem.id">
        <van-checkbox v-if="optionalDutys.includes(dutyItem.id) && dutyItem.isShow" v-model="dutyItem.isSelected"
                      :name="dutyItem.id" @click="optionalDutyChange(dutyItem)">{{ dutyItem.dutyName }}
        </van-checkbox>
      </template>
    </div>
    <!--    主险+附加险  -->
    <van-collapse
      v-if="firstMainRiskInfo['additionalRiskList']&&filterRiskList(firstMainRiskInfo['additionalRiskList'], 1,1).length>0"
      v-model="activeNamesZ">
      <van-collapse-item name="1">
        <template #title>
          <div style="font-size: 1.25rem">
            <paper-clip-outlined/>
            组合险种
          </div>
        </template>
        <template #right-icon>
          <div>
            <CaretDownFilled v-if="activeNames.includes('1')"/>
            <CaretUpFilled v-else/>
          </div>
        </template>

        <van-checkbox-group v-model="checkedExit">
          <template v-for="riskItem in filterRiskList(firstMainRiskInfo['additionalRiskList'], 1, 1)"
                    :key="'risk_' + riskItem['riskId']">
            <a-divider class="risk_divider"/>
            <div class="risk_wrap">
              <div class="risk_title">
                <van-checkbox :name="riskItem['riskName']" @click="initButton(riskItem)">
                  <!-- <img src="../../assets/img/icon_fu.png" style="width:1.5rem"> -->
                  {{ riskItem['riskName'] }}
                  <template #icon="props">
                    <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                  </template>
                </van-checkbox>
              </div>

              <transition name="sub-menu">
                <a-spin :spinning="spinning" :delay="50" v-show="checkedExit.includes(riskItem['riskName'])">
                  <div v-show="checkedExit.includes(riskItem['riskName'])">
                    <transition name="sub-menu">
                      <div v-show="riskItem.isShow">
                        <div class="block_card">
                          <div class="plan_class">
                            <div class="header_plan"
                                 v-for="(factor,index) in riskItem['calculateFactor']"
                                 :key="index+new Date()"
                            >
                            <span v-if="factor.factorType==='Plan'&&factor.operationType==='select'"
                                  class="header_plan_span">
                              <span v-for="(factorTwo,index2) in factor.factorValueList"
                                    :key="factorTwo.value"
                                    @click="handelActive(riskItem['riskId'],index2)"
                              >
                                <span :class="{addition:activeAddition[riskItem['riskId']]===index2}">{{ factorTwo.name }}</span>
                              </span>
                            </span>
                            </div>
                          </div>
                        </div>
                        <template v-for="(factorItem, fIndex2) in riskItem['calculateFactor']"
                                  :key="'calculateFactor_' + riskItem['riskId'] + '_' + factorItem['factorId']">
                          <div v-show="factorItem['isShow']">
                            <InsureElement
                              :ref="(el) => setElementRef(el, riskItem['riskId']+factorItem['factorType'])"
                              :elementKey="getSpecialItem(factorItem['factorType'],0)"
                              :label="factorItem['elementName']"
                              labelPosition="left"
                              :elementType="getSpecialItem(factorItem['operationType'],1,factorItem['factorType'])"
                              :dataType="factorItem['dataType']"
                              :dataList="factorItem['factorValueList']"
                              :isPremiumCal="factorItem['isPremiumCal']"
                              :isEdit="factorItem['isEdit']"
                              v-model:modelValue="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorValue']"
                              v-model:modelName="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorValueName']"
                              :riskId="riskFormData.riskList[riskItem.index]['riskId']"
                              :supCode="productInfo['supplierCode']"
                              :riskSingeId="productInfo['riskList'][0]['riskId']"
                              @factorChange="factorChange"
                              :riskFormData='riskFormData'
                              v-show="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['isShow']&&!(riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorType']==='Plan')"
                              :disabled="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorInMain']"
                              :isPremCalculating="calculating"
                            ></InsureElement>
                          </div>

                        </template>
                        <!-- 附加险 可选责任 -->
                        <div class="select_heard select_heard2"
                             v-if="riskFormData.riskList[riskItem.index]['hasOptionalDuty']">
                          <h4>可选责任</h4>
                          <template v-for="dutyItem2 in riskFormData.riskList[riskItem.index]['dutyList']"
                                    :key="'optionalDuty2_' + dutyItem2.id">
                            <van-checkbox v-if="optionalDutys.includes(dutyItem2.id) && dutyItem2.isShow"
                                          v-model="dutyItem2.isSelected"
                                          :name="dutyItem2.id" @click="optionalDutyChange(dutyItem2)"
                                          :bind-group="false">{{ dutyItem2.dutyName }}
                            </van-checkbox>
                          </template>
                        </div>
                        <van-row justify="end" v-if="riskItem.premiumCalculation !== '2'">
                          <van-col span="18">首期保费：
                            <span v-if="riskItem.newPremium">{{ riskItem.newPremium || 0 }}元</span>
                            <span v-else>{{ riskItem.premium || 0 }}元</span>
                          </van-col>
                        </van-row>
                      </div>
                    </transition>

                    <div class='buttonStyle' @click="riskItem.isShow=!riskItem.isShow">
                      <DownCircleOutlined v-if="!riskItem.isShow"/>
                      <UpCircleOutlined v-else/>&nbsp;
                      {{ riskItem.isShow ? '收起' : '展开' }}
                    </div>
                  </div>
                </a-spin>
              </transition>
            </div>
          </template>
        </van-checkbox-group>
      </van-collapse-item>
    </van-collapse>
    <!--    附加险  -->
    <van-collapse v-if="firstMainRiskInfo['additionalRiskList']&&filterRiskList(firstMainRiskInfo['additionalRiskList'], 2).length>0"
                  v-model="activeNames">
      <van-collapse-item name="1">
        <template #title>
          <div style="font-size: 1.25rem">
            <paper-clip-outlined/>
            附加保障
          </div>
        </template>
        <template #right-icon>
          <div>
            <CaretDownFilled v-if="activeNames.includes('1')"/>
            <CaretUpFilled v-else/>
          </div>
        </template>

        <van-checkbox-group v-model="checkedExit">
          <!-- {{firstMainRiskInfo['additionalRiskList']}} -->
          <template v-for="riskItem in filterRiskList(firstMainRiskInfo['additionalRiskList'], 2, 0)"
                    :key="'risk_' + riskItem['riskId']">
            <a-divider class="risk_divider"/>
            <div class="risk_wrap">
              <div class="risk_title">
                <van-checkbox :name="riskItem['riskName']" @click="initButton(riskItem)">
                  <img src="../../assets/img/icon_fu.png" style="width:1.5rem">
                  {{ riskItem['riskName'] }}
                  <template #icon="props">
                    <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                  </template>
                </van-checkbox>
              </div>

              <transition name="sub-menu">
                <a-spin :spinning="spinning" :delay="50" v-show="checkedExit.includes(riskItem['riskName'])">
                  <div v-show="checkedExit.includes(riskItem['riskName'])">
                    <transition name="sub-menu">
                      <div v-show="riskItem.isShow">
                        <div class="block_card">
                          <div class="plan_class">
                            <div class="header_plan"
                                 v-for="(factor,index) in riskItem['calculateFactor']"
                                 :key="index+new Date()"
                            >
                            <span v-if="factor.factorType==='Plan'&&factor.operationType==='select'"
                                  class="header_plan_span">
                              <span v-for="(factorTwo,index2) in factor.factorValueList"
                                    :key="factorTwo.value"
                                    @click="handelActive(riskItem['riskId'],index2)"
                              >
                                <span :class="{addition:activeAddition[riskItem['riskId']]===index2}">{{ factorTwo.name }}</span>
                              </span>
                            </span>
                            </div>
                          </div>
                        </div>
                        <template v-for="(factorItem, fIndex2) in riskItem['calculateFactor']"
                                  :key="'calculateFactor_' + riskItem['riskId'] + '_' + factorItem['factorId']">
                          <div v-show="factorItem['isShow']">
                            <InsureElement
                              :ref="(el) => setElementRef(el, riskItem['riskId']+factorItem['factorType'])"
                              :elementKey="getSpecialItem(factorItem['factorType'],0)"
                              :label="factorItem['elementName']"
                              labelPosition="left"
                              :elementType="getSpecialItem(factorItem['operationType'],1,factorItem['factorType'])"
                              :dataType="factorItem['dataType']"
                              :dataList="factorItem['factorValueList']"
                              :isPremiumCal="factorItem['isPremiumCal']"
                              :isEdit="factorItem['isEdit']"
                              v-model:modelValue="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorValue']"
                              v-model:modelName="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorValueName']"
                              :riskId="riskFormData.riskList[riskItem.index]['riskId']"
                              :supCode="productInfo['supplierCode']"
                              :riskSingeId="productInfo['riskList'][0]['riskId']"
                              @factorChange="factorChange"
                              :riskFormData='riskFormData'
                              v-show="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['isShow']&&!(riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorType']==='Plan')"
                              :disabled="riskFormData.riskList[riskItem.index]['calculateFactor'][fIndex2]['factorInMain']"
                              :isPremCalculating="calculating"
                            ></InsureElement>
                          </div>

                        </template>
                        <!-- 附加险 可选责任 -->
                        <div class="select_heard select_heard2"
                             v-if="riskFormData.riskList[riskItem.index]['hasOptionalDuty']">
                          <h4>可选责任</h4>
                          <template v-for="dutyItem2 in riskFormData.riskList[riskItem.index]['dutyList']"
                                    :key="'optionalDuty2_' + dutyItem2.id">
                            <van-checkbox v-if="optionalDutys.includes(dutyItem2.id) && dutyItem2.isShow"
                                          v-model="dutyItem2.isSelected"
                                          :name="dutyItem2.id" @click="optionalDutyChange(dutyItem2)"
                                          :bind-group="false">{{ dutyItem2.dutyName }}
                            </van-checkbox>
                          </template>
                        </div>
                        <van-row justify="end" v-if="riskItem.premiumCalculation !== '2'">
                          <van-col span="18">首期保费：
                            <span v-if="riskItem.newPremium">{{ riskItem.newPremium || 0 }}元</span>
                            <span v-else>{{ riskItem.premium || 0 }}元</span>
                          </van-col>
                        </van-row>
                      </div>
                    </transition>

                    <div class='buttonStyle' @click="riskItem.isShow=!riskItem.isShow">
                      <DownCircleOutlined v-if="!riskItem.isShow"/>
                      <UpCircleOutlined v-else/>&nbsp;
                      {{ riskItem.isShow ? '收起' : '展开' }}
                    </div>
                  </div>
                </a-spin>
              </transition>
            </div>
          </template>
        </van-checkbox-group>
      </van-collapse-item>
    </van-collapse>

    <!-- 已选险种的保障范围 -->
    <a-divider class="risk_divider"/>
    <van-index-anchor :index="indexValue" style="height: 0;width: 0; visibility: hidden;"></van-index-anchor>
    <div class="plan_title" id="coverage_id" v-if="guaranteeShow">保障范围</div>
    <template v-for="riskItem2 in riskFormData['riskList']" :key="'duty_risk_' + riskItem2['riskId']">
      <template v-for="(dutyItem, dutyIdx) in riskItem2['dutyList']"
                :key="'duty_risk_' +riskItem2['riskId'] + dutyIdx + '_' + dutyItem['dutyCode']">
        <div class="plan_duty" v-if="riskItem2.isSelected && dutyItem.isShow && dutyItem.isSelected && dutyItem.premiumAmount !== 0">
          <p class="duty_class">
            {{ dutyItem.dutyName }}
            <question-circle-outlined class="duty_tip" v-if="dutyItem.dutyIntroduce" @click="showDutyDetail(dutyItem)"/>
          </p>
          <p style="color: #999;" v-if="dutyItem.introduceFirstline">{{ dutyItem.introduceFirstline }}</p>
        </div>
      </template>
    </template>
    <!-- 已选险种的保障范围 弹窗 -->
    <a-modal :width="350" centered v-model:visible="dutyVisible" :keyboard="false" :closable="false" :footer="null"
             destroyOnClose
             @ok="onDutyDetailClose">
      <div>
        <h2 class="dutyName_class">{{ dutyDetail.dutyName }}</h2>
        <div class="sub">{{ dutyDetail.introduceFirstline }}</div>
        <div class="duty_detail_class">
          <p v-html="dutyDetail.dutyIntroduce"></p>
        </div>
        <div class="duty_button">
          <a-button @click="handelOk" type="primary" shape="round" block
                    style="width: 200px; height: 2.5rem;font-size: 18px; background-color: #0079FE;">我知道了
          </a-button>
        </div>
      </div>
    </a-modal>
<!--  校验弹窗  -->
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical"
             @ok="insuranceErrorHandleOk">
      <p style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs, watch} from 'vue'
import InsureElement from './product_detail_element'
import {isJDALAPIProducts} from '../../utils/utlis'
import {Button, Checkbox, CheckboxGroup, Col, Collapse, CollapseItem, Icon, IndexAnchor, IndexBar, Row} from 'vant'
import 'vant/lib/index.css'
import activeIcon from '../../assets/img/sign/check_b.png'
import inactiveIcon from '../../assets/img/sign/check_no.png'
import PremCalculator from '../../utils/prem_calculator'
import {getAmntElementRefAndSpecailCal, newPremiumInTK, specialRiskAMN} from '../../utils/special_treatment'
import store from '@/store'
import {entranceMethod} from '@/utils/RT-RUIEBAO-rule'

export default defineComponent({
  components: {
    InsureElement,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    tradeNo: {
      type: String,
      required: false
    },
    indexValue: {
      type: String,
      required: false
    },
    factorObject: {
      type: Object
    }
  },
  setup(props, {emit, expose}) {
    const activeAddition = {}
    const insureEl = {}
    const setElementRef = (el, type) => {
      if (el) {
        insureEl[type] = el
      }
    }
    // 附加险模块
    // 手风琴需要的变量
    const activeNames = ref(['1'])
    const activeNamesZ = ref(['1'])
    const checkedExit = ref([])
    const spinning = ref(true)
    const initButton = (riskItem) => {
      console.log('initButton', riskItem)
      // coverageDataList
      let isSelected = ''
      let TKSelected = state.riskFormData.riskList.filter(item => item.riskSign === '1')[0]
      if (checkedExit.value.includes(riskItem['riskName'])) {
        riskItem.isShow = true
      }
      spinning.value = true
      state.riskFormData.riskList.forEach(item => {
        if (item.riskId === riskItem.riskId) {
          item.isSelected = !item.isSelected
          isSelected = item.isSelected
        }
      })
      if(checkedExit.value.includes(riskItem['riskName'])){
        let flag = newPremiumInTK(TKSelected,checkedExit.value.includes(riskItem['riskName']),riskItem)
        if(flag && !flag.type){
          state.isIdentical = true
          state.detailModal = flag.message
        }
      }
      editRenewinsurance(riskItem,'risk',checkedExit.value.includes(riskItem['riskName']))
      if(window.checkDHAdditionalRisk && isSelected){
        const riskList = state.riskFormData.riskList.filter(item => item.riskSign === '1')[0].calculateFactor
        let flag = window.checkDHAdditionalRisk(riskItem, riskList)
        if(flag && !flag.type){
          state.isIdentical = true
          state.detailModal = flag.message
        }
      }
      // 附加险条款展示
      emit('getAdditionalCoverageData',riskItem, checkedExit.value.includes(riskItem['riskName']))
      PremCalculator.factorChange('riskStatusChange', null, (action, flag, value, res, param) => {

        if (res && res.code === 0){
          state.isIdentical = true
          state.detailModal = res.message
        }
        let tk = newPremiumInTK(param)
        if (tk) {
          value = tk
        }
        emit(action, flag, value,res)
        changeCalStatus(flag)
        if (param && res) {
          changeAmntValue(res, param)
        }
      },'',riskItem)
    }

    const editRenewinsurance =(valObject,type,status) =>{
      // 附加险是否需要申请续保校验
      let renewinsuranceList = []
      let flag = false
      let obj = {
        value: type === 'value'? valObject.value : '',
        name: type === 'value'? valObject.name : ''
      }
      let selectList = state.riskFormData.riskList.filter(item =>item.riskId !== valObject.riskId && item.isSelected && item.riskSign !== '1')
      if(type === 'value' && valObject.factorType === 'renewinsurance'){
        flag = true
      }else if(type === 'risk' && status){
        const list = valObject.calculateFactor.filter(item => item.factorType === 'renewinsurance')
        if(list.length){
          PremCalculator.oneCollationFactor(valObject)
          const facObj = list[0].factorValueList.filter(fac => fac.defaultShow === 'Y')[0]
          obj.value = facObj.value
          obj.name = facObj.name
          flag = true
        }
      }
      if(flag && selectList.length){
        renewinsuranceList = new Array(selectList.length)
        selectList.forEach((sel,index) =>{
          if(sel.calculateFactor.length){
            renewinsuranceList[index] = sel.calculateFactor.filter(selElement =>selElement.factorType === 'renewinsurance' && selElement.factorValue !== obj.value)[0]
          }
        })
        if(renewinsuranceList.length && renewinsuranceList.every(item => item)){
          state.isIdentical = true
          state.detailModal = '所有附加险是否申请续保的值需保持一致，将做同步修改。'
          renewinsuranceList.forEach(risk=>{
            console.log(risk)
            if(risk){
              risk.factorValue = obj.value
              risk.factorValueName = obj.name
            }
          })
        }
      }
    }


    const state = reactive({
      flag: -1,
      calculating: false,
      amount: 0,
      productInfo: props.value,
      firstMainRiskInfo: {},
      firstMainRiskInfoCopy: {},
      // TODO 多主险展示暂未实现
      moreMainRiskList: [],
      riskFormData: {
        productId: '',
        riskList: []
      },
      optionalDutys: [],
      dutyVisible: false,
      dutyDetail: {
        dutyName: '',
        dutyIntroduce: ''
      },
      showCheck: true,
      guaranteeShow: false,
      isIdentical: false,
      detailModal: '',
      numTimes: 0,
      optionalDutyList: []
    })
    const previewModal = {
      'overflow': 'auto',
      'border-radius': '20px'
    }

    onBeforeMount(() => {
      const riskState = PremCalculator.createRiskInfo(state.productInfo)
      state.firstMainRiskInfo = riskState.firstMainRiskInfo
      state.firstMainRiskInfoCopy = JSON.parse(JSON.stringify(state.firstMainRiskInfo))
      state.moreMainRiskList = riskState.moreMainRiskList
      state.riskFormData = riskState.riskFormData
      state.riskFormData.riskList.forEach(item=>{
        if(item.isSelected){
          checkedExit.value.push(item.riskName)
        }
        activeAddition[item.riskId] = 0
      })
      state.optionalDutys = riskState.optionalDutys
      if (isJDALAPIProducts(state.productInfo['productId'])) {
        state.showCheck = false
      } else {
        state.showCheck = true
      }
    })
    onMounted(() => {
      PremCalculator.factorChange({
        riskId: state.firstMainRiskInfo.riskId
      }, null, (action, flag, value) => {
        emit(action, flag, value)
        changeCalStatus(flag)
      })
    })
    const changeAmntValue = (premlist, data) => {
      // todo 还差获取主险的保费和缴费期间
      // let key = getAmntElementRef(item.riskId,item.riskCode)
      // let val = isNeedSpecailCal(item.riskCode)
      let obj = getAmntElementRefAndSpecailCal(premlist, data)
      if (obj) {
        insureEl[obj.key].changeInputvalue(obj.val)
      }
    }
    const changePlanIndex = (index, id) => {
      console.debug('changePlanIndex index, id', index, id)
      if (id) {
        insureEl[`${id}Plan`].changeRadioValue(index)
      } else {
        insureEl['Plan'].changeRadioValue(index)
      }

      // 去控制主险元素的元素的radio切换
    }
    const handelActive = (id, index) => {
      changePlanIndex(index, id)
      activeAddition[id] = index
    }

    const changeDateRange = (arr, type) => {
      insureEl[type].changeDateRange(arr)
    }

    const optionalDutyChange = (dutyItem) => {
      if(window.checkDHDutySelect && dutyItem.isSelected){
        const riskList = state.riskFormData.riskList.filter(risk => risk.riskId === dutyItem.riskId)[0].calculateFactor
        const flag = window.checkDHDutySelect(dutyItem, riskList)
        if(flag && !flag.type){
          dutyItem.isSelected = false
          state.isIdentical = true
          state.detailModal = flag.message
          return
        }
      }
      if(window.checkGFDutySelect){
        let dutyCodeObj = state.riskFormData.riskList.filter(risk => risk.riskId === dutyItem.riskId)[0]
        window.checkGFDutySelect(dutyCodeObj)
        if(dutyItem.dutyCode === '20900000109'){
          insureEl['Amnt'].editAmntDataList('Amnt')
        }
      }
      PremCalculator.optionalDutyChange(dutyItem, (action, flag, value, res) => {
        if (res && res.code === 0){
          state.isIdentical = true
          state.detailModal = res.message
        }
        emit(action, flag, value,res)
        changeCalStatus(flag)
        saveOptionalDuty(dutyItem)
      })
    }
    // 将选中的可选责任保存一下
    const saveOptionalDuty = (dutyItem) =>{
      const index = state.optionalDutyList.findIndex(item => item.id === dutyItem.id)
      if(dutyItem.isSelected && index === -1){
        state.optionalDutyList.push(dutyItem)
      }else if(!dutyItem.isSelected && index !== -1){
        state.optionalDutyList.splice(index, 1)
      }
      store.dispatch('insured/saveOptionalDuty', state.optionalDutyList)
    }

    const showDutyDetail = (duty) => {
      state.dutyDetail = duty
      state.dutyVisible = true
    }
    const onDutyDetailClose = () => {
      state.dutyVisible = false
      state.dutyDetail = {
        dutyName: '',
        dutyIntroduce: ''
      }
    }
    const factorChange = (valObject, supRiskCode) => {
      console.log('factorChange-------', valObject, supRiskCode)
      console.log('factorChange-------', valObject, supRiskCode)
      let bool = specialRiskAMN(valObject, state.riskFormData.riskList)
      if (!bool) {
        state.isIdentical = true
        state.detailModal = '输入的保额不能少于100000，且必须为1000的整数倍！'
        return
      }
      // 加上···做一层校验防护！
      // payTypeComparePayYear(valObject,state.riskFormData.riskList)
      editRenewinsurance(valObject,'value')
      if(window.checkDHFactorSelect && valObject.value && !state.isIdentical){
        const riskList = state.riskFormData.riskList.filter(item => item.riskSign === '1')[0].calculateFactor
        const dutyList = state.riskFormData.riskList.filter(item => item.riskSign === '1')[0].dutyList
        let flag = window.checkDHFactorSelect(riskList, dutyList)
        if(flag && !flag.type){
          state.isIdentical = true
          state.detailModal = flag.message
        }
      }
      // 瑞泰年龄,缴费年期, 缴费方式
      // if(window.entranceMethod && valObject.value){
      if(JSON.parse(store.getters['insured/getProductDetailData']).productInfo.supplierCode === '2053' && valObject.value){
        if (valObject.factorType === 'TextAge' ||valObject.factorType === 'PayType') {
          const calculateFactor = state.riskFormData.riskList.filter(item => item.riskSign === '1')[0].calculateFactor
          // window.entranceMethod(calculateFactor, state.firstMainRiskInfo)
          state.firstMainRiskInfo.calculateFactor = entranceMethod(calculateFactor, state.firstMainRiskInfoCopy, state.firstMainRiskInfo).calculateFactor
        }

      }
      if(window.checkGFDutySelect){
        insureEl['Amnt'].editAmntDataList('Amnt')
      }
      if (valObject.factorType === 'Plan') {
        activeAddition[valObject.riskId] = valObject.index
      }
      valObject.getDefault = 1
      state.numTimes++
      let total = state.numTimes
      console.log('factorChange1-------', valObject)
      PremCalculator.factorChange(valObject, supRiskCode, (action, flag, value, res, param) => {
        console.log('factorChange2-------', valObject)
        if(total===state.numTimes){
          if (res && res.code === 0){
            state.isIdentical = true
            state.detailModal = res.message
          }
        }
        let tk = newPremiumInTK(param)
        if (tk) {
          value = tk
        }else if (param && res) {
          changeAmntValue(res, param)
        }
        // console.log('factorChange3-------',  flag)
        // console.log('factorChange4-------', action)
        // console.log('factorChange5-------',  value)
        // console.log('factorChange6-------',  res)
        emit(action, flag, value,res)
        changeCalStatus(flag)
      })
    }
    const changeCalStatus  = (flag) => {
      if (flag === 0) {
        state.calculating = true
      } else if (flag === 1) {
        spinning.value = false
        state.calculating = false
      } else {
        spinning.value = false
        state.calculating = false
      }
    }
    const handelOk = () => {
      state.dutyVisible = false
    }
    const setElementType = (fTpe, oType) => {
      if (fTpe === 'Occupation' || fTpe === 'City' && oType !== fTpe) {
        return fTpe
      }
      return oType
    }
    const getSpecialItem = (type, n, f) => {
      if (n === 0) {
        if (type === 'AreaType' || type === 'City') {
          return 'city'
        }
      }
      if (n === 1) {
        if (type === 'occupation' || type === 'city') {
          return 'select'
        }
        if (f && f.toLowerCase() === 'occup') {
          return 'select'
        }
      }
      return type
    }

    watch(()=> state.riskFormData.riskList, ()=>{
      const isSelectedList = state.riskFormData.riskList.filter(item => item.isSelected)
      const riskList = state.riskFormData.riskList.filter(risk => risk.dutyList && risk.dutyList.length > 0)
      if(riskList.length){
        let dutyList = []
        riskList.forEach(item =>{
          dutyList = item.dutyList.filter(duty =>duty.isShow && duty.isSelected && duty.premiumAmount !== 0)
        })
        state.guaranteeShow = !!(isSelectedList.length && dutyList.length)
      }else{
        state.guaranteeShow = false
      }
    })
    const insuranceErrorHandleOk = () =>{
      state.isIdentical = false
    }
    const filterRiskList = (Risklist, type, flag) => {
      if (flag === 1) {
        return Risklist.filter(item => {
          return Number(item.riskSign) === type && Number(item.doubleMainRiskFlag) === 1 && Number(item.riskSubFlag) === 1
        }) ?? []
      } else {
        return Risklist.filter(item => {
          return Number(item.riskSign) === type
        }) ?? []
      }

    }
    expose({
      factorChange,
      changePlanIndex,
      changeDateRange
    })
    return {
      filterRiskList,
      activeNamesZ,
      insuranceErrorHandleOk,
      insureEl,
      handelOk,
      ...toRefs(state),
      factorChange,
      showDutyDetail,
      onDutyDetailClose,
      previewModal,
      changePlanIndex,
      changeDateRange,
      setElementRef,
      activeNames,
      checkedExit,
      initButton,
      spinning,
      handelActive,
      activeAddition,
      activeIcon,
      inactiveIcon,
      optionalDutyChange,
      changeAmntValue,
      setElementType,
      getSpecialItem
    }
  }
})
</script>
