<style scoped>
.element_wrap {
  margin: .5rem 0 0 ;
  color: #222;
  border-bottom: 1px solid #f3f5f5;
  display: flex;
  justify-content: space-between;
}

.label-top {
  display: block;
}

.label-left, .label-right {
  display: inline-block;
  width: 32%;
  /*line-height: 1.7rem;*/

}

.label-bottom {
  display: block;
}

.element-input {
  border: 0;
  outline: none;
  text-align: right;
  width: calc(67% - 1.5rem);
  padding-right: 10px;
  margin-bottom: 8px;
}

.element-suffix {
  /* padding-left: .5rem; */
  margin-left: -25px !important;
}

.element-radio, .element-switch {
  text-align: right;
  margin-bottom: 7px;
}

.element-radio-l {
  width: 100%;
}

:deep(.van-cascader__options) {
  height: 16rem;
}

:deep(.van-tabs__content) ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.ant-radio-button-wrapper {
  padding: 0 1rem;
  border: 1px solid #999;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  flex-direction: row-reverse !important;
}

:deep(.van-hairline--top-bottom) {
  width: 100%;
}

:deep(.ant-radio-button-wrapper:not(:first-child)::before) {
  height: 0 !important;
  background-color: #fff;
  top: 11px;
  left: -3px;
}

:deep(.van-collapse-item__content) {
  padding: 0;
  margin: 10px 0px;
}

.van-collapse {
  width: 100%;

}

:deep(.van-cell) {
  padding: 2px 5px 5px 0 !important;
}

/*详情页城市职业搜索*/
:deep(.van-cascader__header) {
  display: block;
  height: 30px;
}

:deep(.van-cascader__title .cascader_calss) {
  display: flex;
  justify-content: space-between !important;
  margin-top: -10px;
}

:deep(.ant-input-number:hover) {
  border-color: #fff !important;
}

.inputCustom {
  border-style: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid #333333;
}

.van-cascader {
  margin: -15px;
}
:deep(.van-cell) {
  padding-right: 1px !important;
}
:deep(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover){
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
:deep(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) { 
  box-shadow: none;
}
</style>

<template>
  <div class="element_wrap">
    <label
      v-if="label.length > 0 && (labelPosition === 'top' || labelPosition === 'left') && elementType !=='radioSelection'"
      :class="'label-' + labelPosition">{{ label }}</label>
    <input v-show="elementType !== 'radio' && elementType !== 'switch' && elementType !== 'radioSelection'"
           v-model="inputVal" :name="elementKey"
           :placeholder="cascaderTitle"
           :class="'element-input ' + elementType + ' element-' + labelPosition"
           :readonly="isEdit !== 'Y'" @change="onInputChange" @click="showChoice2">
    <a-radio-group ref="radioPl" v-if="elementType === 'radio'" v-model:value="insideVal.value"
                   :name="elementKey" button-style="solid" @change="radioChange"
                   :class="label.length > 0 ? 'element-radio' : 'element-radio-l'"
                   size="small" :disabled="disabled">
      <template v-for="(item,idx) in dataList" :key="'element_radio_' + idx">
        <!-- 1 {{ insideVal.value }}--{{ JSON.stringify(dataList) }} -->
        <a-radio-button shape="circle" :value="item.value"
                        style="border-radius: 20px;margin: 0px 8px 0 3px;height: 1.6rem;line-height: 1.5rem;">
          {{ item.name }}
        </a-radio-button>
      </template>
    </a-radio-group>
    <a-switch v-else-if="elementType === 'switch'" v-model:checked="insideVal.value"
              :checkedValue="dataList[0]?dataList[0].value:'Y'" :unCheckedValue="dataList[1]?dataList[1].value:'N'"
              @change="switchChange" class="element-switch" :disabled="disabled">
      <template #checkedChildren>
        <check-outlined/>
      </template>
    </a-switch>
    <span
      v-if="elementType === 'date' || elementType === 'select'&& (elementKey.toLowerCase() !== 'occupation') && (elementKey.toLowerCase() !== 'city')&& (elementKey.toLowerCase() !== 'occup')
       || elementType === 'select' && elementKey.toLowerCase() === 'city' ||elementKey.toLowerCase() === 'city'||
       elementType === 'select' && elementKey.toLowerCase() === 'occup' ||elementKey.toLowerCase() === 'occup'||
        elementType === 'select' && elementKey.toLowerCase() === 'occupation'||elementKey.toLowerCase() === 'occupation'"
      class="element-suffix" @click="showChoice" v-show="!disabled">
      <right-outlined/>
    </span>
    <label v-if="label.length > 0 && (labelPosition === 'right' || labelPosition === 'bottom')"
           :class="'label-' + labelPosition">{{ label }}</label>
    <!-- 这个是类似社保的 折叠 + 单选 -->
    <van-collapse :border="false" v-model="activeKey" v-if="elementType === 'radioSelection'">
      <van-collapse-item name="1" :border="false">
        <template #title>
          <div style="display: flex;justify-content: space-between; color: #222;">
            {{ label }} <span>{{ insideVal.name }}</span>
          </div>
        </template>
        <a-radio-group ref="radioPl" v-model:value="insideVal.value"
                       :name="elementKey" button-style="solid" @change="radioChange"
                       class="element-radio-l"
                       size="small" :disabled="disabled">
          <template v-for="(item,idx) in dataListValue" :key="'element_radio_' + idx">
            <a-radio-button v-if="item.typeValue === 'normal' " shape="circle" :value="item.value"
                            style="border-radius: 20px;margin: 8px 8px 8px 3px;height: 1.6rem;line-height: 1.5rem;">
              {{ item.name }}
            </a-radio-button>
            <a-input-number class="inputCustom" v-show="item.typeValue==='customCoverageFlag'" :value=item.value
                             @blur="inputBlur" @keyup="inputBlur"
                            placeholder="自定义金额"/>
            <a-button v-show="item.typeValue==='customCoverageFlag'" type="text">元</a-button>

          </template>
        </a-radio-group>
      </van-collapse-item>
    </van-collapse>
    <a-drawer
      placement="bottom"
      :closable="false"
      :visible="pickerVisible"
      @close="onPickerClose"
      height="25rem"
    >
      <van-picker
        v-if="elementKey.toLowerCase()  !== 'city' && elementKey.toLowerCase() !== 'occupation' &&  elementKey.toLowerCase() !== 'occup' &&(elementType === 'select' || elementType.toLowerCase() === 'occupation' )  "
        :title="pickerTitle"
        :columns="selectDataList"
        :columns-field-names="pickerField"
        @confirm="onPickerConfirm"
        @cancel="onPickerClose"
      />
      <!-- :title="pickerTitle" -->
      <van-datetime-picker v-else-if="elementType === 'date'"
                           type="date"
                           :formatter="formatter"
                           v-model="pickerDate"
                           :min-date="minDate"
                           :max-date="maxDate"
                           @confirm="onDatePickerConfirm"
                           @cancel="onPickerClose"
      />
      <van-cascader
        v-if="(elementKey.toLowerCase()  === 'city' || elementKey.toLowerCase()  === 'occupation'|| elementKey.toLowerCase() === 'occup' )&& elementType === 'select' "
        v-model="cascaderValue"
        :options="cascaderOptions"
        :field-names="cascaderFieldNames"
        @finish="onCascaderConfirm"
        :closeable="false"
        @change="onCascaderChange"
      >
        <template #title>
          <div class="cascader_calss">
            <div @click="onPickerClose" style="padding-top: 5px;">取消</div>
            <div v-if="elementKey.toLowerCase()  === 'occupation'|| elementKey.toLowerCase() === 'occup'"
                 @click="handelSelectOccupation">
              <van-icon style="margin: 0.1rem 0.2rem 0;" name="search" size="1.3rem" color="#d2d2d2"/>
              <span>职业查询 </span>
              <!--              <span>{{ cascaderTitle }}</span>-->
            </div>
          </div>
        </template>
      </van-cascader>
    </a-drawer>
    <occupation-select ref="occupationRef" @handelClickOk="handelClickOk"></occupation-select>
    <a-modal style="width: 80%;" centered :closable="false" v-model:visible="isIdentical"
             @ok="insuranceErrorHandleOk">
      <p style="font-size: 1.01rem;color: #333333;text-align: center;margin: 0.45rem 0.25rem;">{{ detailModal }}</p>
      <template #footer>
        <a-button type="link"
                  style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                  @click="insuranceErrorHandleOk">确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount, reactive, ref, toRefs, watch} from 'vue'
import {message} from 'ant-design-vue'
import {Cascader, Collapse, CollapseItem, DatetimePicker, Icon, Picker} from 'vant'
import 'vant/lib/index.css'
import {request} from '../../utils/request'
import {getExtendUri} from '../../utils/request_extend'
import {
  AddDays,
  getDaysBetweenDates,
  getDefaultBirthdateByDays,
  getNewDateParam, newBornInCalAgeFun,
  showInsurancedateDataMin
} from '../../utils/utlis'
import store from '../../store'
import OccupationSelect from '@/pages/InsuranceConfiguration/element/views/OccupationSelect.vue'
import {getDateCheck} from '../../utils/details_verification'

export default defineComponent({
  components: {
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Cascader.name]: Cascader,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Icon.name]: Icon,
    OccupationSelect
  },
  props: {
    factorId: {
      type: String,
      required: false,
      default: () => ''
    },
    riskFormData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    label: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    labelPosition: {
      type: String,
      required: false,
      default: () => {
        return 'left'
      }
    },
    elementKey: {
      type: String,
      required: true
    },
    elementType: {
      // input, date, select, switch, radio, city, occupation
      type: String,
      required: false,
      default: () => {
        return 'input'
      }
    },
    dataType: {
      // String, Date, Number
      type: String,
      required: false,
      default: () => {
        return 'String'
      }
    },
    dataList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    modelValue: {
      type: [String, Number],
      default: () => ''
    },
    modelName: {
      type: String,
      default: () => ''
    },
    riskId: {
      type: String,
      required: true
    },
    riskSingeId: {
      type: String,
    },
    supCode: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPremCalculating: {
      type: Boolean,
      default: false
    },
    isPremiumCal: {
      type: String,
      default: () => 'N'
    },
    isEdit: {
      type: String,
      default: () => 'N'
    },
    isRiskSign:{
      type: String,
      default: () => ''
    }
  },
  emits: ['factorChange', 'update:modelValue', 'update:modelName'],
  setup(props, {emit}) {
    // const isChangeDate = ref(false)
    let _f = '-'
    const curDate = new Date()
    const radioPl = ref()
    const inputValue = ref()
    const pickerVisible = ref(false)

    //职业城市搜索
    const occupationRef = ref()
    const handelSelectOccupation = () => {
      occupationRef.value.showDrawer()
    }
    // 当职业查询中的弹窗点击确定的时候
    const handelClickOk = (data) => {
      if (props.elementKey.toLowerCase() === 'occup') {
        let str = `${data.occupationLevel}级`
        state.inputVal = str
        insideVal.value = data.occupationLevel
        insideVal.name = str
      } else {
        let obj = collationOccupation(data, props.dataList)
        state.inputVal = data.name
        insideVal.value = obj.value
        insideVal.name = obj.name
      }
      pickerVisible.value = false
    }

    const getCustomList = (paramsList) => {
      const listFilter = paramsList.find(item => item.value === 'customCoverageFlag')
      if (listFilter) {
        const list = paramsList.map(item => {
          if (item.value === 'customCoverageFlag') {
            return {
              ...item,
              value: '',
              typeValue: 'customCoverageFlag'
            }
          } else {
            return {
              ...item,
              typeValue: 'normal'
            }
          }


        })
        return list
      } else {
        return paramsList.map(item => {
          return {
            ...item,
            typeValue: 'normal'
          }
        })
      }

    }
    const getMinAge = (list) => {
      if(!list || !list.length) return
      const minList = list.filter(item => (item.value+ '').includes('D'))
      if(minList.length>0) {
        const str = minList[0].value.slice(0, (minList[0].value+'').indexOf('D'))
        return {
          minAge: str,
          mixType: 'D'
        }
      }else {
        const ageData =  list.reduce((x, y) => {
          let a = x.value + ''
          let b = y.value + ''
          a = a.indexOf(_f) > 0 ? Number(a.split(_f)[0]) : Number(a)
          b = b.indexOf(_f) > 0 ? Number(b.split(_f)[0]) : Number(b)
          return a < b ? x : y
        }).value
        return {
          minAge: ageData,
          mixType: 'Y'
        }
      }
    }
    const getMaxAge = (list) => {
      if(!list || !list.length) return
      const ageMaxList = list.filter(item => !(item.value+ '').includes('D'))
      if (ageMaxList.length > 0) {
        const ageData = ageMaxList.reduce((x, y) => {
          let a = x.value + ''
          let b = y.value + ''
          a = a.indexOf(_f) > 0 ? Number(a.split(_f)[1]) : Number(a)
          b = b.indexOf(_f) > 0 ? Number(b.split(_f)[1]) : Number(b)
          return a > b ? x : y
        }).value
        return {
          maxAge: ageData,
          maxType: 'Y'
        }
      }else{
        // 包括D 也包括有范围的且包含D
        let a = list[list.length-1].value
        let ageData = ''
        let type = 'D'
        if(a.indexOf(_f) > 0){
          const arr = a.split(_f)
          type = arr[1].indexOf('D') > 0 ? 'D' : 'Y'
          ageData = Number(arr[1].replace(type, ''))
        }else{
          ageData = Number(a.replace('D', ''))
        }
        return {
          maxAge: ageData,
          maxType: type
        }

      }
    }

    const getFullYear = (year, age) => {
      let a = (age + '').replaceAll('Y', '')
      if (a.indexOf('D') > 0) {
        a = '0'
      }
      a = a.indexOf(_f) > 0 ? Number(a.split(_f)[0]) : Number(a)
      return year - a
    }
    const state = reactive({
      dataConfirmFlag: false,
      activeKey: [],
      lastSelectedDate: '', // 用于实现选择后点击取消回到之前的值
      inputVal: props.modelName,
      pickerTitle: props.label,
      pickerField: {text: 'name'},
      minDate: new Date(1900,0,1),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
      pickerDate: (new Date(getFullYear(curDate.getFullYear(), props.modelValue), curDate.getMonth(), curDate.getDate()) || new Date()),
      cascaderTitle: '请选择' + props.label,
      cascaderOptions: [],
      riskFormDataN: [],
      cascaderFieldNames: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
      selectDataList : props.dataList,
      cascaderValue: props.modelValue,
      dataListValue: getCustomList(props.dataList),
      isIdentical: false,
      detailModal: ''
    })
    const getInsureDate = (list, factorType) => {
      if ((list === undefined || !list.length) && factorType !== 'Insurancedate' && factorType !== 'TextAge') return
      const min = getMinAge(list)
      const max = getMaxAge(list)
      let data = showInsurancedateDataMin(min.minAge, min.mixType, max.maxAge, max.maxType, 0)
      state.minDate = getNewDateParam(data.earliestDate)
        // new Date(new Date(data.earliestDate).getFullYear(), new Date(data.earliestDate).getMonth(), new Date(data.earliestDate).getDate())
      state.maxDate = getNewDateParam(data.latestDate)
        // new Date(new Date(data.latestDate).getFullYear(), new Date(data.latestDate).getMonth(), new Date(data.latestDate).getDate())

    }


    const formatter = (type, option) => {
      if (type === 'year') {
        option += '年'
      }
      if (type === 'month') {
        option += '月'
      }
      if (type === 'day') {
        option += '日'
      }
      return option
    }

    const dateFormat = (date) => {
      let year = date.getFullYear()
      let month = '0' + (date.getMonth() + 1)
      let day = '0' + date.getDate()
      if (month.length > 2) month = month.substring(1)
      if (day.length > 2) day = day.substring(1)
      // return getNewTimeForIOS(year + split + month + split + day)
      return (`${year}${_f}${month}${_f}${day}`)
    }

    const insideVal = reactive({
      riskId: props.riskId,
      factorType: props.elementKey,
      value: props.modelValue,
      name: props.modelName,
      elementType: props.elementType,
      count: 0,
      index: 0,
      textAgeValue: undefined
    })
    // 计算年龄函数
    // @马志江
    const calAge = (da) => {
      console.log('计算年龄------->', da)
      let strBirthday = dateFormat(da)
      console.log('计算年龄------->', strBirthday)
      const strBirthdayArr = strBirthday.split(_f)
      const birthYear = Number(strBirthdayArr[0])
      const birthMonth = Number(strBirthdayArr[1])
      const birthDay = Number(strBirthdayArr[2])
      let d = new Date()
      if (store.getters['insured/getAGEDIFF']){
        d = newBornInCalAgeFun(undefined, store.getters['insured/getAGEDIFF'])
      }
      // 拿到当前时间的年
      const nowYear = d.getFullYear()
      // 拿到当前时间的月
      const nowMonth = d.getMonth() + 1
      let nowDay
      // 拿到当前时间的日 (三元是 单独对京东安联的儿童险)
      // todo 缓存取这个值，没有则默认为当天生效
      if (store.getters['insured/getTNUMBER']) {
        // nowDay = props.riskId === d.getDate() + Number(sessionStorage.getItem('TNUMBER'))
        nowDay = d.getDate() + store.getters['insured/getTNUMBER']
      } else {
        nowDay = d.getDate()
      }

      // 如果出生日期的年等于当前时间的年
      if (nowYear === birthYear) {
        return newAge(0,strBirthday,(store.getters['insured/getTNUMBER']||0))
      }
      const ageDiff = nowYear - birthYear  // 年之差 2024-1958 = 66
      if (ageDiff < 0) return -1
      if (nowMonth !== birthMonth) {
        const monthDiff = nowMonth - birthMonth
        return monthDiff < 0 ? ageDiff - 1 : ageDiff
      }
      const dayDiff = nowDay - birthDay
      return newAge((dayDiff < 0 ? ageDiff - 1 : ageDiff),strBirthday,(store.getters['insured/getTNUMBER']||0))
    }
    // 不满一年计算差
    const newAge = (num,born,t) => {
      if (num == 0) {
        // 根据生日计算天数
        let bornDate = getNewDateParam(born)
        let today = new Date()
        let newdate = today
        if (t) {
          today = AddDays(new Date(), t, t >= 0)
          newdate = new Date(today[0], today[1] - 1, today[2])
        }
        return getDaysBetweenDates(newdate, bornDate, props.elementKey, t) + 'D'
      } else {
        return num
      }
    }
    // let dataList = getPropDateList(props.factorId, 'TextAge')
    const findAge = (age) => {
      // if (!dataList) {
      let dataList = props.dataList
      // }
      console.debug('获取出生日期列表', dataList)
      // props.dataList = dataList
      const tmpAge = age + ''
      for (let i = 0; i < dataList.length; i++) {
        try {
          const ageStr = dataList[i].value + ''
          if (ageStr.indexOf('-') > 0) {
            if (ageStr.indexOf('-') !== -1 && tmpAge != '0') {
              let [min, max] = ageStr.replaceAll('Y', '').split('-')
              if (min.indexOf('D') !== -1 && max.indexOf('D') !== -1) {
                if (tmpAge.indexOf('D') !== -1) {
                  age = Number(tmpAge.replace('D', ''))
                  if (Number(min.replace('D', '')) <= age && age <= Number(max.replace('D', ''))) {
                    return ageStr
                  }
                }
              } else if (min.indexOf('D') !== -1 && max.indexOf('D') === -1) {
                if (tmpAge.indexOf('D') !== -1) {
                  age = Number(tmpAge.replace('D', ''))
                  if (Number(min.replace('D', '')) <= age) {
                    return ageStr
                  }
                } else {
                  age = Number(tmpAge)
                  if (Number(max.replace('D', '')) >= age) {
                    return ageStr
                  }
                }
              } else {
                if (Number(min) <= Number(tmpAge) && age <= Number(max)) {
                  return ageStr
                }
              }
            } else {
              // 原逻辑不想看了，直接放else
              if (tmpAge.indexOf('D') != -1) {
                age = '0'
              }
              let [min, max] = ageStr.replaceAll('Y', '').split('-')
              if (min.indexOf('D') > 0) {
                min = '0'
              }
              if (Number(min) <= age && age <= Number(max)) {
                return ageStr
              }
            }
          } else if (ageStr.indexOf('D') > 0 && tmpAge === '0') {
            return ageStr
          } else if (ageStr.indexOf('Y') > 0 && ageStr === tmpAge + 'Y') {
            return ageStr
          } else if (ageStr === tmpAge) {
            return ageStr
          }
        } catch (e) {
          console.error('findAge error', props.dataList[i], e)
        }
      }
      return tmpAge
    }


    watch(insideVal, (nVal, oVal) => {
      let arr = props.riskFormData
      if (arr) {
        let statusObj = {
          lock: true,
          message: ''
        }
        let obj = window.calibrationMethod_Detail ? window.calibrationMethod_Detail(arr, nVal, oVal) : statusObj
        if (!obj.lock) {
          message['error'](obj.message)
          return
        }
      }
      if (props.elementKey.toLowerCase() !== 'occupation' && props.elementKey.toLowerCase() !== 'occup' && props.elementKey !== 'TextAge') {
        state.inputVal = nVal.name
      }
      //todo 此方法会让出生日期变化，因为年龄不变导致出生日日期回显不变，所以加一层 props.elementKey !== 'TextAge'的判断
      if ((props.elementKey !== 'TextAge' && props.elementKey !== 'Insurancedate') && nVal.value === props.modelValue && nVal.name === props.modelName) {
        return
      }
      if ((props.elementKey === 'TextAge' || props.elementKey=== 'Insurancedate') && props.elementType === 'date') {
          state.inputVal = props.modelName === '同主险' ? '同主险' : dateFormat(state.pickerDate)
        let age = findAge(calAge(state.pickerDate))
        emit('update:modelValue', age)
        emit('update:modelName', getNewName(age))
      } else {
        emit('update:modelValue', nVal.value)
        emit('update:modelName', nVal.name)
      }

      console.debug('update:modelValue-------factorChange', nVal, oVal)
      emit('factorChange', insideVal)
      // }
    }, {deep: true})
    /**
     * 主动修改radio的值
     * @param index
     */
    const changeRadioValue = (index) => {
      console.debug('changeRadioValue')
      let val = props.dataList[index].value
      insideVal.value = val
      insideVal.name = props.dataList[index].name
      insideVal.index = index
    }

    const changeInputvalue = (val) => {
      console.debug('changeInputvalue', val)
      insideVal.value = val
      insideVal.name = `${val}元`
    }
    /**
     * 监听 modelValue
     */
    watch(() => props.modelValue, () => {
      if ((props.elementKey === 'TextAge' || props.elementKey === 'Insurancedate') && props.elementType === 'date') {
        console.debug('日期-props.modelValue', props.modelValue)

        // state.pickerDate = new Date(getFullYear(curDate.getFullYear(), props.modelValue), curDate.getMonth(), curDate.getDate()) || new Date()
        if (!state.dataConfirmFlag) {
          state.pickerDate = getDefaultBirthdateByDays(props.modelValue, (store.getters['insured/getTNUMBER'] || 0))
        }
        state.inputVal = dateFormat(state.pickerDate)
        insideVal.textAgeValue = calAge(state.pickerDate)
      } else if (props.elementType === 'select' && (props.elementKey.toLowerCase() === 'city' || props.elementKey.toLowerCase() === 'occupation' || props.elementKey.toLowerCase() === 'occup')) {
        // state.inputVal = props.modelName
      }
      insideVal.value = props.modelValue
      insideVal.name = props.modelName
      state.riskFormDataN = props.riskFormDatas
    }, {immediate: true, deep: true})

    const editAmntDataList = (type = '') =>{
      // 国富的
      if(window.editAmntDataList && (props.elementKey === 'Amnt' || type === 'Amnt')){
        const data = window.editAmntDataList(props.riskFormData, props.dataList)
        if(data){
          insideVal.value = data.value
          insideVal.name = data.name
          state.selectDataList = data.selectDataList
        }
      }
    }

    watch(() => props.dataList, (newVal, oldVal) => {
      if (newVal && oldVal && newVal !== oldVal && newVal.length > 0) {
        let needReset = true
        newVal.forEach(item => {
          if (item.value === insideVal.value) {
            needReset = false
          }
          if (item.value !== insideVal.value && item.name === insideVal.name) {
            needReset = false
            insideVal.value = item.value
          }
        })
        if (needReset) {
          insideVal.value = newVal[0].value
          insideVal.name = newVal[0].name
        }
        state.dataListValue = getCustomList(newVal)
      }
      editAmntDataList()
    }, {immediate: true})



    const setDefaultDate = (d) => {
      if (props.elementType === 'date') {
        insideVal.value = dateFormat(d)
        insideVal.name = insideVal.value
      }
    }

    onBeforeMount(() => {
      queryCascaderOption('0', state.cascaderOptions)
      getInsureDate(props.dataList, props.elementKey)
    })
    const onInputChange = () => {
      if (props.elementType === 'input') {
        insideVal.value = state.inputVal
        insideVal.name = state.inputVal
      }
    }
    const radioChange = (event) => {
      console.debug('radio-change', props.dataList, event.target, insideVal.value)
      if (insideVal.value !== 'customCoverageFlag') {
        inputValue.value = false
        insideVal.name = props.dataList.filter(item => item.value === insideVal.value)[0]['name']
        state.dataListValue = state.dataListValue.map(item => {
          if (item.typeValue === 'customCoverageFlag') {
            return {
              ...item,
              value: ''
            }
          } else {
            return {
              ...item
            }
          }
        })
      }
    }
    const inputBlur = (event) => {
      if (event.type === 'blur') {
        console.debug('inputBlur', event)
        insideVal.value = event.target.value
        insideVal.name = event.target.value + '元'
        state.dataListValue = state.dataListValue.map(item => {
          if (item.typeValue === 'customCoverageFlag') {
            return {
              ...item,
              value: event.target.value
            }
          } else {
            return {
              ...item
            }
          }
        })
      } else if (event.type === 'keyup' && event.keyCode === 13) {
        event.target.blur()
      } else if (event.type === 'keyup') {
        console.debug('inputBlur keyup', event.target.value)
      }else if(event.type === 'focus'){
        console.log(123131)
      }
    }
    const switchChange = () => {
      insideVal.name = props.dataList.filter(item => item.value === insideVal.value)[0]['name']
    }
    const showChoice = () => {
      pickerVisible.value = true
    }
    const showChoice2 = () => {
      if (!props.disabled && (props.elementType === 'date' ||
        props.elementType === 'select' ||
        props.elementType.toLowerCase() === 'city' ||
        props.elementType.toLowerCase() === 'occup' ||
        props.elementType.toLowerCase() === 'occupation')) {
        pickerVisible.value = true
      }
    }
    const doChoice = () => {
      insideVal.value = ''
      insideVal.name = ''
    }

    const onPickerClose = () => {
      if ((props.elementKey === 'TextAge' || props.elementKey=== 'Insurancedate') && props.elementType === 'date') {
        // todo 关闭时记录一下回到上一个值 , 用于实现取消功能

        insideVal.count = 0
        state.pickerDate = getNewDateParam(state.lastSelectedDate)
      }
      pickerVisible.value = false
    }
    const onPickerConfirm = (node, index) => {
      console.debug('onPickerConfirm-node', JSON.stringify(node))
      console.debug('onPickerConfirm-index', index)
      insideVal.value = node.value
      insideVal.name = node.name
      insideVal.index = index
      pickerVisible.value = false
    }
    const onDatePickerConfirm = (e) => {
      state.dataConfirmFlag = true
      console.debug('onDatePickerConfirm--0', dateFormat(state.pickerDate))
      console.debug(e)
      if ((props.elementKey === 'TextAge' || props.elementKey=== 'Insurancedate') && props.elementType === 'date') {
        const type = getDateCheck(findAge(calAge(state.pickerDate)))
        if(typeof type === 'string'){
          state.isIdentical = true
          state.detailModal = type
          return
        }
        state.lastSelectedDate = dateFormat(state.pickerDate)
        console.debug('onDatePickerConfirm--pickerDate,lastSelectedDate', dateFormat(state.pickerDate), state.lastSelectedDate)
        insideVal.value = findAge(calAge(state.pickerDate))
        insideVal.textAgeValue = calAge(state.pickerDate)
        insideVal.name = getNewName(insideVal.value)
        insideVal.count++
      } else if (props.elementType === 'date') {
        setDefaultDate(e)
      }
      pickerVisible.value = false
    }
    const insuranceErrorHandleOk = function () {
      state.isIdentical = false
      state.loadingShow = false
    }

    const isNumber = (obj) => {
      return !isNaN(Number(obj))
    }

    const collationOccupation = (val, list = []) => {
      const curValue = isNumber(val['occupationLevel']) ? Number(val['occupationLevel']) : val['occupationLevel']
      const defaultValue = {value: '', name: ''}
      for (let i = 0; i < list.length; i++) {
        let valStr = list[i]['value'] + ''
        if (list[i]['defaultShow'] === 'Y') {
          defaultValue.value = list[i]['value']
          defaultValue.name = list[i]['name']
        }
        if (valStr.indexOf(_f) > 0) {
          let [min, max] = valStr.split(_f)
          min = isNumber(min) ? Number(min) : min
          max = isNumber(max) ? Number(max) : max
          if (min <= curValue && curValue <= max) {
            return {
              value: list[i]['value'],
              name: val.name
            }
          }
        } else if (valStr === curValue + '') {
          return {
            value: list[i]['value'],
            name: val.name
          }
        }
      }
      return defaultValue
    }
    const onCascaderConfirm = ({selectedOptions}) => {
      console.debug('onCascaderConfirm', selectedOptions[2])
      if (props.elementKey.toLowerCase() === 'city') {
        insideVal.value = selectedOptions.map((option) => option.id).join(',')
        insideVal.name = selectedOptions.map((option) => option.name).join(',')
      } else if (props.elementKey.toLowerCase() === 'occupation') {
        let obj = selectedOptions[selectedOptions.length - 1]
        insideVal.value = obj.code
        insideVal.name = obj.name
        state.inputVal = obj.name
      } else if (props.elementKey.toLowerCase() === 'occup') {
        // let obj = collationOccupation(selectedOptions[selectedOptions.length - 1], props.dataList)
        let obj = selectedOptions[selectedOptions.length - 1]
        insideVal.value = obj.occupationLevel
        insideVal.name = `${obj.occupationLevel}级`
        state.inputVal = obj.name + `(${obj.occupationLevel}级)`
      }
      pickerVisible.value = false
    }
    const queryCascaderOption = (pId, node) => {
      if (props.elementKey.toLowerCase() === 'city') {
        request(getExtendUri('queryArea'), {
          data: {
            parentId: pId,
            productId: store.getters['insured/getProductId'] || '',
            agentId: JSON.parse(store.getters['insured/getProductDetailData'])?.agentInfo?.cardCode
          }
        }).then(res => {
          if (res.code === 1 && res.result) {
            if (pId === '0') {
              state.cascaderOptions = res.result
            } else {
              node['children'] = res.result
            }
          } else {
            message['error'](res.message)
          }
        })
      } else if (props.elementKey.toLowerCase() === 'occupation' || props.elementKey.toLowerCase() === 'occup') {
        let url = getExtendUri('queryOccupation')
        if (pId !== '0') {
          url = getExtendUri('querySubOccupation')
        }
        request(url, {
          data: {
            riskId: props.riskId,
            id: pId
          }
        }).then(res => {
          if (res.code === 1 && res.result) {
            if (pId === '0') {
              state.cascaderOptions = res.result
            } else {
              if(res.result.length > 0){
                node['children'] = res.result
              }
            }
          } else {
            message['error'](res.message)
          }
        })
      }
    }
    const onCascaderChange = ({selectedOptions, tabIndex, value}) => {
      console.debug(selectedOptions)
      if (props.elementKey.toLowerCase() === 'city' && tabIndex < 2 && selectedOptions[tabIndex]['children'] === undefined) {
        selectedOptions[tabIndex]['children'] = []
        queryCascaderOption(value, selectedOptions[tabIndex])
      } else if ((props.elementKey.toLowerCase() === 'occupation' || props.elementKey.toLowerCase() === 'occup') && selectedOptions[tabIndex]['childrenCount'] > 0) {
        selectedOptions[tabIndex]['children'] = []
        console.debug(selectedOptions[tabIndex]['childrenCount'])

        queryCascaderOption(value, selectedOptions[tabIndex])
      }
    }
    // const getNewdate = (timeStr) => {
    //   // let arr = timeStr.split(_f)
    //
    //   // let d = new Date(getNewTimeForIOS(timeStr))
    //   // if (arr.length === 3) {
    //   //   return new Date(d.getFullYear(),d.getMonth(),d.getDate())
    //   // }
    //   // return null
    //   // return dayjs(timeStr)
    //   return getNewDateParam(timeStr)
    // }
    const changeDateRange = (arr) => {
      console.debug('changeDateRange1', `${arr[0]},${arr[1]},${arr[2]}`)
      state.pickerDate = getNewDateParam(arr[2])
      state.minDate = getNewDateParam(arr[0])
      state.maxDate = getNewDateParam(arr[1])
      // todo 切换计划 将默认值附为临时值
      state.lastSelectedDate = arr[2]
      let age = findAge(calAge(state.pickerDate))
      state.inputVal = dateFormat(state.pickerDate)
      state.dataConfirmFlag = true
      insideVal.value = age
      insideVal.name = getNewName(insideVal.value)
      insideVal.count = 0
    }
    const getNewName = (value)=>{
      if (value.indexOf('Y') !== -1){
        return `${value.replace('Y','')}岁`
      }
      if (value.indexOf('D') !== -1){
        return `${value.replace('D','')}天`
      }
      return value + '岁'
    }
    watch(() => pickerVisible.value, () => {
      if (pickerVisible.value === true && props.elementKey === 'TextAge' && props.elementType === 'date') {
        // todo 打开 赋值一次临时值
        state.pickerDate = state.lastSelectedDate ? getNewDateParam(state.lastSelectedDate) : getNewDateParam(state.inputVal)
      }
    }, {
      immediate: true
    })
    return {
      formatter,
      ...toRefs(state),
      radioPl,
      pickerVisible,
      insideVal,
      radioChange,
      switchChange,
      showChoice,
      showChoice2,
      doChoice,
      onPickerClose,
      onPickerConfirm,
      onDatePickerConfirm,
      onCascaderConfirm,
      onCascaderChange,
      onInputChange,
      changeRadioValue,
      changeDateRange,
      inputValue,
      occupationRef,
      handelSelectOccupation,
      handelClickOk,
      inputBlur,
      changeInputvalue,
      insuranceErrorHandleOk,
      editAmntDataList,
    }
  }
})
</script>
