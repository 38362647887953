<template>
  <a-drawer
    title="快速录入"
    :width="'40%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      ref="formRef"
      autocomplete="off"
      :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }"
    >
      <a-row :gutter="10">
        <a-col :span="24">
          <a-form-item label="原始数据" name="yesNo">
            <a-radio-group v-model:value="formData.yesNo">
              <a-radio :value="false">覆盖</a-radio>
              <a-radio :value="true">拼接</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="起始值" name="startValue" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.startValue"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="结束值" name="endValue" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.endValue"/>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="步长" name="buchang" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.buchang"/>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="值数据格式" name="valuedata" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-row>
              <a-col :span="22">
                <a-input v-model:value="formData.valuedata"></a-input>
              </a-col>
              <a-col :span="2">
                <a-popover title="n为计算结果，语法格式：">
                  <template #content>
                    <p>10   -> {n}</p>
                    <p> 10年 -> {n}年</p>
                  </template>
                  <a-button>?</a-button>
                </a-popover>
              </a-col>
            </a-row>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="值显示格式" name="valuecontext"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-row>
              <a-col :span="22">
                <a-input v-model:value="formData.valuecontext"></a-input>
              </a-col>
              <a-col :span="2">
                <a-popover title="n为计算结果，语法格式：">
                  <template #content>
                    <p>10   -> {n}</p>
                    <p> 10年 -> {n}年</p>
                    <p>1000 -> {n/1000}千</p>
                  </template>
                  <a-button>?</a-button>
                </a-popover>
              </a-col>
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {message} from 'ant-design-vue'

export default defineComponent({
  setup(props, {emit, expose}) {
    const formData = reactive({
      yesNo: false,
      startValue: 0,
      endValue: 0,
      buchang: 1,
      valuecontext: '{n}',
      valuedata: '{n}'
    })
    const state = reactive({
      visible: false,
      baoeliebiao: []
    })
    const onSave = function () {
      if (formData.startValue === '' || formData.endValue === '' || formData.buchang === '' || formData.valuecontext === '') {
        message.warning('请录入必填项')
        return
      }
      state.baoeliebiao = []
      var data = new Date()
      for (let i = formData.startValue - 0; i <= formData.endValue - 0; i += formData.buchang - 0) {
        let obj = {}
        obj.premium = i
        obj.id = data.getTime() + Math.round(Math.random() * 10000)
        obj.id = (obj.id + '') + (i + '')
        obj.id = obj.id - 0
        let yy = formData.valuecontext
        let uu = yy.replace('n', i)
        // console.log(formData.valuecontext)
        let a = uu.indexOf('{')
        // console.log(a)
        let b = uu.indexOf('}')
        // console.log(b)
        let c = uu.substring(a + 1, b)
        // console.log(c)
        let plusResult = calculator(c)
        obj.calculatedValue = uu.replace(c, plusResult)
        obj.calculatedValue = obj.calculatedValue.replace('{', '')
        obj.calculatedValue = obj.calculatedValue.replace('}', '')
        let vv = formData.valuedata
        let gg = vv.replace('n', i)
        obj.calculated = gg
        obj.calculated = obj.calculated.replace('{', '')
        obj.calculated = obj.calculated.replace('}', '')
        state.baoeliebiao.push(obj)
      }
      console.log(state.baoeliebiao)
      emit('tableChange', state.baoeliebiao, formData.yesNo)
      onClose()
    }
    const calculator = function (str) {
      return new Function('return (' + str + ')')()
    }
    const formRef = ref()
    const onClose = function () {
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.visible = false
    }
    const show = function () {
      state.visible = true
    }
    expose({
      show
    })
    return {
      ...toRefs(state),
      formRef,
      show,
      onClose,
      formData,
      onSave,
      calculator
    }
  }
})
</script>

<style scoped>

</style>
