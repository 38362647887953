<style scoped>
.footer {
  background: white;
  height: 42px;
}
</style>

<template>
  <a-row class="footer">
    <a-col :span="18"></a-col>
    <a-col :span="6" ></a-col>
  </a-row>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
