<style scoped>
.ant-tabs {
  padding: 10px;
}

.ant-tabs :deep(.ant-tabs-nav) {
  margin: 0;
}

.ant-tabs :deep(.ant-tabs-tab) {
  border-radius: 6px 6px 0 0 !important;
}

.ant-tabs :deep(.ant-tabs-content-holder) {
  height: calc(100vh - 166px);
  background: white;
  padding: 10px;
}

.ant-tabs :deep(.ant-tabs-content) {
  height: 100%;
}

.ant-tabs-tabpane {
  text-align: left;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<template>
  <a-tabs v-model:activeKey="activeKey" hide-add type="editable-card" @edit="onEdit" @change="tabChange">
    <template v-for="item in frames" :key="item.key">
      <a-tab-pane :closable="item.closable" :ref="item.key">
        <template #tab>
          <span><component :is="iconComponent(item.icon)"/>{{ item.title }}</span>
        </template>
        <router-view :name="item.key"/>
      </a-tab-pane>
    </template>
  </a-tabs>
</template>

<script>
import {defineComponent, ref} from 'vue'
import {useRouter} from 'vue-router'
import * as antIcons from '@ant-design/icons-vue'
import store from '../../store'

export default defineComponent({
  setup(props, context) {
    const router = useRouter()
    const frames = ref([{
      title: '首页',
      key: 'frame_home',
      icon: 'HomeOutlined',
      path: '/home',
      closable: false
    }])
    const frame_keys = ref(['frame_home'])
    const activeKey = ref('frame_home')
    const changeFrame = function (item) {
      if (frame_keys.value.indexOf(item.key) >= 0) {
        activeKey.value = item.key
      } else {
        add({
          id: item.id,
          title: item.title,
          icon: item.icon,
          path: item.path
        })
      }
    }
    const add = (item) => {
      activeKey.value = `frame_${item.id}`
      frames.value.push({
        title: item.title,
        key: activeKey.value,
        icon: item.icon,
        path: item.path,
        closable: true
      })
      frame_keys.value.push(`frame_${item.id}`)
    }

    const remove = targetKey => {
      let lastIndex = 0
      frames.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })

      frames.value = frames.value.filter(pane => pane.key !== targetKey)
      frame_keys.value = frame_keys.value.filter(str => str !== targetKey)
      activeKey.value = frames.value[lastIndex].key
      tabChange(activeKey.value)
    }

    const onEdit = (targetKey, action) => {
      if (action === 'remove') {
        remove(targetKey)
      }
    }
    const tabChange = (activeKey) => {
      const key = activeKey.replace('frame_','')
      const menu = store.getters['user/getMenuMap'][key]
      router.push({ path: menu.uri })
      context.emit('callback', key)
    }
    const iconComponent = (name) => {
      return antIcons[name]
    }
    context.expose({
      changeFrame
    })
    return {
      frames,
      activeKey,
      onEdit,
      iconComponent,
      tabChange
    }
  }
})
</script>
