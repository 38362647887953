<style scoped>
.auth {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/texture.png');
  background-color: #2f90b9;

}

.login_title {
  color: #2f90b9;
  height: 60px;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.login_form {
  width: 320px;
  height: 420px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 60px 40px 40px 40px;
  background-image: url("../../assets/img/texture.png");
  background-color: #144a74;
  box-shadow: 10px 10px 20px #1c5581;
  transition: transform .4s ease-out, left .2s ease-out ;
  transform-origin: left;

}
.login_form.loading {
  transform: perspective(20px) rotateY(5deg);
  left: -100px;
}

.auth-form {
  padding-top: 60px;
}
.login_submit {
  border-radius: 50px;
  background: transparent;
  padding: 0 80px;
  border: 2px solid #2f90b9;
  color: #2f90b9;
  transition-duration: .2s;
  font-weight: 500;
  height: 36px;
  font-size: 1.2rem;
  line-height: 36px;
}
.login_submit:hover {
  color: #FFFFFF;
  background: #2f90b9;
}
.login_active {
  width: 320px;
  height: 36px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  color: #144a74;
  font-size: 1.5rem;
  text-align: left;
  transition: left .4s ease-out, opacity .3s ease-out ;
}
.login_active.loading {
  left: 240px;
  opacity: 1;
}
</style>

<template>
  <div class="auth">
    <div :class="{login_form:true, loading:loading}">
      <div class="login_title"><span>产品配置中心</span></div>
      <a-form class="auth-form"
              :model="formState"
              :wrapper-col="{ span: 24 }"
              autocomplete="off"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
      >
        <a-form-item
            name="userName"
            :rules="[{ required: true, message: '请输入用户名!' }]"
        >
          <a-input v-model:value="formState.userName" autocomplete="username">
            <template #prefix>
              <user-outlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
            name="password"
            :rules="[{ required: true, message: '请输入密码!' }]"
        >
          <a-input-password v-model:value="formState.password" autocomplete="current-password">
            <template #prefix>
              <lock-outlined  class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>
          <a-button type="primary" html-type="submit" class="login_submit" :loading="loading">登录</a-button>
      </a-form>
    </div>
    <div :class="{login_active:true, loading:loading}">
      <setting-outlined spin />&nbsp;&nbsp;{{ stateText }}
    </div>
  </div>
</template>
<script>
import {defineComponent, onBeforeMount, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import {login} from '../../utils/auth'
import {JSEncrypt} from 'jsencrypt'
import store from '../../store'
import {message} from 'ant-design-vue'
import {getRuleJsConfigMethod} from '../../utils/request_extend'

export default defineComponent({
  setup() {
    const router = useRouter()
    const formState = reactive({
      userName: '',
      password: ''
    })
    const state = reactive({
      loading: false,
      stateText: '登录验证中...'
    })
    const onFinish = () => {
      state.stateText = '登录验证中...'
      state.loading = true
      const data = {
        userName: JSON.parse(JSON.stringify(formState.userName)),
        password: setEncrypt(JSON.parse(JSON.stringify(formState.password)))
      }
      // login('/login', {data}).then(res => {
      login('/bee-system-service/system/login', {data}).then(res => {
        if (res.code === 1) {
          state.stateText = '登录成功！'
          setTimeout(() => state.loading = false, 1000)
          router.getRoutes().filter(r => r.name === 'Frame')[0].components = store.getters['user/getMenuComponents']
          getRuleJsConfigMethod()
          let p = router.currentRoute.value.redirectedFrom ? router.currentRoute.value.redirectedFrom.path : '/'
          setTimeout(() => {
            state.loading = false
            if (p !== '/') {
              router.push(router.currentRoute.value.redirectedFrom)
            } else {
              router.push({path: '/home'})
            }
          }, 500)
        } else {
          state.stateText = '登录失败！～'
          message.error(res.message)
          setTimeout(() => state.loading = false, 1000)
        }
      }).catch(res => {
        if(res.status == '404') { router.push({path: '/error'})}
        state.stateText = '登录失败！～'
        message.error('服务异常:' + res.status)
        setTimeout(() => state.loading = false, 1000)
      })
    }
    // RSA加密
    const setEncrypt = (msg)  => {
      // 公钥
      const key = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCKbZqPP8xy3+dDmG2o10HHx02cilY6m0q79yOa75HN+Vj+DKGkvnneHWiRR9JxNgFJY7ncUkrlQuExXxbxqK66QGwwfLUvnOtf1qPrx/rVXdKOA9dPDfGFQJ0NgH7QsLj1WnHaB5zkPle5SFLlqKIN8Sdo0xaQ1kVHSKGTUkkT8wIDAQAB'
      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(key)
      return jsencrypt.encrypt(msg)
    }
    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo)
    }

    // (*^▽^*)
    onBeforeMount(() => {
      window.localStorage.clear()
    })

    return {
      ...toRefs(state),
      formState,
      onFinish,
      onFinishFailed,

    }
  }

})
</script>
