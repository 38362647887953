<!--
操作指南
引用组件 =>  import uploadFileNew from '../../../components/extended/uploadFileNew'
           import {defineComponent} from 'vue'
           components: {uploadFileNew}
           <upload-file-new filetype="img" :showImg="showImg" listType="picture" @fileChange="fileChange"></upload-file-new>
组件参数 =>  filetype:上传的类型 img 、pgf、xls、amr后续可新增
           showImg: 回显的数据 => 数组对象[{url:''}]
           fileChange: 接收上传成功后返回的数据
           listType:展示样式   picture-card 、picture
           num:上传1张，2张.......
-->
<template>
  <div>
    <a-upload
      v-model:file-list="fileList"
      name="file"
      :max-count="num"
      @remove="handelRemove"
      @change="handleChange"
      :disabled="disabled"
      :showUploadList="showUploadList"
      @preview="handlePreview"
      :customRequest="uploadFile"
      :accept="fileState.filetypeL"
    >
      <a-button type="primary">
       上传
      </a-button>
    </a-upload>
    <p>{{ enclosure }}</p>
    <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
    <!-- 视频 -->
    <a-modal :visible="videoPreviewVisible" :footer="null" @cancel="videoCancel">
      <video width="100%" height="600px;" controls="controls" id="video">
        您的浏览器不支持播放该视频！
      </video>
    </a-modal>
  </div>
</template>

<script>
  import {request} from '../../utils/request.js'
  import {defineComponent, onBeforeUpdate, ref, reactive, toRefs, onBeforeMount} from 'vue'
  // import { message } from 'ant-design-vue'
  //import {PlusOutlined, UploadOutlined} from '@ant-design/icons-vue'
  import {uploadObject} from '../../utils/upload'
  import {addPropToItem} from '../../utils/ArrayUtils'

  // let COS = require('cos-js-sdk-v5')

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
        reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  export default defineComponent({
    components: {
    // PlusOutlined,
    //  UploadOutlined
    },
    props: {
      showUploadList: {
        type: Boolean,
        default: true
      },
      filetype: {
        type: String,
        default() {
          return ''
        }
      },
      showImg: {
        type: Array,
        required: false,
        default() {
          return []
        }
      },
      listType: {
        type: String,
        default() {
          return 'picture-card'
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default() {
          return false
        }
      },
      num: {
        type: [Number, String],
        required: true,
        default() {
          return 1
        }
      },
      enclosureName: {
        type: String,
        default: () => ''
      },
      isSpecial:{
        type: Boolean,
        default: () => false
      }
    },
    emits: ['fileChange', 'getFileList', 'handelDel'],
    setup(props, {emit}) {
      const handleChange = (info) => {
        emit('getFileList', info)
      }
      const previewVisible = ref(false)
      const videoPreviewVisible = ref(false)
      const previewImage = ref('')
      const previewTitle = ref('')
      const enclosure = ref(props.enclosureName)

      const fileList = ref(props.showImg)
      const handlePreview = async file => {

        if (props.filetype == 'pdf') {
          // console.log(file.url)
          window.location = file.url
        }
        if (props.filetype == 'xlsx') {
          window.location = file.url
        }
        if (props.filetype == 'word') {
          window.location = file.url
        }
        if (props.filetype == 'video') {
          videoPreviewVisible.value = true
        }
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj)
        }

        previewImage.value = file.url || file.preview
        if (props.filetype === 'amr') {
          previewVisible.value = false
        } else {
          previewVisible.value = true
        }
        previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
      const handleCancel = () => {
        previewVisible.value = false
        previewTitle.value = ''

      }
      const videoCancel = () => {
        videoPreviewVisible.value = true
        previewVisible.value = false
      }
      onBeforeUpdate(() => {

        fileState.filetypeL = ''
        if (props.filetype == 'img' || props.filetype == 'image') {
          fileState.filetypeL = 'image/*'
        } else if (props.filetype == 'excel') {
          fileState.filetypeL = '*.xls,.xlsx'
        } else if (props.filetype == 'pdf') {
          fileState.filetypeL = '.pdf'
        } else if (props.filetype == '') {
          fileState.filetypeL = ''
        } else if (props.filetype === 'productType') {
          fileState.filetypeL = '.pdf,image/*,'
        }
      })
      const params = reactive({
        cosType: 'cos'
      })
      const fileState = reactive({
        filetypeL: ''
      })
      //此处添加上传类型
      if (props.filetype == 'img' || props.filetype == 'IMAGE') {
        fileState.filetypeL = 'image/*'
      } else if (props.filetype == 'excel') {
        fileState.filetypeL = '*.xls,.xlsx'
      } else if (props.filetype == 'pdf' || props.filetype == 'PDF') {
        fileState.filetypeL = '.pdf'
      } else if (props.filetype === 'amr') {
        fileState.filetypeL = '.amr'
      } else if (props.filetype === 'video') {
        fileState.filetypeL = 'video/*'
      } else if (props.filetype === '图片类型') {
        fileState.filetypeL = ''
      } else if (props.filetype === '视频') {
        fileState.filetypeL = ''
      } else if (props.filetype === '办公软件') {
        fileState.filetypeL = ''
      } else if (props.filetype === '压缩包') {
        fileState.filetypeL = ''
      } else if (props.filetype === 'word') {
        fileState.filetypeL = '*.doc,*docx'
      } else if (props.filetype === 'all') {
        fileState.filetypeL = '*docx ,*.doc,*.xls,.xlsx,.ppt,.pdf,.png,.jpg'
      } else if (props.filetype === 'productType') {
        fileState.filetypeL = '.pdf,image/*,'
      }
      //此页面内部使用数据
      const fileData = {
        bucket: '',
        region: '',
        folder: '',
        TmpSecretId: '',
        TmpSecretKey: '',
        XCosSecurityToken: '',
        StartTime: '',
        ExpiredTime: '',
        uploadFileId: 'uploadId',
        fileArr: [],
        ScopeLimit: false,
        showProgress: false,
        // listType:props.listType
      }
      // const getUUID = () => {
      //   let s = []
      //   let hexDigits = '0123456789abcdef'
      //   for (let i = 0; i < 36; i++) {
      //     s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      //   }
      //   s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
      //   s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      //   s[8] = s[13] = s[18] = s[23] = '_'
      //   let uuid = s.join('')
      //   return uuid
      // }
      const getCOSType = () => {
        let uri = '/product-service/product/cos/getTempToken'
        let param = {
          'data': {
            'type': ''
          },
        }
        return request(uri, param).then(res => {
          if (res.code === 1) {
            return res.result.osType
          }
          return ''
        })
      }
      onBeforeMount(async () => {
        params.cosType = await getCOSType()
      })
      // 上传腾讯云
      const uploadFile = (e) => {
        uploadToCos(e.file)
      }
      const uploadToCos = (uploadFile) => {
        let uid = uploadFile.uid
        uploadObject(uploadFile, params.cosType, (type, fileUrl, origin_file_name, key, p) => {
          if (p && fileUrl === '') {
            // 设置进度及状态
            if (p !== 100) {
              fileData.percentage = p
              addPropToItem(fileData.fileArr, 'percent', p, 'uid', uid)
            } else {
              fileData.showProgress = false
              addPropToItem(fileData.fileArr, 'percent', p, 'uid', uid)
              addPropToItem(fileData.fileArr, 'status', 'done', 'uid', uid)
            }
          } else {
            fileData.showProgress = false
            fileData.fileArr = {
              'enclosureName': origin_file_name,
              'url': fileUrl,
              'id': fileData.uploadFileId
            }
            fileList.value.forEach(item => {
              item.status = 'done'
              item.url = fileData.fileArr.url
            })
            console.log('fileList', fileList)
            console.log(fileData)
            emit('fileChange', fileData.fileArr)
          }
        })
      }

      // 删除
      const handelRemove = (e) => {
        console.log('删除了', e)
        emit('handelDel')
      }

      return {
        ...toRefs(params),
        handelRemove,
        enclosure,
        videoCancel,
        videoPreviewVisible,
        // uploadCosFile,
        uploadFile,
        fileData,
        props,
        fileList,
        headers: {
          authorization: 'authorization-text',
        },
        fileState,
        handleChange,
        handlePreview,
        previewVisible,
        previewImage,
        handleCancel,
        previewTitle,
      }
    },
  })


</script>

<style scoped>
  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
</style>
