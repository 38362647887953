<style scoped>
.search-form {
  padding-bottom: 10px;
}

:deep(.ant-col) :deep(.ant-form-item-label) {
  width:140px !important;
}
</style>

<template>
  <a-form
    ref="formRef"
    name="advanced_search"
    class="search-form"
    :model="formState"
    @finish="onFinish"
    :label-col="{ style: { width: '100px' } }"
  >
    <a-row :gutter="24">
      <template
        v-for="(keyStr, index) in Object.keys(Condition)"
        :key="'form_item_' + index"
      >
        <a-col v-show="expand || index < (24 / ColSpan) * 2" :span="ColSpan">
          <!-- 带必填标识的下拉-->
          <a-form-item
            v-if="Condition[keyStr].type === 'select1' && typeof Condition[keyStr] === 'object'"
            :name="keyStr"
            :label="Condition[keyStr].label"
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
          >
            <a-select
              v-if="Condition[keyStr].type === 'select1'"
              v-model:value="formState[keyStr]"
              dropdown-class-name="value-select"
              show-search
              :filter-option="filterOptionClass"
              :placeholder="`请选择${Condition[keyStr].label}`"
              @change="changes($event,Condition[keyStr].label)"
              allow-clear
            >
              <template v-if="Condition[keyStr]['urlKey'] !== undefined">
                <template
                  v-for="(dicItem, index2) in State.treeData[keyStr]"
                  :key="'form_select2_' + index2"
                >
                  <a-select-option :value="dicItem.value" :label="dicItem.label">{{
                      dicItem.label
                    }}</a-select-option>
                </template>
              </template>
              <template v-else-if="Condition[keyStr].data !== undefined">
                <template
                  v-for="(dicItem, index3) in Condition[keyStr].data"
                  :key="'form_select3_' + index3"
                >
                  <a-select-option :value="dicItem.value" :label="dicItem.label">{{
                      dicItem.label
                    }}</a-select-option>
                </template>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item
            v-else-if="typeof Condition[keyStr] === 'string'"
            :name="keyStr"
            :label="Condition[keyStr]"
          >
            <a-input
              v-model:value="formState[keyStr]"
              :placeholder="`请输入${Condition[keyStr]}`"
            ></a-input>
          </a-form-item>
          <a-form-item
            v-if="typeof Condition[keyStr] === 'object' && Condition[keyStr].type !== 'select1'"
            :name="keyStr"
            :label="Condition[keyStr].label"
            :rules="Condition[keyStr].rules || []"
          >
            <a-select
              v-if="Condition[keyStr].type === 'select'"
              v-model:value="formState[keyStr]"
              dropdown-class-name="value-select"
              show-search
              allow-clear
              :filter-option="filterOption"
              :placeholder="Condition[keyStr].placeholder?Condition[keyStr].placeholder:`请选择${Condition[keyStr].label}`"
              @change="asd($event,Condition[keyStr].label)"
            >
              <!--              <a-select-option :key="`form_select_placeholder_${Condition[keyStr].label}`" :label="Condition[keyStr].placeholder?Condition[keyStr].placeholder:`请选择${Condition[keyStr].label}`" disabled>-->
              <!--              </a-select-option>-->
              <template v-if="Condition[keyStr]['urlKey'] !== undefined">
                <template
                  v-for="(dicItem, index2) in State.treeData[keyStr]"
                  :key="'form_select2_' + index2"
                >
                  <a-select-option :value="dicItem.value" :label="dicItem.label">{{
                    dicItem.label
                  }}</a-select-option>
                </template>
              </template>
              <template v-else-if="Condition[keyStr].data !== undefined">
                <template
                  v-for="(dicItem, index3) in Condition[keyStr].data"
                  :key="'form_select3_' + index3"
                >
                  <a-select-option :value="dicItem.value" :label="dicItem.label">{{
                    dicItem.label
                  }}</a-select-option>
                </template>
              </template>
            </a-select>
            <a-date-picker
              v-else-if="Condition[keyStr].type === 'date-picker'"
              v-model:value="formState[keyStr]"
              value-format="YYYY-MM-DD"
              :placeholder="`请选择${Condition[keyStr].label}`"
              style="width: 100%"
            >
            </a-date-picker>
            <a-date-picker
              v-else-if="Condition[keyStr].type === 'date-time-picker'"
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model:value="formState[keyStr]"
              :placeholder="`请选择${Condition[keyStr].label}`"
              show-time
              style="width:100%"
            >
            </a-date-picker>
            <a-range-picker
              v-else-if="Condition[keyStr].type === 'range-picker'"
              v-model:value="formState[keyStr]"
              :placeholder="`请选择${Condition[keyStr].label}`"
            ></a-range-picker>
            <a-tree-select
              v-else-if="Condition[keyStr].type === 'tree-select'"
              v-model:value="formState[keyStr]"
              @change="linked($event, Condition[keyStr].label, State.treeData[keyStr])"
              show-search
              :filterTreeNode="filterTreeOption"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :placeholder="
                Condition[keyStr].placeholder
                  ? Condition[keyStr].placeholder
                  : `请输入${Condition[keyStr].label}`
                  "
              allow-clear
              tree-default-expand-all
              :tree-data="State.treeData[keyStr]"
              :fieldNames="{children:'children', label:'supName', key:'id', value: Condition[keyStr].value }"
            >
            </a-tree-select>
            <a-tree-select
              v-else-if="Condition[keyStr].type === 'tree-select1'"
              v-model:value="formState[keyStr]"
              @change="linked($event, Condition[keyStr].label, State.treeData[keyStr])"
              show-search
              tree-node-filter-prop="name"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :placeholder="
                Condition[keyStr].placeholder
                  ? Condition[keyStr].placeholder
                  : `请输入${Condition[keyStr].label}`
                  "
              allow-clear
              tree-default-expand-all
              :tree-data="props.paramsData"
              :fieldNames="{children:'children', label:'column', key:'id', value: Condition[keyStr].value }"
            >
            </a-tree-select>
            <a-input
              v-else
              v-model:value="formState[keyStr]"
              :placeholder="
                Condition[keyStr].placeholder
                  ? Condition[keyStr].placeholder
                  : `请输入${Condition[keyStr].label}`
              "
            ></a-input>
          </a-form-item>
        </a-col>
      </template>
    </a-row>
    <a-row>

      <a-col :span="24" style="text-align: right">
        <a
          style="font-size: 12px; margin-right: 20px"
          v-if="Object.keys(Condition).length > (24 / ColSpan) * 2"
          @click="expand = !expand"
        >
          <template v-if="expand">
            <UpOutlined />
            隐藏部分条件
          </template>
          <template v-else>
            <DownOutlined />
            展开更多
          </template>
        </a>
        <a-button type="primary" html-type="submit">
          <template #icon>
            <SearchOutlined />
          </template>
          查询
        </a-button>
        <a-button style="margin: 0 8px" @click="deleteAll">
          <template #icon>
            <ReloadOutlined />
          </template>
          重置
        </a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {DownOutlined, ReloadOutlined, SearchOutlined, UpOutlined,} from '@ant-design/icons-vue'
import {request} from '../../utils/request.js'
import {getExtendUri} from '../../utils/request_extend.js'

export default defineComponent({
  components: {
    DownOutlined,
    UpOutlined,
    SearchOutlined,
    ReloadOutlined,
  },
  props: {
    Condition: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    ColSpan: {
      type: Number,
      required: true,
      default() {
        return 8
      },
    },
    paramsData: {
      type: Array,
      default() {
        return []
      },
    }
  },
  setup(props, context) {
    const expand = ref(false)
    const formRef = ref()
    const State = reactive({
      treeData:[]
  })
    const state = reactive({

    })
    const formState = reactive({})
    const onFinish = (values) => {
      context.emit('callback', values)
    }
   const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const filterOptionClass = (input, option) => {
      return option.label.indexOf(input) > -1
    }

    const filterTreeOption = function(input, option) {
      return option.supName.includes(input)
    }
    const asd = function (value, status) {
      if (status === '海报类型一级') {
        context.emit('call', value)
        formState.posterGradeTwo = undefined
      } else if(status === '调用方名称') {
        context.emit('call', value)
        formState.portId = undefined
      } else if (status === '调用方接口名称') {
        context.emit('nameLinked', value)
        formState.icId = undefined
      }
    }
    Object.keys(props.Condition).forEach(key => {
      if (props.Condition[key].type === 'select' && props.Condition[key].defaults !== undefined) {
        formState[key] = props.Condition[key].defaults
      }
      if (props.Condition[key]['urlKey']) {
          return request(getExtendUri(props.Condition[key]['urlKey']), {'data': props.Condition[key]['queryParam'] || {}}).then(result => {
            console.debug('Condition请求结果', result)
            if (result.code === 1) {
              State.treeData[key] = ref(result.result)
            }
          })
      }
    })
    const linked = function(value, status, list) {
      if (status === '供应商名称') {
        context.emit('nameLinked', value,list)
        formState.productId= undefined
        formState.riskName = undefined
      }
    }
    const changes = function (value, status) {
      if (status === '调用方名称') {
        context.emit('call', value)
        formState.ipId = undefined
      }
    }
    const deleteAll = function () {
      context.emit('resetAll')
      formRef.value['resetFields']()
    }

    const deleteAllM = function () {
      console.debug('deleteAllM')
      formRef.value['resetFields']()
    }

    /**
     * 给组件加一个清除内容的方法
     * @param arr
     */
    const clearSearchInput = (arr) => {
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          formState[arr[i]] = ''
        }
      }
    }
    context.expose({
      clearSearchInput,
      deleteAll,
      deleteAllM,
      State
    })
    return {
      filterOptionClass,
      props,
      formRef,
      ...toRefs(State),
      formState,
      expand,
      onFinish,
      State,
      asd,
      state,
      deleteAll,
      deleteAllM,
      linked,
      changes,
      filterOption,
      filterTreeOption
    }
  },
})
</script>
