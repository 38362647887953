<template>
  <meta name="referrer" content="never">
  <template v-for="(item,idx) in data" :key="idx">
    <a-card hoverable :style="dataInsert === 1 ? style1 : style">
      <div class="card-header">
        <div class="nav">
          <div class="nav-left">
            <a-checkbox v-model:checked="item.lock" @change="stateChange(item)"></a-checkbox>
          </div>
          <div class="nav-center">{{item.updateTime}}</div>
          <div class="nav-right" v-if="item.status === '上架'"><a-tag color="#87d068">{{item.status}}</a-tag></div>
          <div class="nav-right" v-if="item.status === '待上架'"><a-tag color="warning">{{item.status}}</a-tag></div>
          <div class="nav-right" v-if="item.status === '下架'"><a-tag color="rgb(175, 175, 175)">{{item.status}}</a-tag></div>
        </div>
        <!-- 图片 视频 -->
        <div class="image">
            <img :src="item.pictureUrl" alt="" v-if="dataType === 'haibao'"/>
          <div v-if="dataType !== 'haibao'">
          <img :src="item.pictureUrl" v-if="activeKey === '1'? true : false" alt=""/>
          <img :src="item.pictureUrl" v-if="activeKey === '2'? true : false" alt=""/>
          <img :src="item.videoPictureUrl" v-if="activeKey === '3'? true : false" alt=""/>
          <img :src="item.videoPictureUrl" v-if="activeKey === '4'? true : false" alt=""/>
          </div>
          <div class="img-footer">{{ item.materialName || item.audioName || item.videoName || item.fileName}}</div>
          <play-circle-filled v-if="activeKey === '3'? true : false" class="icon-video" @click="openVideo(item)" style="fontSize: 24px"/>
        </div>
        <div class="context" style="padding: 10px">
          <a-form  autocomplete="off">
            <a-row>
              <a-col :span="24" style="margin-bottom: -20px" v-if="dataType === 'haibao'">
                <a-form-item label="海报类型">
                  {{posterGrade(item.posterGradeOne)}}
                </a-form-item>
              </a-col>
              <a-col :span="24" style="margin-bottom: -15px"  v-if="dataInsert !== 1">
                <a-form-item label="标签">
                  <a-tag class="tag-item" v-for="lab in item.labelNameList" :key="lab.labelName">{{lab.labelName}}</a-tag>
                </a-form-item>
              </a-col>
              <a-col span="12" v-if="dataInsert !== 1">
                <a-form-item label="置顶">
                  <a-switch v-model:checked="item.isTop" @change="onUpdateTop(item)"/>
                </a-form-item>
              </a-col>
              <a-col span="12" v-if="dataInsert !== 1">
                <a-form-item label="上传者" class="form-tag">
                  {{item.updateUser}}
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="footer-active" v-if="dataInsert !== 1">
          <span class="i-a" @click="handelUpdate(item)"><form-outlined :style="{fontSize : '18px'}"/></span>
          <span class="i-b" v-if="activeKey === '1'? true : false" @click="handelSee(item)"><eye-outlined :style="{fontSize : '18px'}"/></span>
        </div>
      </div>
    </a-card>

    <!-- 图片查看 -->
    <a-modal :visible="previewVisible" title="查看" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </template>
    <!-- 视频播放 -->
    <a-modal :destroyOnClose="true" v-model:visible="showVideo" title="视频播放" :footer="null" @cancel="videoClose(item)">
      <video id="video" width="300" height="280" controls="controls">
        <source :src="videoName" type="video/mp4">
        <!--        <source :src="videoName" type="video/webm">-->
        <!--        <source :src="videoName" type="video/ogg">-->
      </video>
    </a-modal>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, inject, reactive, ref, toRefs} from 'vue'
import {request} from '../../utils/request'

const plainOptions = ['Apple', 'Pear', 'Orange']
export default defineComponent({
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    dataInsert: {
      type: Number,
      required: true,
      dafault() {
        return 0
      }
    },
    dataType: {
      type: String,
      required: true,
      default() {
        return 'img'
      }
    },
    activeKey: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    }
  },
  emits:['statusLosk', 'getIds','getList'],
  setup(props, {expose,emit}) {
    const state = reactive({
      previewVisible: false,
      previewImage: '',
      showVideo: false,
      ids: [],
      listData: [],
      style: {
        width: '230px',
        height: '350px',
        borderRadius: '8px',
        display: 'inline-block',
        margin: '20px'
      },
      style1: {
        width: '210px',
        height: '220px',
        borderRadius: '8px',
        display: 'inline-block',
        margin: '20px'
      }
    })
    const videoName = ref('')
    const topping = ref(false)
    const init = function () {
    }
    init()
    const stateChange = function(oldItem) {
      console.debug(oldItem)
      if(oldItem.lock === true){
        if (props.dataInsert === 1) {
          oldItem.lock = false
          state.listData.push(oldItem)
          emit('getList', state.listData)
          state.listData = []
        }
        state.ids.push(oldItem.id)
        emit('getIds',state.ids)
      }else{
        state.ids.forEach((child,index) => {
          console.debug(child)
          if(oldItem.id === child){
            state.ids.splice(index,1)
          }
        })
        emit('getIds',state.ids)
      }
      let obj = []
      props.data.forEach((item) => {
        obj.push(item.lock)
        if (item.lock === false) {
          emit('statusLosk',item.lock)
        }
      })
      if (obj.includes(false) == false) {
        emit('statusLosk',true)
      }
    }
    const posterGrade = function(value) {
      if (value === 1) {
        return '供应商海报'
      } else if (value === 2) {
        return '分类海报'
      } else {
        return '定制海报'
      }
    }

    const selectAll = (flag) => {
      state.ids = []
      if(flag === true){
        console.debug('ppppp', props.data)
        props.data.forEach((child) => {
          state.ids.push(child.id)
        })
        emit('getIds',state.ids)
      }else{
        state.ids = []
        emit('getIds',state.ids)
      }
      props.data.forEach((item) => {
        item.lock = ref(flag)
      })
    }
    const getUpdatetoYe = inject('handelUpdate')
    // 编辑按钮
    const handelUpdate = (item) => {
      getUpdatetoYe(item)
    }
    const dell = function() {
      state.ids = []
    }
    // 查看按钮
    const handelSee = (item) => {
      state.previewVisible = true
      state.previewImage = item.pictureUrl

    }
    const handleCancel = () => {
      state.previewVisible = false
      state.previewImage = ''
    }
    const openVideo = (item) => {
      videoName.value = item.video
      state.showVideo = true

      console.debug(videoName.value, item)
    }
    const videoClose = () =>{
      state.showVideo = false
      let a = document.getElementById('video')
      a.pause()
      a.currentTime = 0
    }
    const updateTop = inject('updateTop')
    const handleSearch = inject('handleSearch')
    // 置顶
    const onUpdateTop = (item) =>{
      console.debug('触发的事件', item,props.activeKey, updateTop)
      request(updateTop, {
        data:{
          id: item.id,
          isTop: item.isTop ? 1 : 0,
          // type: props.activeKey
        }
      }).then(res => {
        if(res.code === 1){
          message.success(res.message)
          handleSearch({})
        }else{
          message.warning(res.message)
        }
      })
    }

    expose({
      selectAll,
      dell
    })
    return {
      ...toRefs(state),
      onUpdateTop,
      openVideo,
      videoClose,
      handleCancel,
      stateChange,
      handelUpdate,
      handelSee,
      plainOptions,
      topping,
      videoName,
      dell,
      posterGrade
    }
  }
})
</script>

<style>
.nav {
  display: flex;
}
.nav-left {
  width: 20px;
  text-align: center;
}

:deep(.ant-card-body) {
  height:0 !important;
}
.context {
  width: 100%;
}
.nav-center {
  flex: 1;
  text-align: center;

}
.nav-right {
  /* width: 65px; */
  text-align: center;
}
.card-header{
  position: relative;
  height: 350px;
}
.footer-active {
  border-radius:0 0 8px 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 43px;
  background: rgb(224, 222, 222);
}
.image {
 position: relative;
}
.img-footer{
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background: rgba(120, 119, 119, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
}
.icon-video {
  position: absolute;
  left: 45%;
  top: 40%;
  transform: translate(50%);
}

.image img {
  width: 100%;
  height: 160px;
}
.i-a{
  color: rgb(175, 175, 175);
  text-align: center;
  line-height: 43px;
  margin-left: 50px;

}
.i-b{
  margin-left: 85px;
}
.form-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
}
.tag-item {
  overflow: hidden;
  white-space: nowrap;
  width: 45px;
}
</style>
