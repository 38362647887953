<style scoped>
.user-update {
  display: inline-block;
}
.user-update-icon {
  display: inline-block;
  font-size: 17px;
}
.user-update-info {
  display: inline-block;
  font-size: 14px;
  margin-left: 11px;
}
</style>

<template>
  <a-row>
    <a-col :span="18"></a-col>
    <a-col :span="5">
      <div class="user-update">
        <div class="user-update-icon">
          <UserOutlined/>
        </div>
        <div class="user-update-info">
          <a-dropdown>
            <a style="color: #FFFFFF" @click.prevent>
              {{loginName}}
              <DownOutlined/>
            </a>
            <template #overlay>
              <a-menu >
                <a-menu-item-group title="配置">
                <a-menu-item>
                  <a href="javascript:;" @click="changePassword">修改密码</a>
                </a-menu-item>

                </a-menu-item-group>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </a-col>
    <a-col :span="1">
      <a-button type="primary" shape="circle" @click="logout">
        <template #icon>
          <LogoutOutlined/>
        </template>
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
import {defineComponent, reactive} from 'vue'
import {LogoutOutlined, UserOutlined} from '@ant-design/icons-vue'
import store from '../../store'
import {useRouter} from 'vue-router'

export default defineComponent({
  components: {LogoutOutlined, UserOutlined},
  setup(props, {emit}) {
    const router = useRouter()
    const state = reactive({
     loginName: store.getters['user/getLoginName'],
    })
    const changePassword = function() {
      emit('changePassword')
    }
    const logout = function () {
      store.dispatch('user/logout')
      localStorage.clear()
      sessionStorage.clear()
      router.push({name: 'Auth'})
    }
    return {
      ...state,
      logout,
      changePassword
    }
  }
})
</script>
