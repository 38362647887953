<style scoped></style>

<template>
  <template v-for="item in menuData">
    <a-menu-item v-if="!item['submenus'] || item['submenus'].length === 0" :key="item.id" >
      <template #icon><component :is="iconComponent(item.icon)" /></template>
      {{ item.name }}</a-menu-item>
    <a-sub-menu v-else :key="item.id">
      <template #title>{{ item.name }}</template>
      <template #icon><component :is="iconComponent(item.icon)" /></template>
      <cus-menu :menuData="item['submenus']"/>
    </a-sub-menu>
  </template>
</template>

<script>
import {defineComponent} from 'vue'
import cusMenu from './menu'
import * as antIcons from '@ant-design/icons-vue'
export default defineComponent({
  name: 'cusMenu',
  components: {
    cusMenu
  },
  props: {
    menuData: {
      type: Array,
      require: true,
      default() {
        return []
      }
    }
  },
  // setup 两次  左侧菜单 and 折叠后浮动菜单
  setup(props) {
    const iconComponent = (name) => {
      return antIcons[name]
    }
    return {
      iconComponent,
      props
    }
  }
})
</script>
