<template>
  <meta name="referrer" content="never">
  <template v-for="(item,idx) in data" :key="idx">
    <a-card hoverable style="width: 230px;height: 350px;border-radius: 8px;display: inline-block;margin: 20px">
      <div class="card-header">
        <div class="nav">
          <div class="nav-left">
            <a-checkbox v-model:checked="item.lock" @change="stateChange(item)"></a-checkbox>
          </div>
          <div class="nav-center">{{ item.updateTime }}</div>
          <div class="nav-right" v-if="item.status === '0'">
            <a-tag color="#87d068">待上架</a-tag>
          </div>
          <div class="nav-right" v-if="item.status === '1'">
            <a-tag color="warning">上架</a-tag>
          </div>
          <div class="nav-right" v-if="item.status === '2'">
            <a-tag color="rgb(175, 175, 175)">下架</a-tag>
          </div>
        </div>
        <!-- 图片 视频 -->
        <div class="image">
          <img :src="item.pictureUrl" v-if="activeKey === '1'? true : false" alt=""/>
          <div style="width: 100%;height: 160px" v-if="activeKey === '2'? true : false && item.pictureUrl !==''">
            <img style="width: 50px;height: 40px;position: absolute;right: 90px;top: 50px"
                 src="https://static-1305332945.cos.ap-beijing.myqcloud.com/upload/common/product/2022/07/28/fdf59df1_a8df_4f4d_8f73_e073da3c1188/%E9%9F%B3%E9%A2%91%E9%BB%98%E8%AE%A4%E5%9B%BE.jpeg"
                 v-if="activeKey === '2'? true : false && item.pictureUrl !==''" alt=""/>
          </div>
          <!--          <img v-if="activeKey === '2'? true : false && item.pictureUrl === ''" style="width: 50px;height: 40px;position: absolute;right: 90px;top: 50px" src="https://static-1305332945.cos.ap-beijing.myqcloud.com/upload/common/product/2022/07/28/fdf59df1_a8df_4f4d_8f73_e073da3c1188/%E9%9F%B3%E9%A2%91%E9%BB%98%E8%AE%A4%E5%9B%BE.jpeg"/>-->
          <img :src="item.videoPictureUrl" v-if="activeKey === '3'? true : false" alt=""/>
          <div v-if="activeKey === '4'? true : false" style="width: 100%;height: 160px">
            <img style="width: 50px;height: 40px;position: absolute;right: 90px;top: 50px"
                 src="https://static-1305332945.cos.ap-beijing.myqcloud.com/upload/common/product/2022/07/28/0fbb7953_f3c5_4200_ae99_21b4ce7aa2de/gqmx.jpg"
                 v-if="activeKey === '4'? true : false" alt=""/>
          </div>
          <div class="img-footer">{{ item.materialName || item.audioName || item.videoName || item.fileName}}</div>
          <play-circle-filled v-if="activeKey === '3'? true : false" class="icon-video" @click="openVideo(item)"
                              style="fontSize: 24px"/>
        </div>
        <div class="context" style="padding: 10px">
          <a-form autocomplete="off">
            <a-row>
              <a-col :span="24">
                <a-form-item label="标签">
                  <a-tag class="tag-item" v-for="lab in item.labelNameList" :key="lab.labelName">{{ lab.labelName }}
                  </a-tag>
                </a-form-item>
              </a-col>
              <a-col span="12">
                <a-form-item label="置顶">
                  <a-switch v-model:checked="item.isTop" @change="onUpdateTop(item)"/>
                </a-form-item>
              </a-col>
              <a-col span="12">
                <a-form-item label="上传者" class="form-tag">
                  {{ item.updateUserName }}
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="footer-active">
          <span class="i-a" @click="handelUpdate(item)"><form-outlined :style="{fontSize : '18px'}"/></span>
          <span class="i-b" v-if="activeKey === '1'? true : false" @click="handelSee(item)"><eye-outlined
            :style="{fontSize : '18px'}"/></span>
        </div>
      </div>
    </a-card>

    <!-- 图片查看 -->
    <a-modal :visible="previewVisible" title="查看" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </template>
  <!-- 视频播放 -->
  <a-modal :destroyOnClose="true" v-model:visible="showVideo" title="视频播放" :footer="null" @cancel="videoClose(item)">
    <video id="video" width="300" height="280" controls="controls">
      <source :src="videoName" type="video/mp4">
    </video>
  </a-modal>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref, inject} from 'vue'
import {request} from '../../utils/request'

const plainOptions = ['Apple', 'Pear', 'Orange']
export default defineComponent({
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    dataType: {
      type: String,
      required: true,
      default() {
        return 'img'
      }
    },
    activeKey: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    }
  },
  emits: ['statusLosk', 'getIds'],
  setup(props, {expose, emit}) {
    const state = reactive({
      previewVisible: false,
      previewImage: '',
      showVideo: false,
      ids: []
    })
    console.debug('asd', props.data)
    const videoName = ref('')
    const topping = ref(false)
    const init = function () {
    }
    init()
    const stateChange = function (oldItem) {
      console.debug(oldItem)
      if (oldItem.lock === true) {
        state.ids.push(({id: oldItem.id, status: oldItem.status}))
        emit('getIds', state.ids)
      } else {
        state.ids.forEach((child, index) => {
          console.debug(child)
          if (oldItem.id === child.id) {
            state.ids.splice(index, 1)
          }
        })
        emit('getIds', state.ids)
      }
      let obj = []
      props.data.forEach((item) => {
        obj.push(item.lock)
        if (item.lock === false) {
          emit('statusLosk', item.lock)
        }
      })
      if (obj.includes(false) == false) {
        emit('statusLosk', true)
      }
    }
    const selectAll = (flag) => {
      state.ids = []
      if (flag === true) {
        props.data.forEach((child) => {
          state.ids.push({id: child.id, status: child.status})
        })
        emit('getIds', state.ids)
      } else {
        state.ids = []
        emit('getIds', state.ids)
      }
      props.data.forEach((item) => {
        item.lock = ref(flag)
      })
    }
    const clearIds = () => {
      state.ids.splice(0)
    }

    const getUpdatetoYe = inject('handelUpdate')
    // 编辑按钮
    const handelUpdate = (item) => {
      getUpdatetoYe(item)
    }
    // 查看按钮
    const handelSee = (item) => {
      state.previewVisible = true
      state.previewImage = item.pictureUrl

    }
    const handleCancel = () => {
      state.previewVisible = false
      state.previewImage = ''
    }
    const openVideo = (item) => {
      videoName.value = item.videoUrl
      state.showVideo = true
    }
    const videoClose = () => {
      state.showVideo = false
      let a = document.getElementById('video')
      a.pause()
      a.currentTime = 0
    }
    const updateTop = inject('updateTop')
    const handleSearch = inject('handleSearch')
    // 置顶
    const onUpdateTop = (item) => {
      request(updateTop, {
        data: {
          id: item.id,
          isTop: item.isTop ? 1 : 0,
          type: props.activeKey
        }
      }).then(res => {
        if (res.code === 1) {
          message.success(res.message)
          handleSearch({})
        } else {
          message.warning(res.message)
        }
      })
    }

    expose({
      selectAll,
      clearIds
    })
    return {
      ...toRefs(state),
      onUpdateTop,
      openVideo,
      videoClose,
      handleCancel,
      stateChange,
      handelUpdate,
      handelSee,
      plainOptions,
      topping,
      videoName
    }
  }
})
</script>

<style>
.nav {
  display: flex;
}

.nav-left {
  width: 20px;
  text-align: center;
}

:deep(.ant-card-body) {
  height:0 !important;
}

.context {
  width: 100%;
}

.nav-center {
  flex: 1;
  text-align: center;

}

.nav-right {
  /* width: 65px; */
  text-align: center;
}

.card-header {
  position: relative;
  height: 350px;
}

.footer-active {
  border-radius: 0 0 8px 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 43px;
  background: rgb(224, 222, 222);
}

.image {
  position: relative;
}

.img-footer {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 30px;
  color: rgb(255, 255, 255);
  background: rgba(120, 119, 119, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
}

.icon-video {
  position: absolute;
  left: 45%;
  top: 40%;
  transform: translate(50%);
}

.image img {
  width: 100%;
  height: 160px;
}

.i-a {
  color: rgb(175, 175, 175);
  text-align: center;
  line-height: 43px;
  margin-left: 50px;

}

.i-b {
  margin-left: 85px;
}

.form-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
}

.tag-item {
  overflow: hidden;
  white-space: nowrap;
  width: 45px;
}
</style>
