<template >
  <nav class="header-bar">
    <div class="left">
      <div @click="back">
        <van-icon name="arrow-left" />
      </div>
    </div>
    <div class="center">
      <h1>{{ title }}</h1>
    </div>
  </nav>
</template>
<script>
import {onMounted, reactive, toRefs} from 'vue'
// import {isIOS, isQyWeiXin, isWeiXin} from '@/utils/utlis.js'
import router from '@/router/index.js'
// import {LeftOutlined} from '@ant-design/icons-vue'
import { Icon} from 'vant'
export default {
  name: 'IOSbackBar',
  components: {
    [Icon.name]: Icon,
  },
  props: {
    backMethod:{
      type: Function,
      default: () => {
        router.go(-1)
      }
    },
    title: {
      type: String,
      default:()=>''
    }
  },
  setup(props) {

    const state = reactive({
      isShow: true,
      backFun: props.backMethod,
      title: props.title
    })
    const back = () => {
      state.backFun()
    }

    onMounted(() => {
      // state.isShow = isIOS() && (isWeiXin() || isQyWeiXin())
    })
    return {
      ...toRefs(state),
      back
    }
  }
}
</script>


<style scoped>
.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

.left {
  width: 22px;
  font-size: 22px;
}
.center {
  width: 95%;
  text-align: center;

/* display: flex;
align-items: center; */
}

.left button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.center h1 {
  margin: 0;
  font-size: 18px;
}

</style>
