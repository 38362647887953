<style scoped>
.logo {
  height: 36px;
  margin: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo_img {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url("../../assets/img/logo.png") no-repeat 0 100%;
  background-size: 32px;
  margin-right: 14px;
}

.logo label {
  color: #FFFFFF;
  font-size: larger;
  font-weight: bolder;
}

.ant-layout-header {
  color: #fff;
}

.ant-layout-footer {
  margin: 0 10px;
  background: white;
  padding: 0;
}

</style>

<template>
  <a-layout style="height: 100vh;">
    <a-layout-sider v-model:collapsed="collapsed" :width="240" collapsible>
      <div class="logo">
        <div class="logo_img"></div>
        <label v-show="!collapsed">产品中心</label>
      </div>
      <a-menu style="height: calc(100% - 64px);overflow-y: auto;" @click="handleClick"
              v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" :theme="theme" :mode="mode">
        <cus-menu :menuData="menuData"/>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header>
        <cus-header @changePassword="changePassword"/>
      </a-layout-header>
      <a-layout-content>
        <cus-frame ref="frameRef" @callback="reSelectMenu"/>
      </a-layout-content>
      <a-layout-footer>
        <cus-footer/>
      </a-layout-footer>
    </a-layout>
  </a-layout>
  <a-modal v-model:visible="visible" title="请修改密码" :maskClosable="false" :closable = "closable" :cancel-button-props="{ disabled: disabled }" @ok="changePWD">
    <a-form class="auth-form"
            :model="formStatePassword"
            :wrapper-col="{ span: 24 }"
            layout="vertical"
            ref="formRef"
    >
      <a-form-item
        name="password"
        label="请输入原密码"
        :rules="[{ required: true, message: '请输入原密码' }]"
      >
        <a-input-password v-model:value="formStatePassword.password"/>
      </a-form-item>
      <a-form-item
        label="请输入新密码"
        name="newPassword"
        :rules="[{ required: true, message: '请输入新密码' }]"
      >
        <a-input-password v-model:value="formStatePassword.newPassword"/>
      </a-form-item>
      <a-form-item
        label="请确认新密码"
        name="confirmPassword"
        :rules="[{ required: true, message: '请确认新密码' }]"
      >
        <a-input-password v-model:value="formStatePassword.confirmPassword"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, reactive, ref, toRefs, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import store from '../../store'
import cusMenu from './menu'
import cusFrame from './frame'
import cusHeader from './header'
import cusFooter from './footer'
import {isAllMobile} from '../../router'
import {getRequestURI, request} from '../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  // beforeRouteUpdate(to, from, next){
  //   console.log('Application.beforeRouteUpdate', to, from.path)
  //   next()
  // },
  components: {
    cusFrame,
    cusMenu,
    cusHeader,
    cusFooter
  },
  setup() {
    const formRef = ref()
    const router = useRouter()
    const frameRef = ref()
    const state = reactive({
      disabled: false,
      visible: false,
      collapsed: false,
      closable: false,
      mode: 'inline',
      theme: 'dark',
      selectedKeys: ['home'],
      openKeys: [],
      status: store.getters['user/getIsInitPassword'],
      menuData: store.getters['user/getMenuTree'],
      menuMap: store.getters['user/getMenuMap'],
      formStatePassword: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      }
    })
    const handleClick = ({key}) => {
      let item = state.menuMap[key]
      if (item.uri === '/test/insured/InsuredPage') {
        if (isAllMobile()) {
          item.uri = '/test/insured/InsuredPageOnline'
        }
      }
      router.push({path: item.uri})
      frameRef.value.changeFrame({
        id: item.id,
        title: item.name,
        key: `frame_${item.id}`,
        icon: item.icon,
        path: item.uri,
      })
    }
    const reSelectMenu = (key) => {
      state.selectedKeys = [key]
    }
    // 弹出修改密码框
    const changePassword = function() {
      state.visible = true
    }
    const changePWD = async function () {
      const values = await formRef.value.validate()
      console.log(values)
      request(getRequestURI('changePassword'), {data: state.formStatePassword}).then(res => {
        console.log(res)
        if (res.code === 1) {
          message.success('修改密码成功')
          state.visible = false
          state.disabled = false
          store.dispatch('user/resetPasswordStatus')
        } else {
          message.success(res.message)
        }
      })
    }
    onMounted(() => {
      if (state.status === 'Y') {
        state.visible = true
        state.disabled = true
      }
      const menu = store.getters['user/getMenuPathMap'][router.currentRoute.value.path]
      if (menu && menu.id !== 'home') {
        frameRef.value.changeFrame({
          id: menu.id,
          title: menu.name,
          key: `frame_${menu.id}`,
          icon: menu.icon,
          path: menu.uri,
        })
      }
    })
    return {
      ...toRefs(state),
      frameRef,
      handleClick,
      reSelectMenu,
      changePWD,
      formRef,
      changePassword
    }
  }
})
</script>
